import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { RefreshControl } from 'react-native';

import { useCreatedTrips } from '@/api';
import { EmptyList, ScrollView, Text, View } from '@/ui';

import { Card } from '../home/card';

//@ts-ignore
export const UserProfile = ({ route }) => {
  const { data, isLoading, isError, refetch } = useCreatedTrips({
    variables: { id: route.params.id },
  });
  const { navigate } = useNavigation();
  // const [myTripData, setMyTripData] = React.useState(data);

  const renderItem = React.useCallback(
    //  @ts-ignore
    ({ item }) => (
      <Card
        {...item}
        onPress={() =>
          // {
          //   console.log('click');
          // }
          {
            // navigate('TripDetail', { id: item.id });
            //@ts-ignore
            navigate('App', {
              screen: 'TabNavigator',
              params: {
                screen: 'HomeNavigator',
                params: {
                  screen: 'TripDetail',
                  params: {
                    id: item.id,
                  },
                },
              },
            });
            // navigate('TabNavigator', {
            //   screens: 'TripDetail',
            //   params: { id: item.id },
            // });
          }
        }
      />
    ),
    [navigate]
    // [navigate]
  );

  if (isError) {
    return (
      <View>
        <Text> Error Loading data </Text>
      </View>
    );
  }

  return (
    <ScrollView
      className="flex grow"
      refreshControl={
        <RefreshControl
          refreshing={isLoading} // Use isLoading state to control the refresh indicator
          onRefresh={() => refetch()}
        />
      }
    >
      <View>
        <Text variant="xl" className="m-2 w-[100%] text-center">
          {data ? data[0]?.author?.name : null}
        </Text>
      </View>
      <FlashList
        data={data}
        numColumns={2}
        renderItem={renderItem}
        keyExtractor={(_, index) => `item-${index}`}
        ListEmptyComponent={<EmptyList isLoading={isLoading} />}
        estimatedItemSize={199}
      />
    </ScrollView>
  );
};
