import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { TripItinerary } from './types';

type Variables = { id: string };
type Response = TripItinerary;

export const useTripItinerary = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'itinerary',
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    return clientWithAuthHeader
      .get(`trip/${variables.id}/${primaryKey}`)
      .then((response) => response.data);
  },
});
