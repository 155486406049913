import { zodResolver } from '@hookform/resolvers/zod';
import { useColorScheme } from 'nativewind';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Platform } from 'react-native';
import { Calendar } from 'react-native-calendars';
// import { useNavigation } from '@react-navigation/native';
import DatePicker from 'react-native-date-picker'; //old
import * as z from 'zod';

import { Button, colors, ControlledInput, ScrollView, Text, View } from '@/ui';
import { DropMenu } from '@/ui/icons';

const schema = z.object({
  phoneNumber: z.string({
    required_error: 'Phone Number is required',
  }),
  member: z.string({
    required_error: 'Members are required',
  }),
});

export type FormType = z.infer<typeof schema>;

export type CloneFormProps = {
  onSubmitAuto?: SubmitHandler<FormType>;
  onSubmitManual?: SubmitHandler<FormType>;
  disable?: boolean;
};

// const customTheme = {
//   calendarBackground: '#ffffff',
//   textSectionTitleColor: '#b6c1cd',
//   selectedDayBackgroundColor: '#00adf5',
//   selectedDayTextColor: '#ffffff',
//   todayTextColor: '#00adf5',
//   dayTextColor: '#2d4150',
//   textDisabledColor: '#d9e1e8',
//   dotColor: '#00adf5',
//   selectedDotColor: '#ffffff',
//   arrowColor: 'orange',
//   monthTextColor: 'blue',
//   indicatorColor: 'blue',
// };

export const CloneForm = ({
  onSubmitAuto = () => {},
  onSubmitManual = () => {},
  disable,
  //@ts-ignore
  onDateChange,
}: CloneFormProps) => {
  const [date, setDate] = React.useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const { colorScheme } = useColorScheme();
  const { handleSubmit, control } = useForm<FormType>({
    resolver: zodResolver(schema),
  });

  React.useEffect(() => {
    //
    onDateChange(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <ScrollView className="flex-1 p-4 dark:bg-themeblue-600">
      <ControlledInput
        testID="phone-input"
        control={control}
        name="phoneNumber"
        label="Phone Number"
        placeholder="Enter PhoneNumber"
      />
      <ControlledInput
        testID="member-input"
        control={control}
        name="member"
        label="No. of Members"
        placeholder="Enter no. of members"
      />
      <Text className="text-md">Select Trip Start Date</Text>
      <View className="flex w-full flex-row justify-center p-2">
        {Platform.OS !== 'web' && (
          <DatePicker
            date={date}
            onDateChange={setDate}
            mode="date"
            // androidVariant="nativeAndroid"
            fadeToColor={colorScheme === 'dark' ? '#000' : '#fff'}
            textColor={colorScheme === 'dark' ? '#fff' : '#000'}
            minimumDate={new Date()}
            className="dark:bg-charcoal-900"
          />
        )}
        {Platform.OS === 'web' && (
          <View className="relative">
            <View
              className="absolute"
              style={{
                transform: 'rotate(90deg)',
                left: 1,
                zIndex: 1,
                top: 14,
              }}
            >
              <DropMenu
              // color={iconColor}
              />
            </View>
            <Calendar
              onDayPress={(day) => {
                const dateSelect = new Date(day?.timestamp);
                setDate(dateSelect);
              }}
              markedDates={{
                [date?.toISOString().split('T')[0]]: {
                  selected: true,
                  // marked: true,
                  disableTouchEvent: true,
                  selectedColor: colors.themeblue[400],
                  // selectedDotColor: 'orange',
                },
              }}
            />
            <View
              className="absolute"
              style={{
                transform: 'rotate(270deg)',
                right: 1,
                zIndex: 1,
                top: 14,
              }}
            >
              <DropMenu
              // color={iconColor}
              />
            </View>
          </View>
        )}
      </View>
      <View>
        <Text variant="xs">
          Note: {'\n'} Manual Booking means you can book the hotels and
          itinerary items yourself via your sources {'\n'} AutoBook means we
          (CloneMyTrips) will book everything on behalf of you related to the
          trip subject to availability and lumpsum discounts and invoice will be
          shared on your provided whatsapp numbers
        </Text>
      </View>
      <View className="flex-row justify-around">
        <Button
          testID="manual-button"
          label="Manual"
          onPress={handleSubmit(onSubmitManual)}
          variant="primary"
          disabled={disable}
        />
        <Button
          testID="auto-button"
          label="AutoBook"
          onPress={handleSubmit(onSubmitAuto)}
          variant="secondary"
          disabled={disable}
        />
      </View>
      <View className="p-4" />
    </ScrollView>
  );
};
