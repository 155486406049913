import React from 'react';

import { openLinkInBrowser } from '@/core';
import { Pressable, Text, View } from '@/ui';

//@ts-ignore
export const LocationCard = (item) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const toggle = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Pressable
      className="relative my-2 block flex-col divide-y-[1px] divide-[cornflowerblue] overflow-hidden rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 py-2 px-4 shadow-xl dark:bg-themeblue-500"
      // onPress={onPress}
      onPress={toggle}
    >
      {/* <View className="flex-col"> */}
      <View>
        <View className="flex-row items-center justify-between">
          <Text variant="md">{item?.title}</Text>
          <Text variant="xs">
            {item?.distance > 1000
              ? `${(item?.distance / 1000).toFixed(1)}km`
              : `${item?.distance}m`}
          </Text>
        </View>
      </View>
      <View>
        <View className="flex-row items-end justify-between">
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <View className="flex-col" style={{ width: '60%' }}>
            {/* <View className="flex-row"> */}
            <Text variant="xs">
              {
                //@ts-ignore
                item?.categories?.map((sub) => sub?.name)?.join(', ')
              }
            </Text>
            {/* </View> */}
            <Text variant="xs">{item?.address?.district}</Text>
          </View>
          <View className="mt-[10px] flex-col justify-end">
            <Pressable
              className="flex-row items-center justify-center rounded-lg bg-themeblue-400 px-4 py-2"
              onPress={() =>
                openLinkInBrowser(
                  `https://www.google.com/maps?q=${item?.access[0]?.lat},${item?.access[0]?.lng}`
                )
              }
            >
              {/* <Location color={iconColor} /> */}
              <Text variant="sm" className="text-white">
                Open in Maps
              </Text>
            </Pressable>
          </View>
        </View>
        {showDetails && (
          <View>
            <Text variant="xs">Address</Text>
            <Text variant="xs">{item?.address?.label}</Text>
          </View>
        )}
        {showDetails && (
          <View className="flex flex-row justify-center">
            <Text variant="xs">Show less</Text>
          </View>
        )}
        {!showDetails && (
          <View className="flex flex-row justify-center">
            <Text variant="xs">Show more</Text>
          </View>
        )}
      </View>
      {/* </View> */}
    </Pressable>
  );
};
