import React from 'react';

import type { TxKeyPath } from '@/core';
import { Text, View } from '@/ui';

type Props = {
  children: React.ReactNode;
  title?: TxKeyPath;
};

export const ItemsContainer = ({ children, title }: Props) => {
  return (
    <>
      {title && <Text variant="lg" className="pt-4 pb-2" tx={title} />}
      {
        <View className=" divide-y-[1px] divide-[cornflowerblue] rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 dark:border-[cornflowerblue] dark:bg-themeblue-500">
          {children}
        </View>
      }
    </>
  );
};
