import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { MyTripCommonDetail } from './types';

type Variables = { id: string };
type Response = MyTripCommonDetail;

export const useMyTripCommonDetail = createQuery<
  Response,
  Variables,
  AxiosError
>({
  primaryKey: 'tripDetail',
  // eslint-disable-next-line unused-imports/no-unused-vars
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    return clientWithAuthHeader
      .get(`myTrip/${variables.id}`)
      .then((response) => response.data);
  },
});
