import Slider from '@react-native-community/slider';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native'; //@ts-ignore

import { useSearchLocation } from '@/api';
import { getCurrentLocation } from '@/core';
import {
  ActivityIndicator,
  colors,
  EmptyList,
  ScrollView,
  Text,
  View,
} from '@/ui';

import { LocationCard } from './location-card';

// const tempData = [
//   {
//     title: 'Marathwada Biryani House',
//     id: 'here:pds:place:356tek9g-89ff0e774def99bd31ca8590f57a8238',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Marathwada Biryani House, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5904,
//       lng: 73.99687,
//     },
//     access: [
//       {
//         lat: 18.59035,
//         lng: 73.99674,
//       },
//     ],
//     distance: 197,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Cafe Hungry Monkey',
//     id: 'here:pds:place:356tek9g-d32ebb791a7cfdb0bb36ac02f84d0ec9',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Cafe Hungry Monkey, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5902,
//       lng: 73.99681,
//     },
//     access: [
//       {
//         lat: 18.5902,
//         lng: 73.99681,
//       },
//     ],
//     distance: 200,
//     categories: [
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Guru Snacks &Chinese',
//     id: 'here:pds:place:356tek9g-03656204ee06d3540429a86abf10ce8d',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Guru Snacks &Chinese, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59033,
//       lng: 73.9969,
//     },
//     access: [
//       {
//         lat: 18.59027,
//         lng: 73.99678,
//       },
//     ],
//     distance: 203,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Pokket Cafe',
//     id: 'here:pds:place:356tek9g-c45b1e7636b47220b287f58fc30f74f4',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Pokket Cafe, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58996,
//       lng: 73.99682,
//     },
//     access: [
//       {
//         lat: 18.59002,
//         lng: 73.99691,
//       },
//     ],
//     distance: 213,
//     categories: [
//       {
//         id: '100-1000-0007',
//         name: 'Cafeteria',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+917020257175',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 11:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T110000',
//             duration: 'PT12H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Baskin-Robbins',
//     id: 'here:pds:place:356tek9g-e56412df4e8919ed9566bb8f55b1d96c',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Baskin-Robbins, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59002,
//       lng: 73.99708,
//     },
//     access: [
//       {
//         lat: 18.59003,
//         lng: 73.99715,
//       },
//     ],
//     distance: 234,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     chains: [
//       {
//         id: '1481',
//         name: 'Baskin-Robbins',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-063',
//         name: 'Ice Cream',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917774000700',
//           },
//         ],
//         mobile: [
//           {
//             value: '+918008595339',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'SUBWAY',
//     id: 'here:pds:place:356tek9g-d9637996d3baf1dad723c0ff1ee13426',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'SUBWAY, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5896,
//       lng: 73.9968,
//     },
//     access: [
//       {
//         lat: 18.58946,
//         lng: 73.99669,
//       },
//     ],
//     distance: 235,
//     categories: [
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//         primary: true,
//       },
//     ],
//     chains: [
//       {
//         id: '1605',
//         name: 'SUBWAY',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1232400980',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-060',
//         name: 'Sandwich',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911244188700',
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.subway.com/en-IN',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 09:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T090000',
//             duration: 'PT14H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: "Domino's",
//     id: 'here:pds:place:356tek9g-c490f30681b0e661c2ababb732155711',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: "Domino's, Gade Wasti, Wagholi, Haveli 412207, India",
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58934,
//       lng: 73.99659,
//     },
//     access: [
//       {
//         lat: 18.58924,
//         lng: 73.99646,
//       },
//     ],
//     distance: 239,
//     categories: [
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//         primary: true,
//       },
//     ],
//     chains: [
//       {
//         id: '27851',
//         name: "Domino's",
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1229459684',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-057',
//         name: 'Pizza',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918149366888',
//           },
//         ],
//         www: [
//           {
//             value:
//               'https://www.dominos.co.in/store-location/pune/rainbow-crossroad-wagholi',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 11:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T110000',
//             duration: 'PT12H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Pizza Hut',
//     id: 'here:pds:place:356tek9g-bd0c072e6daf1b9581386125e26f9921',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Pizza Hut, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58939,
//       lng: 73.99681,
//     },
//     access: [
//       {
//         lat: 18.58937,
//         lng: 73.9968,
//       },
//     ],
//     distance: 251,
//     categories: [
//       {
//         id: '100-1000-0001',
//         name: 'Casual Dining',
//         primary: true,
//       },
//     ],
//     chains: [
//       {
//         id: '1582',
//         name: 'Pizza Hut',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1231809544',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-057',
//         name: 'Pizza',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+9118002022022',
//           },
//         ],
//         www: [
//           {
//             value:
//               'https://restaurants.pizzahut.co.in/pizza-hut-ph-rainbow-cross-road-wagholi-pizza-restaurant-wagholi-pune-125076/Home',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 11:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T110000',
//             duration: 'PT12H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//     payment: {
//       methods: [
//         {
//           id: 'mastercard',
//           accepted: true,
//         },
//         {
//           id: 'visa',
//           accepted: true,
//         },
//       ],
//     },
//   },
//   {
//     title: 'Hotel Khandesh Tadka',
//     id: 'here:pds:place:356jx7ps-7579ea7af9fa0d0bc6ed3245a587527f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Khandesh Tadka, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59123,
//       lng: 73.99762,
//     },
//     access: [
//       {
//         lat: 18.59123,
//         lng: 73.99776,
//       },
//     ],
//     distance: 268,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569629',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919028398986',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919028398986',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Dream Land',
//     id: 'here:pds:place:356jx7ps-0aefcb9418a500d8ea573b70efb3482d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Dream Land, SH-60, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59039,
//       lng: 73.99769,
//     },
//     access: [
//       {
//         lat: 18.5903,
//         lng: 73.99783,
//       },
//     ],
//     distance: 281,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166095069',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Goodluck Dhaba',
//     id: 'here:pds:place:356tek9g-c387902d3863cb8c36293339f75e0764',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Goodluck Dhaba, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58953,
//       lng: 73.99741,
//     },
//     access: [
//       {
//         lat: 18.58956,
//         lng: 73.99738,
//       },
//     ],
//     distance: 291,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-023',
//         name: 'Indian - North Indian',
//         primary: true,
//       },
//       {
//         id: '201-000',
//         name: 'Chinese',
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 10:00 - 23:30'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T100000',
//             duration: 'PT13H30M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Raj',
//     id: 'here:pds:place:356jx7ps-8e69d9e1940d0c2bb8c62426f8092d81',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Raj, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5896,
//       lng: 73.99746,
//     },
//     access: [
//       {
//         lat: 18.58962,
//         lng: 73.99742,
//       },
//     ],
//     distance: 291,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104857883',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Jagdamba Juice Bar',
//     id: 'here:pds:place:356tek9g-4bee551680551bb767ef16538db87a89',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Jagdamba Juice Bar, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58956,
//       lng: 73.99744,
//     },
//     access: [
//       {
//         lat: 18.58959,
//         lng: 73.9974,
//       },
//     ],
//     distance: 292,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '600-6300-0064',
//         name: 'Food/Beverage Specialty Store',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224585246',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Lijjat',
//     id: 'here:pds:place:356tek9g-012167fa45d4ffdd05dddfde0591faed',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Lijjat, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58932,
//       lng: 73.99736,
//     },
//     access: [
//       {
//         lat: 18.58939,
//         lng: 73.99725,
//       },
//     ],
//     distance: 300,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+917795979587',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Cafe Coffee Day',
//     id: 'here:pds:place:356tek99-91225613104b4e81b06803a64f1c3785',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Cafe Coffee Day, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5892,
//       lng: 73.99727,
//     },
//     access: [
//       {
//         lat: 18.58927,
//         lng: 73.99716,
//       },
//     ],
//     distance: 301,
//     categories: [
//       {
//         id: '100-1100-0010',
//         name: 'Coffee Shop',
//         primary: true,
//       },
//     ],
//     chains: [
//       {
//         id: '3777',
//         name: 'Cafe Coffee Day',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1227317125',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+9118001025093',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919119386219',
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.cafecoffeeday.com/store-locator',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Royal Pune',
//     id: 'here:pds:place:356tek9g-55b99b96f7fabe11aa7236c09f7f1ca8',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Royal Pune, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58957,
//       lng: 73.99756,
//     },
//     access: [
//       {
//         lat: 18.58965,
//         lng: 73.99744,
//       },
//     ],
//     distance: 302,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224409094',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+918147477878',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Yashraj Inn',
//     id: 'here:pds:place:356tek9g-2bd4f931b9679bfe83f1922b3b9a45ed',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Yashraj Inn, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58914,
//       lng: 73.99723,
//     },
//     access: [
//       {
//         lat: 18.58922,
//         lng: 73.99712,
//       },
//     ],
//     distance: 302,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '500-5000-0054',
//         name: 'Motel',
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166368746',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166368747',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224253375',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224253395',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-077',
//         name: 'Vegetarian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912060701618',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//               {
//                 id: '500-5000-0054',
//               },
//             ],
//           },
//           {
//             value: '+912060780956',
//           },
//         ],
//         www: [
//           {
//             value:
//               'http://www.oyorooms.com/40354?utm_source=tripadvisor&utm_medium=listing&utm_campaign=PUN398',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//               {
//                 id: '500-5000-0054',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/h/40354?utm_source=Bing_bp&utm_medium=listing&utm_campaign=PUN398',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     payment: {
//       methods: [
//         {
//           id: 'mastercard',
//           accepted: true,
//         },
//       ],
//     },
//   },
//   {
//     title: 'Hotel Abhiruchi Pure Veg Family Restaurant',
//     id: 'here:pds:place:356tek9u-5813d92863bd49ad89a8901388841581',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Abhiruchi Pure Veg Family Restaurant, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58902,
//       lng: 73.99714,
//     },
//     access: [
//       {
//         lat: 18.5891,
//         lng: 73.99702,
//       },
//     ],
//     distance: 305,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569655',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//       {
//         id: '800-077',
//         name: 'Vegetarian',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919821752212',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Kolhapuri Rassa',
//     id: 'here:pds:place:356tek9g-04ab8f7b71987a8cfb9c6aebc0c70a29',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Kolhapuri Rassa, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58943,
//       lng: 73.99752,
//     },
//     access: [
//       {
//         lat: 18.58941,
//         lng: 73.99751,
//       },
//     ],
//     distance: 307,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919665501990',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon: 00:00 - 23:00', 'Tue-Sun: 10:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT23H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO',
//           },
//           {
//             start: 'T100000',
//             duration: 'PT13H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Kusumban Pure Veg',
//     id: 'here:pds:place:356jx7ps-a9c9ebdebb3c0b2e7c394714112df834',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Kusumban Pure Veg, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5905,
//       lng: 73.998,
//     },
//     access: [
//       {
//         lat: 18.59047,
//         lng: 73.99784,
//       },
//     ],
//     distance: 311,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1134002372',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Shri Wagheshwar',
//     id: 'here:pds:place:356jx7ps-3257defc75430a5a2cc172868f3a2b09',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Shri Wagheshwar, Haveli Rural Road, Gade Wasti-BJS College Campus, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti-BJS College Campus',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58989,
//       lng: 73.99782,
//     },
//     access: [
//       {
//         lat: 18.58974,
//         lng: 73.99781,
//       },
//     ],
//     distance: 311,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333562',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Dattakrupa Vadewale',
//     id: 'here:pds:place:356jx7ps-757ba0281fa30ace6bd90836a5bd068d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Dattakrupa Vadewale, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58961,
//       lng: 73.99776,
//     },
//     access: [
//       {
//         lat: 18.58974,
//         lng: 73.99777,
//       },
//     ],
//     distance: 318,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176558132',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Sarthak Veg Nonveg Family Restaurant',
//     id: 'here:pds:place:356jx7ps-45452852a0e404580ea1e35a22695b91',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Sarthak Veg Nonveg Family Restaurant, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59105,
//       lng: 73.99813,
//     },
//     access: [
//       {
//         lat: 18.59099,
//         lng: 73.99825,
//       },
//     ],
//     distance: 321,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166095068',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Street Coffee',
//     id: 'here:pds:place:356tek9g-f9873dc530993f5e04b41e7888325fdc',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Street Coffee, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58845,
//       lng: 73.99663,
//     },
//     access: [
//       {
//         lat: 18.58855,
//         lng: 73.99653,
//       },
//     ],
//     distance: 322,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '100-1100-0000',
//         name: 'Coffee/Tea',
//       },
//       {
//         id: '600-6300-0064',
//         name: 'Food/Beverage Specialty Store',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917875682281',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'QUALITY CHINESE CENTRE',
//     id: 'here:pds:place:356jx7ps-6ce4382203a303ffa53752979c6a85c6',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'QUALITY CHINESE CENTRE, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58961,
//       lng: 73.99782,
//     },
//     access: [
//       {
//         lat: 18.58974,
//         lng: 73.99782,
//       },
//     ],
//     distance: 324,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176560494',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shraddha Vadewale & Snacks Centre',
//     id: 'here:pds:place:356jx7ps-7cf5a0f902c608f06d7daf2860f180f8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shraddha Vadewale & Snacks Centre, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58961,
//       lng: 73.99786,
//     },
//     access: [
//       {
//         lat: 18.58974,
//         lng: 73.99786,
//       },
//     ],
//     distance: 328,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '600-6300-0064',
//         name: 'Food/Beverage Specialty Store',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570095',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919922964200',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//         mobile: [
//           {
//             value: '+919922964200',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Idli Dosa Express',
//     id: 'here:pds:place:356tek9g-35b56fa65e2d1b8958d736148cb3c029',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Idli Dosa Express, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58961,
//       lng: 73.99787,
//     },
//     access: [
//       {
//         lat: 18.58974,
//         lng: 73.99787,
//       },
//     ],
//     distance: 329,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-061',
//         name: 'Breakfast',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919730299667',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Nanashri',
//     id: 'here:pds:place:356jx7ps-e89e7fbdb583065d7b9b87b26bbf0cd0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Nanashri, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58961,
//       lng: 73.998,
//     },
//     access: [
//       {
//         lat: 18.58974,
//         lng: 73.998,
//       },
//     ],
//     distance: 341,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '100-1000-0001',
//         name: 'Casual Dining',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333559',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//   },
//   {
//     title: 'Guljar Wadapav Centre',
//     id: 'here:pds:place:356tek9g-0bbe50d9b02ab16e24c04c1c6139d8c0',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Guljar Wadapav Centre, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58972,
//       lng: 73.99809,
//     },
//     access: [
//       {
//         lat: 18.58971,
//         lng: 73.99809,
//       },
//     ],
//     distance: 344,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224737205',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'R K Amantran Veg Family Restaurant',
//     id: 'here:pds:place:356jx7ps-8cb5bab6c26b0a8cc87484098160a050',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'R K Amantran Veg Family Restaurant, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59132,
//       lng: 73.99869,
//     },
//     access: [
//       {
//         lat: 18.59138,
//         lng: 73.99856,
//       },
//     ],
//     distance: 382,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569541',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-077',
//         name: 'Vegetarian',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//   },
//   {
//     title: 'Good Lak Biryani',
//     id: 'here:pds:place:356tek9e-162cb16ec8b8397b19cd54a30881307e',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Good Lak Biryani, Lexicon Group of Institutes, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Lexicon Group of Institutes',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58761,
//       lng: 73.9969,
//     },
//     access: [
//       {
//         lat: 18.5877,
//         lng: 73.997,
//       },
//     ],
//     distance: 418,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'B J S',
//     id: 'here:pds:place:356jx7ps-d44f5ae5f12108c57fac9db7968703c8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'B J S, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58982,
//       lng: 73.99927,
//     },
//     access: [
//       {
//         lat: 18.58997,
//         lng: 73.99921,
//       },
//     ],
//     distance: 458,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104856445',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shri Rangdas Swami Krupa Hotel',
//     id: 'here:pds:place:356jx7ps-9cfba88219fa090a1430024227d4a043',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Rangdas Swami Krupa Hotel, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59242,
//       lng: 73.99937,
//     },
//     access: [
//       {
//         lat: 18.59256,
//         lng: 73.99934,
//       },
//     ],
//     distance: 480,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '100-1000-0001',
//         name: 'Casual Dining',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166368150',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+918007349151',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//         mobile: [
//           {
//             value: '+918007349151',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Shambhu Raje Restorant',
//     id: 'here:pds:place:356tek9g-fc75a6b5249b8c5662c5164679efc1db',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Shambhu Raje Restorant, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59296,
//       lng: 73.99922,
//     },
//     access: [
//       {
//         lat: 18.59288,
//         lng: 73.99934,
//       },
//     ],
//     distance: 489,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shiv Amrutthuly',
//     id: 'here:pds:place:356tek9e-df2c7f8cd12e52fb2316ed0f6c462277',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Shiv Amrutthuly, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58663,
//       lng: 73.99393,
//     },
//     access: [
//       {
//         lat: 18.58604,
//         lng: 73.99358,
//       },
//     ],
//     distance: 496,
//     categories: [
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Kokan Katta',
//     id: 'here:pds:place:356jx7ps-c39f900711250b82d810d8eaafe4addf',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Kokan Katta, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58629,
//       lng: 73.99391,
//     },
//     access: [
//       {
//         lat: 18.58617,
//         lng: 73.99403,
//       },
//     ],
//     distance: 533,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166095091',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+917720053287',
//           },
//           {
//             value: '+919970811667',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Gajanan and Rasvanti Gruh',
//     id: 'here:pds:place:356jx7ps-dba9bece6c8405a204a07987bc7e2997',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Gajanan and Rasvanti Gruh, SH-60, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59293,
//       lng: 73.9997,
//     },
//     access: [
//       {
//         lat: 18.59302,
//         lng: 73.99957,
//       },
//     ],
//     distance: 533,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104855097',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Om Sai',
//     id: 'here:pds:place:356tek9g-ee777d89e0cacba4cfb1194e9e9cfbab',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Om Sai, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59359,
//       lng: 73.99966,
//     },
//     access: [
//       {
//         lat: 18.59352,
//         lng: 73.99978,
//       },
//     ],
//     distance: 564,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224411073',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224651791',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Omsai Mutton Khanawal & Chinese',
//     id: 'here:pds:place:356tek9g-4249cf55828b8406da4755621f40ca0f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Omsai Mutton Khanawal & Chinese, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59364,
//       lng: 73.9997,
//     },
//     access: [
//       {
//         lat: 18.59357,
//         lng: 73.99982,
//       },
//     ],
//     distance: 571,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '600-6300-0363',
//         name: 'Butcher',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224578352',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919730264422',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Kapila Snacks & Chinese',
//     id: 'here:pds:place:356tek9e-4079f8f04f1e712bbead7c705b735b52',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Kapila Snacks & Chinese, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58597,
//       lng: 73.99358,
//     },
//     access: [
//       {
//         lat: 18.58587,
//         lng: 73.99368,
//       },
//     ],
//     distance: 576,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224623442',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//       },
//     ],
//   },
//   {
//     title: 'Balusheth Misal & Matki Bhel',
//     id: 'here:pds:place:356tek9e-47927e0b34579cc3e077942a2f5711d6',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Balusheth Misal & Matki Bhel, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58586,
//       lng: 73.99359,
//     },
//     access: [
//       {
//         lat: 18.58582,
//         lng: 73.99362,
//       },
//     ],
//     distance: 588,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotelyash',
//     id: 'here:pds:place:356tek9g-d858071c42f70a7d0709ae22789507a2',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotelyash, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59397,
//       lng: 73.99993,
//     },
//     access: [
//       {
//         lat: 18.5939,
//         lng: 74.00005,
//       },
//     ],
//     distance: 611,
//     categories: [
//       {
//         id: '100-1000-0003',
//         name: 'Take Out & Delivery Only',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Jay Bhavani',
//     id: 'here:pds:place:356tek9e-931157727acf46584b52d02182e3cf94',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Jay Bhavani, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58555,
//       lng: 73.99382,
//     },
//     access: [
//       {
//         lat: 18.58559,
//         lng: 73.99387,
//       },
//     ],
//     distance: 615,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+917798596478',
//           },
//         ],
//         www: [
//           {
//             value: 'https://jaybhavanihotel.business.site',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 09:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T090000',
//             duration: 'PT14H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Mauli Vadewale',
//     id: 'here:pds:place:356tek9g-2289f5f2e425bffd56f953e96270aa79',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Mauli Vadewale, Haveli Rural Road, Gade Wasti-BJS College Campus, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti-BJS College Campus',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59045,
//       lng: 74.001,
//     },
//     access: [
//       {
//         lat: 18.5904,
//         lng: 74.001,
//       },
//     ],
//     distance: 625,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Venkateshwara',
//     id: 'here:pds:place:356jx7ps-26248e7f2ec909d32c29f86417055986',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Venkateshwara, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5938,
//       lng: 74.0003,
//     },
//     access: [
//       {
//         lat: 18.59388,
//         lng: 74.00018,
//       },
//     ],
//     distance: 634,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097334128',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'The Wild Cafe',
//     id: 'here:pds:place:356tek9g-d42cac7eb0f7d78a5ea0a1afd3ca7a0f',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'The Wild Cafe, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58999,
//       lng: 74.00111,
//     },
//     access: [
//       {
//         lat: 18.5902,
//         lng: 74.00102,
//       },
//     ],
//     distance: 643,
//     categories: [
//       {
//         id: '100-1100-0010',
//         name: 'Coffee Shop',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+918855059310',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Youngistan Hotel',
//     id: 'here:pds:place:356tek9g-bd58849b2073cded694189ab1da567e0',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Youngistan Hotel, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59423,
//       lng: 74.00012,
//     },
//     access: [
//       {
//         lat: 18.59415,
//         lng: 74.00024,
//       },
//     ],
//     distance: 643,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224419633',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918888516538',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Customer Care',
//     id: 'here:pds:place:356jx7ps-42f2b700c63a04f6972b5e2ddc6060ef',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Customer Care, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59424,
//       lng: 74.00013,
//     },
//     access: [
//       {
//         lat: 18.59417,
//         lng: 74.00025,
//       },
//     ],
//     distance: 645,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176564945',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: "Kks' Kitchen",
//     id: 'here:pds:place:356tek9e-c2db0a6eb5b4ac91a8325af2ccb787a2',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: "Kks' Kitchen, Wagholi, Haveli 412207, India",
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58561,
//       lng: 73.99265,
//     },
//     access: [
//       {
//         lat: 18.58556,
//         lng: 73.99252,
//       },
//     ],
//     distance: 647,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '23202552',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-065',
//         name: 'Continental',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917058947500',
//           },
//         ],
//         mobile: [
//           {
//             value: '+917058947500',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: '+918793802020',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.kkskitchens.com',
//           },
//           {
//             value: 'https://kkskitchens.com',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: 'https://www.facebook.com/1590256241013184',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '100-1000-0000',
//           },
//         ],
//         text: ['Mon-Sun: 09:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T090000',
//             duration: 'PT14H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Garma Garam',
//     id: 'here:pds:place:356tek9e-0a3d36aae6e5127f61dd0460babf577e',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Garma Garam, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58549,
//       lng: 73.99295,
//     },
//     access: [
//       {
//         lat: 18.58538,
//         lng: 73.99303,
//       },
//     ],
//     distance: 648,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224622032',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919719240664',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Free Bird',
//     id: 'here:pds:place:356jx7ps-cea5ef612d5b0e183552fb37dbff31ee',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Free Bird, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5856,
//       lng: 73.99266,
//     },
//     access: [
//       {
//         lat: 18.58555,
//         lng: 73.99252,
//       },
//     ],
//     distance: 648,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981568',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '8449022',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//       {
//         id: '200-000',
//         name: 'Asian',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912065341111',
//           },
//           {
//             value: '+918793802020',
//           },
//         ],
//         mobile: [
//           {
//             value: '+918793802020',
//           },
//         ],
//         www: [
//           {
//             value: 'http://freebirdrestaurant.com',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 00:00 - 01:00, 11:00 - 15:00, 19:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T110000',
//             duration: 'PT04H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//           {
//             start: 'T190000',
//             duration: 'PT06H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Chinese Castle',
//     id: 'here:pds:place:356tek9g-0adc4d0923edb6c9733f9322daa8b958',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Chinese Castle, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59016,
//       lng: 74.00126,
//     },
//     access: [
//       {
//         lat: 18.59028,
//         lng: 74.00119,
//       },
//     ],
//     distance: 656,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Nisarg Biryani Catering',
//     id: 'here:pds:place:356tek9g-dc42a788fb2c19fa35eee3c9db172680',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Nisarg Biryani Catering, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59039,
//       lng: 74.00129,
//     },
//     access: [
//       {
//         lat: 18.59044,
//         lng: 74.00128,
//       },
//     ],
//     distance: 656,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '700-7400-0284',
//         name: 'Wedding Services & Bridal Studio',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919657049362',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//         mobile: [
//           {
//             value: '+919146067789',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: '+919657049362',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Sairaj Chiniese & Nashta Centre',
//     id: 'here:pds:place:356jx7ps-cd7cc7e38d7706d8f11b68734908cfc7',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Sairaj Chiniese & Nashta Centre, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5903,
//       lng: 74.00129,
//     },
//     access: [
//       {
//         lat: 18.59044,
//         lng: 74.00126,
//       },
//     ],
//     distance: 657,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176564536',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-061',
//         name: 'Breakfast',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//   },
//   {
//     title: 'Red Hut',
//     id: 'here:pds:place:356tek9g-5fec89a054ec370aded9dfe6dfe1c0d8',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Red Hut, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59025,
//       lng: 74.00131,
//     },
//     access: [
//       {
//         lat: 18.59032,
//         lng: 74.00128,
//       },
//     ],
//     distance: 660,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Kadams Famous Wadapav Centre',
//     id: 'here:pds:place:356tek9g-25ff2c6876d9e14a9b6070db9163cbc3',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Kadams Famous Wadapav Centre, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59041,
//       lng: 74.00134,
//     },
//     access: [
//       {
//         lat: 18.59045,
//         lng: 74.00133,
//       },
//     ],
//     distance: 661,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224578230',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shree Tirupati Snacks & Bhojnalaya',
//     id: 'here:pds:place:356jx7ps-aa1b5a9ac76a0f1270ec912783cb009b',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shree Tirupati Snacks & Bhojnalaya, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59091,
//       lng: 74.00143,
//     },
//     access: [
//       {
//         lat: 18.59092,
//         lng: 74.00158,
//       },
//     ],
//     distance: 668,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176556158',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919767631702',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919767631702',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Yashraj Snacks Centre',
//     id: 'here:pds:place:356jx7ps-c2b04aab859104bbc3a3932b6bd34d03',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Yashraj Snacks Centre, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59033,
//       lng: 74.00142,
//     },
//     access: [
//       {
//         lat: 18.59046,
//         lng: 74.00139,
//       },
//     ],
//     distance: 671,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569553',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917083951182',
//           },
//         ],
//         mobile: [
//           {
//             value: '+917083951182',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Coffee Cafe Time',
//     id: 'here:pds:place:356jx7ps-e889293d20280871f2b7d99f16f2c36f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Coffee Cafe Time, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59069,
//       lng: 74.00148,
//     },
//     access: [
//       {
//         lat: 18.59072,
//         lng: 74.00162,
//       },
//     ],
//     distance: 674,
//     categories: [
//       {
//         id: '100-1100-0010',
//         name: 'Coffee Shop',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176557425',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919623791190',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919623791190',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Cafe Rush',
//     id: 'here:pds:place:356jx7ps-0037c0989359062732cefb0cb7296a40',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Cafe Rush, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5904,
//       lng: 74.0015,
//     },
//     access: [
//       {
//         lat: 18.59051,
//         lng: 74.00158,
//       },
//     ],
//     distance: 678,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176567984',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917040402496',
//           },
//         ],
//         mobile: [
//           {
//             value: '+917040402496',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Cafe Rudro Be Cafinated',
//     id: 'here:pds:place:356tek9e-c9e26d22c32a60eab4a916dbdceb64b9',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Cafe Rudro Be Cafinated, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58521,
//       lng: 73.99247,
//     },
//     access: [
//       {
//         lat: 18.58534,
//         lng: 73.99242,
//       },
//     ],
//     distance: 695,
//     categories: [
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Krushna',
//     id: 'here:pds:place:356jx7ps-aedd34ab07c0024aeb5310d2d8728a1d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Krushna, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59062,
//       lng: 74.00168,
//     },
//     access: [
//       {
//         lat: 18.5905,
//         lng: 74.00159,
//       },
//     ],
//     distance: 696,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104856448',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Sharyu Chinese Corner',
//     id: 'here:pds:place:356tek9g-62d896932e7aa39e1bf9c2c3656a1665',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Sharyu Chinese Corner, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59031,
//       lng: 74.00173,
//     },
//     access: [
//       {
//         lat: 18.59044,
//         lng: 74.0017,
//       },
//     ],
//     distance: 703,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Wagheshwar Snacks Centre & Chinese House',
//     id: 'here:pds:place:356jx7ps-fd1bf6c7a1ee0f0a343e0f9e4a75a468',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Wagheshwar Snacks Centre & Chinese House, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59045,
//       lng: 74.00176,
//     },
//     access: [
//       {
//         lat: 18.59058,
//         lng: 74.00171,
//       },
//     ],
//     distance: 705,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176556742',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176567239',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '800-061',
//         name: 'Breakfast',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+918975702800',
//           },
//         ],
//         mobile: [
//           {
//             value: '+918446248385',
//           },
//           {
//             value: '+918975702800',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Muktai Lunch Home',
//     id: 'here:pds:place:356jx7ps-7ec58190a8df09a3dda258636737e33d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Muktai Lunch Home, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59032,
//       lng: 74.00178,
//     },
//     access: [
//       {
//         lat: 18.59045,
//         lng: 74.00175,
//       },
//     ],
//     distance: 709,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176567335',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-081',
//         name: 'Lunch',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919766557555',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919766557555',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Savli Snacks and Food Centre',
//     id: 'here:pds:place:356jx7ps-6eb12ecb72540311565dd66105c49919',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Savli Snacks and Food Centre, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59079,
//       lng: 74.00183,
//     },
//     access: [
//       {
//         lat: 18.59064,
//         lng: 74.00189,
//       },
//     ],
//     distance: 711,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174691946',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Samadhan Poha',
//     id: 'here:pds:place:356tek9g-25b370ab8993de5e6e3f9bb7e9d8e7ff',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Samadhan Poha, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59016,
//       lng: 74.00181,
//     },
//     access: [
//       {
//         lat: 18.59045,
//         lng: 74.00175,
//       },
//     ],
//     distance: 714,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224417185',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Bollywood Biryani Centre',
//     id: 'here:pds:place:356tek9g-511bd784d5970f604d82d55c8f037359',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Bollywood Biryani Centre, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5894,
//       lng: 74.00169,
//     },
//     access: [
//       {
//         lat: 18.58964,
//         lng: 74.00249,
//       },
//     ],
//     distance: 717,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shegaon Kachori Centre',
//     id: 'here:pds:place:356tek9e-b29acd6ac1a14e76978d6a5aff162c94',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shegaon Kachori Centre, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58453,
//       lng: 73.99619,
//     },
//     access: [
//       {
//         lat: 18.5844,
//         lng: 73.99616,
//       },
//     ],
//     distance: 723,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '600-6900-0096',
//         name: 'Specialty Store',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1192028798',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224251453',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-062',
//         name: 'Chicken',
//         primary: true,
//       },
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//       },
//     ],
//   },
//   {
//     title: 'Hangyo Ice Cream Shop',
//     id: 'here:pds:place:356tek9g-77c2c57f9e8ef2a0f0417802f1865b46',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hangyo Ice Cream Shop, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59059,
//       lng: 74.00198,
//     },
//     access: [
//       {
//         lat: 18.59053,
//         lng: 74.002,
//       },
//     ],
//     distance: 727,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-063',
//         name: 'Ice Cream',
//         primary: true,
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 07:30 - 22:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T073000',
//             duration: 'PT14H30M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Chatak Matak Veg Nonveg',
//     id: 'here:pds:place:356jx7ps-1ddcbf1b2e460195632ba19ac00f184f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Chatak Matak Veg Nonveg, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59059,
//       lng: 74.00214,
//     },
//     access: [
//       {
//         lat: 18.59071,
//         lng: 74.00209,
//       },
//     ],
//     distance: 744,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176557932',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//       {
//         id: '800-077',
//         name: 'Vegetarian',
//       },
//     ],
//   },
//   {
//     title: 'Maharaja Wadapav',
//     id: 'here:pds:place:356tek9g-36cae7b963731bceda8b485dd0dff655',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Maharaja Wadapav, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59073,
//       lng: 74.00215,
//     },
//     access: [
//       {
//         lat: 18.59073,
//         lng: 74.00215,
//       },
//     ],
//     distance: 744,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224621581',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Mangalmurti Bhojnalaya',
//     id: 'here:pds:place:356tek9g-fff20afd46ae59694771ed45fda6daf8',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Mangalmurti Bhojnalaya, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59023,
//       lng: 74.00213,
//     },
//     access: [
//       {
//         lat: 18.59026,
//         lng: 74.00242,
//       },
//     ],
//     distance: 746,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224595434',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919011216959',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Nitin Bhojnalaya',
//     id: 'here:pds:place:356tek9g-71460e20e0436dc246913cf90dabc6f5',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Nitin Bhojnalaya, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59024,
//       lng: 74.00214,
//     },
//     access: [
//       {
//         lat: 18.59028,
//         lng: 74.00242,
//       },
//     ],
//     distance: 747,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224735684',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hot N Spicy Chinese',
//     id: 'here:pds:place:356tek9e-e5557c3435a1c9e72233429490c2f152',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hot N Spicy Chinese, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58427,
//       lng: 73.99613,
//     },
//     access: [
//       {
//         lat: 18.5844,
//         lng: 73.99616,
//       },
//     ],
//     distance: 751,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224641647',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//       {
//         id: '201-000',
//         name: 'Chinese',
//       },
//     ],
//   },
//   {
//     title: 'Gabbu Vada Pav',
//     id: 'here:pds:place:356tek9g-9f35a36f2f85f2183933cdfdd2f3180c',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Gabbu Vada Pav, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5901,
//       lng: 74.00216,
//     },
//     access: [
//       {
//         lat: 18.59013,
//         lng: 74.00244,
//       },
//     ],
//     distance: 751,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224582518',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//   },
//   {
//     title: 'Myshaas Biryani',
//     id: 'here:pds:place:356tek9e-d1cb12bcf95a06c264a0d491f21383fa',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Myshaas Biryani, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58445,
//       lng: 73.99708,
//     },
//     access: [
//       {
//         lat: 18.58427,
//         lng: 73.99705,
//       },
//     ],
//     distance: 753,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224605216',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919561188433',
//           },
//         ],
//         www: [
//           {
//             value:
//               'https://myshaas-biryani.business.site/?utm_source=gmb&utm_medium=referral',
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 11:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T110000',
//             duration: 'PT13H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Haridwar',
//     id: 'here:pds:place:356jx7ps-d28ebd75fbc1020fa8818e8520bc23bb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Haridwar, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59058,
//       lng: 74.00225,
//     },
//     access: [
//       {
//         lat: 18.59058,
//         lng: 74.00225,
//       },
//     ],
//     distance: 756,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569763',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Cafe Creamy Crush',
//     id: 'here:pds:place:356tek9g-d081e85e177baab133ea2419d79f78b7',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Cafe Creamy Crush, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59085,
//       lng: 74.00231,
//     },
//     access: [
//       {
//         lat: 18.5908,
//         lng: 74.00232,
//       },
//     ],
//     distance: 761,
//     categories: [
//       {
//         id: '100-1100-0010',
//         name: 'Coffee Shop',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919420211768',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Engineers Katta',
//     id: 'here:pds:place:356tek9g-f06174fb3f57ec4672ccab19df48a2a4',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Engineers Katta, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59006,
//       lng: 74.00225,
//     },
//     access: [
//       {
//         lat: 18.59008,
//         lng: 74.00244,
//       },
//     ],
//     distance: 761,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224415128',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919403635767',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Cafe Campus Stories',
//     id: 'here:pds:place:356tek9g-0226c8a5af7c11290c46a6856d3addff',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Cafe Campus Stories, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59022,
//       lng: 74.00228,
//     },
//     access: [
//       {
//         lat: 18.59023,
//         lng: 74.00242,
//       },
//     ],
//     distance: 762,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '100-1000-0007',
//         name: 'Cafeteria',
//       },
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224613248',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '100-1000-0007',
//           },
//         ],
//         text: ['Mon-Sun: 09:00 - 21:00'],
//         isOpen: false,
//         structured: [
//           {
//             start: 'T090000',
//             duration: 'PT12H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Spoon & Me',
//     id: 'here:pds:place:356jx7ps-e026e00b1c08021fdeea3dce507c62a2',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Spoon & Me, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58984,
//       lng: 74.00242,
//     },
//     access: [
//       {
//         lat: 18.58984,
//         lng: 74.00247,
//       },
//     ],
//     distance: 782,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '100-1100-0331',
//         name: 'Tea House',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176555963',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919096973255',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//         mobile: [
//           {
//             value: '+919096973255',
//           },
//           {
//             value: '+919561679900',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'The Cafe Evening',
//     id: 'here:pds:place:356tek9e-cfdb1c9bd28837b78667a551f329ca23',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'The Cafe Evening, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58428,
//       lng: 73.99752,
//     },
//     access: [
//       {
//         lat: 18.5842,
//         lng: 73.99751,
//       },
//     ],
//     distance: 785,
//     categories: [
//       {
//         id: '100-1100-0010',
//         name: 'Coffee Shop',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//       {
//         id: '100-1000-0007',
//         name: 'Cafeteria',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224736332',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//   },
//   {
//     title: 'Hotel Bhairavnath',
//     id: 'here:pds:place:356jx7ps-9e2b62ab8ec10b92bd0bb83cb9008a14',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Bhairavnath, Bhawdi Road, Phool Mala-Tambe Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Phool Mala-Tambe Nagar',
//       street: 'Bhawdi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58958,
//       lng: 73.98778,
//     },
//     access: [
//       {
//         lat: 18.58957,
//         lng: 73.98792,
//       },
//     ],
//     distance: 785,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '600-6300-0364',
//         name: 'Dairy Goods',
//       },
//       {
//         id: '600-6900-0096',
//         name: 'Specialty Store',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176564912',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176568657',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919822478095',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919730178931',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: '+919822478095',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotelkalvan',
//     id: 'here:pds:place:356tek9g-d573acaae318bcfc335c0a23eefb5a76',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hotelkalvan, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59053,
//       lng: 74.00253,
//     },
//     access: [
//       {
//         lat: 18.59051,
//         lng: 74.00239,
//       },
//     ],
//     distance: 786,
//     categories: [
//       {
//         id: '100-1000-0001',
//         name: 'Casual Dining',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Samarth Snacks & Pavbhaji Centre',
//     id: 'here:pds:place:356tek9g-485705d7af0715e96c615f3b82604832',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Samarth Snacks & Pavbhaji Centre, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59049,
//       lng: 74.0026,
//     },
//     access: [
//       {
//         lat: 18.59047,
//         lng: 74.0024,
//       },
//     ],
//     distance: 793,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224640312',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918698523728',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Claypot',
//     id: 'here:pds:place:356tek9e-1ed2bdec6b24b88a258a464a00745d5e',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Claypot, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5843,
//       lng: 73.99782,
//     },
//     access: [
//       {
//         lat: 18.58416,
//         lng: 73.9978,
//       },
//     ],
//     distance: 793,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224594131',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Moms Tiffin',
//     id: 'here:pds:place:356tek9g-c00b78d68abe975fe0eefb2237a21765',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Moms Tiffin, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59029,
//       lng: 74.00263,
//     },
//     access: [
//       {
//         lat: 18.59027,
//         lng: 74.00242,
//       },
//     ],
//     distance: 798,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224634572',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Mumbai Biryani Chinese & Kebab Centre',
//     id: 'here:pds:place:356tek9g-96ca56e76ba1ca1d3a7a08f97f2b4272',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Mumbai Biryani Chinese & Kebab Centre, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59036,
//       lng: 74.00264,
//     },
//     access: [
//       {
//         lat: 18.59034,
//         lng: 74.00241,
//       },
//     ],
//     distance: 798,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224411620',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '201-000',
//         name: 'Chinese',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//       {
//         id: '250-000',
//         name: 'Middle Eastern',
//       },
//       {
//         id: '800-062',
//         name: 'Chicken',
//       },
//     ],
//   },
//   {
//     title: 'MPS Kitchenn',
//     id: 'here:pds:place:356tek9e-672f9455088c46d79d264a27c91aa97e',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'MPS Kitchenn, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58416,
//       lng: 73.99759,
//     },
//     access: [
//       {
//         lat: 18.58419,
//         lng: 73.99759,
//       },
//     ],
//     distance: 800,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1192028677',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919130535059',
//           },
//           {
//             value: '+919764880187',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Jijau Bhojnalaya',
//     id: 'here:pds:place:356tek9g-d6c2415efa8a0ef1851ef85c646e7604',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Jijau Bhojnalaya, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59068,
//       lng: 74.00269,
//     },
//     access: [
//       {
//         lat: 18.59091,
//         lng: 74.00259,
//       },
//     ],
//     distance: 802,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224592020',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918390693952',
//           },
//           {
//             value: '+918668279019',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Diamond Cafe',
//     id: 'here:pds:place:356tek9g-882e00262598ae5beed2322aef5bfdce',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Diamond Cafe, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59084,
//       lng: 74.0027,
//     },
//     access: [
//       {
//         lat: 18.59097,
//         lng: 74.00275,
//       },
//     ],
//     distance: 802,
//     categories: [
//       {
//         id: '100-1100-0010',
//         name: 'Coffee Shop',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hotel Athwan',
//     id: 'here:pds:place:356tek9g-e666893a787ac35d13aa2727c784b37b',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Athwan, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59023,
//       lng: 74.00268,
//     },
//     access: [
//       {
//         lat: 18.5902,
//         lng: 74.00243,
//       },
//     ],
//     distance: 804,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224590587',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918975972841',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Sunil',
//     id: 'here:pds:place:356tek9e-4e51d65c2f6b693c9f3d1542150c2819',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Sunil, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58528,
//       lng: 73.99991,
//     },
//     access: [
//       {
//         lat: 18.58529,
//         lng: 74.00005,
//       },
//     ],
//     distance: 810,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224635453',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shravni Mess Service',
//     id: 'here:pds:place:356tek9e-8c24ed47ca7b5b4ac77aa4c6b59e27cf',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Shravni Mess Service, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58526,
//       lng: 73.99993,
//     },
//     access: [
//       {
//         lat: 18.58529,
//         lng: 74.00005,
//       },
//     ],
//     distance: 813,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224631382',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Swami Samarth Snacks Hotel Yash',
//     id: 'here:pds:place:356jx7ps-564f0db0dc3500f214be1ab21760bfa3',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Swami Samarth Snacks Hotel Yash, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58454,
//       lng: 73.99136,
//     },
//     access: [
//       {
//         lat: 18.58441,
//         lng: 73.99145,
//       },
//     ],
//     distance: 814,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981482',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919881085587',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Bhairavnath',
//     id: 'here:pds:place:356jx7ps-98d827d5dec201be43bf7d72168a8d02',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Bhairavnath, Bhawdi Road, Phool Mala-Tambe Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Phool Mala-Tambe Nagar',
//       street: 'Bhawdi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58846,
//       lng: 73.98782,
//     },
//     access: [
//       {
//         lat: 18.58846,
//         lng: 73.98782,
//       },
//     ],
//     distance: 815,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Mahadev Dining Hall',
//     id: 'here:pds:place:356tek9e-627e3075f1f3f65199c4d62bfc7fb6e0',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Mahadev Dining Hall, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5845,
//       lng: 73.99133,
//     },
//     access: [
//       {
//         lat: 18.58438,
//         lng: 73.9914,
//       },
//     ],
//     distance: 819,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+918208090460',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Savera Foods',
//     id: 'here:pds:place:356tek9e-df413cf6632119c4e138e49ff9050cb7',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Savera Foods, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58429,
//       lng: 73.99843,
//     },
//     access: [
//       {
//         lat: 18.58408,
//         lng: 73.9984,
//       },
//     ],
//     distance: 820,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224575370',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Mata Rani Snacks Centre',
//     id: 'here:pds:place:356tek9e-2b8588ea0016460d83d02f33003b30c4',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Mata Rani Snacks Centre, Ivy Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ivy Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58429,
//       lng: 73.99843,
//     },
//     access: [
//       {
//         lat: 18.58408,
//         lng: 73.99838,
//       },
//     ],
//     distance: 820,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1192028797',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-058',
//         name: 'Snacks & Beverages',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919822685292',
//           },
//         ],
//       },
//     ],
//   },
// ];

export const NearbyRestaurants = () => {
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(10);
  const { data, isLoading, isError, refetch } = useSearchLocation({
    variables: {
      categoryType: 'food', //@ts-ignore
      gps: `${location?.coords?.latitude},${location?.coords?.longitude}`,
      distance: distance * 1000,
    },
  });
  console.log(data);

  const debounedSetState = debounce((value) => {
    setDistance(value);
  }, 500);

  const renderItem = React.useCallback(
    //  @ts-ignore
    ({ item }) => <LocationCard {...item} />,
    []
  );

  useEffect(() => {
    (async () => {
      const currentLocation = await getCurrentLocation(); //@ts-ignore
      setLocation(currentLocation);
    })();
  }, []);

  useEffect(() => {
    refetch();
  }, [location, refetch, distance]);

  return (
    <>
      <ScrollView className="p-4 dark:bg-themeblue-600">
        <Text className="mb-4">Adjust the radius for nearby activities</Text>
        <Slider
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ width: '100%', height: 40 }}
          minimumValue={0}
          maximumValue={50}
          step={1}
          value={distance}
          minimumTrackTintColor={colors.themeblue[400]}
          maximumTrackTintColor={colors.themeblue[300]}
          thumbTintColor={colors.themeblue[400]}
          onValueChange={(value: number) => {
            debounedSetState(value);
          }}
        />
        <View className="flex-row justify-between">
          <Text className="m-2">1km</Text>
          <Text className="m-2">50km</Text>
        </View>
        <Text>Activities within {distance} kms</Text>
        {isLoading && (
          <View className="flex-1  justify-center">
            <ActivityIndicator />
          </View>
        )}
        {isError && (
          <View>
            <Text> Error Loading data </Text>
          </View>
        )}
        {data && (
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(_, index) => `item-${index}`}
            ListEmptyComponent={<EmptyList isLoading={false} />}
            // estimatedItemSize={199}
          />
        )}
      </ScrollView>
    </>
  );
};
