import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { ActivityIndicator, Button, FlatList, StyleSheet } from 'react-native';
import { CheckBox } from 'react-native-elements';
import { showMessage } from 'react-native-flash-message';

import { useMyTripChecklist, useTripUpdateChecklist } from '@/api';
import {
  FocusAwareStatusBar,
  ScrollView,
  showErrorMessage,
  Text,
  View,
} from '@/ui';

//@ts-ignore
const SubChecklistItem = ({ item, parentId }) => {
  const { mutate: updateChecklistStatus } = useTripUpdateChecklist();
  const [status, setStatus] = React.useState(item.status);

  const handleCheckboxPress = () => {
    updateChecklistStatus(
      {
        clonedChecklistId: parentId,
        clonedSubchecklistId: item.id,
        status: status === 'done' ? 'todo' : 'done',
      },
      {
        onSuccess: () => {
          showMessage({
            message: 'Updated',
            type: 'success',
          });

          setStatus(status === 'done' ? 'todo' : 'done');
          // navigate('MyTrips'); //{ id: res.clonedTrip._id }); //res.clonedTrip.id
          // here you can navigate to the post list and refresh the list data
          //queryClient.invalidateQueries(usePosts.getKey());
        },
        onError: () => {
          showErrorMessage('Error updating status');
        },
      }
    );
    // Update the checklist status based on the checkbox press
    // You can update the state or perform any other logic here
  };
  return (
    <View style={styles.subChecklistItem}>
      <CheckBox
        title={item.name}
        checked={status === 'done'}
        onPress={() => handleCheckboxPress()}
      />
      {/* {item.price && <Text className="pl-12">{item.price}</Text>} */}
      {/* {item.description && <Text className="pl-12">{item.description}</Text>} */}
    </View>
  );
};
//@ts-ignore
const ChecklistItem = ({ item }) => {
  const { mutate: updateChecklistStatus } = useTripUpdateChecklist();
  const [subchecklistVisible, setSubchecklistVisible] = React.useState(false);
  const [status, setStatus] = React.useState(item.status);

  const handleCheckboxPress = () => {
    updateChecklistStatus(
      {
        clonedChecklistId: item.id,
        clonedSubchecklistId: null,
        status: status === 'done' ? 'todo' : 'done',
      },
      {
        onSuccess: () => {
          showMessage({
            message: 'Updated',
            type: 'success',
          });

          setStatus(status === 'done' ? 'todo' : 'done');
          // navigate('MyTrips'); //{ id: res.clonedTrip._id }); //res.clonedTrip.id
          // here you can navigate to the post list and refresh the list data
          //queryClient.invalidateQueries(usePosts.getKey());
        },
        onError: () => {
          showErrorMessage('Error updating status');
        },
      }
    );
    // Update the checklist status based on the checkbox press
    // You can update the state or perform any other logic here
  };

  return (
    <View
      style={styles.checklistItem}
      // className="border-[1px] border-solid border-[cornflowerblue]"
    >
      {item.subchecklist ? (
        <View className="relative">
          <CheckBox
            title={item.name}
            checked={status === 'done'}
            onPress={() => handleCheckboxPress()}
          />
          {subchecklistVisible && (
            <FlatList
              data={item.subchecklist} //@ts-ignore
              renderItem={({ item: subitem }) => (
                <SubChecklistItem
                  item={subitem}
                  parentId={item.id}
                  // onCheckboxPress={onCheckboxPress}
                />
              )}
              keyExtractor={(subitem) => subitem.name}
            />
          )}
          <View className="absolute right-4 top-3">
            <Button
              title={subchecklistVisible ? 'Hide' : 'Show'}
              onPress={() => setSubchecklistVisible(!subchecklistVisible)}
            />
          </View>
        </View>
      ) : (
        <CheckBox
          title={item.name}
          checked={status === 'done'}
          onPress={() => handleCheckboxPress()}
        />
      )}
    </View>
  );
};

//@ts-ignore
export const MyTripDetailChecklist = ({ route }) => {
  const { data, isLoading, isError } = useMyTripChecklist({
    variables: { id: route.params.id },
  });

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }
  //@ts-ignore
  return (
    <ScrollView
      style={styles.container}
      className="bg-white dark:bg-themeblue-600"
    >
      <FlashList
        data={data}
        renderItem={({ item }) => (
          <ChecklistItem
            item={item}
            // onCheckboxPress={handleCheckboxPress}
          />
        )}
        keyExtractor={(item) => item.name}
      />
      <View className="pb-16" />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  checklistItem: {
    marginBottom: 16,
  },
  subChecklistItem: {
    marginLeft: 16,
    marginTop: 8,
  },
});
// const styles = StyleSheet.create({
//   container: {
//     padding: 16,
//   },
//   bulletPointContainer: {
//     flexDirection: 'column',
//     // alignItems: '',
//     marginBottom: 8,
//   },
//   bulletPoint: {
//     fontSize: 20,
//     marginRight: 8,
//     color: 'black',
//   },
//   bulletText: {
//     fontSize: 16,
//   },
//   subBulletList: {
//     marginLeft: 24, // Adjust indentation for sub-bullets
//   },
// });
