/* eslint-disable react-native/no-inline-styles */
import { FlashList } from '@shopify/flash-list';
import { useColorScheme } from 'nativewind';
import React from 'react';

import { colors, EmptyList, Text, TouchableOpacity, View } from '@/ui';
import { DropMenu } from '@/ui/icons';

import { LocationCard } from './location-card';
//  @ts-ignore
export const DaywiseDropdown = ({ item }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const { colorScheme } = useColorScheme();
  const iconColor =
    colorScheme === 'dark' ? colors.neutral[400] : colors.neutral[500];

  const toggleItinerary = () => {
    setIsExpanded(!isExpanded);
  };

  const renderItem = React.useCallback(
    //  @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ item }) => <LocationCard {...item} />,
    []
    // [navigate]
  );

  return (
    <View style={{ margin: 10 }}>
      <TouchableOpacity
        onPress={toggleItinerary}
        className="flex-row justify-between"
      >
        <Text
          style={{ fontSize: 18, fontWeight: 'bold' }}
        >{`Day ${item.day}`}</Text>
        {isExpanded ? (
          <DropMenu color={iconColor} />
        ) : (
          <View className="rotate-180">
            <DropMenu color={iconColor} />
          </View>
        )}
      </TouchableOpacity>
      {
        isExpanded && ( //  @ts-ignore
          // item.data.map((itineraryItem) => (
          // <View key={itineraryItem.id} style={{ marginVertical: 5 }}>
          //   <Text>{`Order: ${itineraryItem.order}`}</Text>
          //   <Text>{`Title: ${itineraryItem.title}`}</Text>
          //   <Text>{`Description: ${itineraryItem.description}`}</Text>
          // </View>
          <FlashList
            data={item.data}
            renderItem={renderItem}
            keyExtractor={(_, index) => `item-${index}`}
            ListEmptyComponent={<EmptyList isLoading={false} />}
            estimatedItemSize={199}
          />
        )
        // ))
      }
    </View>
  );
};
