// import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useColorScheme } from 'nativewind';
import * as React from 'react';

import { useMyTripCommonDetail } from '@/api';
import { openLinkInBrowser } from '@/core';
import {
  MyTripDetailChecklist,
  MyTripDetailCost,
  MyTripDetailInfo,
  MyTripDetailItinerary,
  MyTripDetailStays,
} from '@/screens';
import {
  ActivityIndicator,
  Button,
  // Pressable, Text,
  colors,
  EmptyList,
  FocusAwareStatusBar,
  Text,
  View,
} from '@/ui';

export type MyTripDetailStackParamList = {
  Itinerary: { id: number };
  Food: { id: number };
  Stays: { id: number; bookingType: string };
  Checklist: { id: number };
  About: { id: number };
  Cost: { id: number };
};

// const Stack = createNativeStackNavigator<NearbyStackParamList>();

// const GoToAddPost = () => {
//   return (
//     <Pressable onPress={() => {}} className="p-2">
//       <Text className="text-primary-300">Create</Text>
//     </Pressable>
//   );
// };
const Tab = createMaterialTopTabNavigator<MyTripDetailStackParamList>();

type Props = {
  navigation: any;
  route: any;
};

export const MyTripDetailNavigator = ({ navigation, route }: Props) => {
  const { colorScheme } = useColorScheme();
  React.useLayoutEffect(() => {
    navigation.setOptions({ title: route.params?.name });
  }, [navigation, route]);

  const { data, isLoading, isError } = useMyTripCommonDetail({
    variables: { id: route.params.id },
  });

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }
  //@ts-ignore
  const generateGoogleMapsRouteLink = (coordinates) => {
    if (coordinates.length < 2) {
      // Handle case with less than two coordinates
      return null;
    }

    const origin = `${coordinates[0].latitude},${coordinates[0].longitude}`;
    const destination = `${coordinates[coordinates.length - 1].latitude},${
      coordinates[coordinates.length - 1].longitude
    }`;
    const waypoints = coordinates
      .slice(1, -1) //@ts-ignore
      .map((coord) => `${coord.latitude},${coord.longitude}`)
      .join('|');

    // Construct the Google Maps URL
    const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints}`;

    return url;
  };
  return (
    // <NavigationContainer independent>
    <>
      <Tab.Navigator
        initialRouteName="Itinerary"
        screenOptions={{
          tabBarScrollEnabled: true,
          tabBarLabelStyle: {
            fontSize: 12,
            fontWeight: 'bold',
            // color: colors.black[500],
          },
          tabBarIndicatorStyle: {
            backgroundColor:
              colorScheme === 'dark' ? colors.white : colors.themeblue[500],
            height: '100%',
          },
          tabBarActiveTintColor:
            colorScheme === 'dark' ? colors.themeblue[500] : colors.white,
          tabBarInactiveTintColor:
            colorScheme === 'dark' ? colors.white : colors.themeblue[500],
          tabBarStyle: {
            // width: 'auto',
            // marginTop: Platform.OS === 'ios' ? 100 : 60,
            backgroundColor:
              colorScheme === 'dark' ? colors.themeblue[500] : colors.white,
            // position: 'absolute',
            // elevation: 0,
            // bottom: 5,
          },
          lazy: true,
          lazyPlaceholder: () => <EmptyList isLoading={true} />,
          lazyPreloadDistance: 0,
        }}
      >
        <Tab.Screen
          name="About"
          component={MyTripDetailInfo}
          initialParams={{ id: route?.params?.id }}
        />
        <Tab.Screen
          name="Itinerary"
          component={MyTripDetailItinerary}
          initialParams={{ id: route?.params?.id }}
        />
        <Tab.Screen
          name="Stays"
          component={MyTripDetailStays}
          initialParams={{
            id: route?.params?.id,
            bookingType: route?.params?.bookingType,
          }}
        />
        <Tab.Screen
          name="Checklist"
          component={MyTripDetailChecklist}
          initialParams={{ id: route?.params?.id }}
        />
        <Tab.Screen
          name="Cost"
          component={MyTripDetailCost}
          initialParams={{ id: route?.params?.id }}
        />
      </Tab.Navigator>
      <View className="absolute bottom-2 flex w-full flex-row justify-evenly">
        <Button
          label="Route"
          variant="secondary"
          onPress={() =>
            openLinkInBrowser(
              //@ts-ignore
              generateGoogleMapsRouteLink(data?.sortedLocations)
            )
          }
        />
        {/* <Button label="Clone" variant="secondary" /> */}
      </View>
    </>
  );
};
