import React, { useEffect } from 'react';
import { showMessage } from 'react-native-flash-message';

import { useAuthLogin } from '@/api';
import { useAuth } from '@/core';
import { useSoftKeyboardEffect } from '@/core/keyboard';
import { FocusAwareStatusBar, showErrorMessage } from '@/ui';

import type { LoginFormProps } from './login-form';
import { LoginForm } from './login-form';

/* @ts-ignore */
export const Login = ({ route }) => {
  const signIn = useAuth.use.signIn();
  const { mutate: apiLogin, isLoading } = useAuthLogin();
  useSoftKeyboardEffect();

  useEffect(() => {
    if (route?.params?.token) {
      signIn({ access: route.params.token, refresh: 'refresh-token' });
    }
  }, [route?.params?.token, signIn]);

  const onSubmit: LoginFormProps['onSubmit'] = (data) => {
    apiLogin(
      { ...data },
      {
        onSuccess: (res) => {
          showMessage({
            message: res?.message,
            type: 'success',
          });
          signIn({ access: res?.token, refresh: 'refresh-token' });
          // here you can navigate to the post list and refresh the list data
          //queryClient.invalidateQueries(usePosts.getKey());
        },
        onError: (res) => {
          //@ts-ignore
          showErrorMessage(res?.response?.data?.message);
        },
      }
    );
    // signIn({ access: 'access-token', refresh: 'refresh-token' });
  };
  return (
    <>
      <FocusAwareStatusBar />
      <LoginForm onSubmit={onSubmit} disable={isLoading} />
    </>
  );
};
