import * as React from 'react';
import { Platform } from 'react-native';

import { useSelectedLanguage } from '@/core';
import { translate } from '@/core';
import type { Language } from '@/core/i18n/resources';
import type { Option } from '@/ui';
import { Options, useModal } from '@/ui';

import { Item } from './item';

export const LanguageItem = () => {
  const { language, setLanguage } = useSelectedLanguage();
  const [visibility, setVisibility] = React.useState(false);
  const modal = useModal();
  const onSelect = React.useCallback(
    (option: Option) => {
      setLanguage(option.value as Language);
      if (Platform.OS === 'web') {
        setVisibility(false);
      } else {
        modal.dismiss();
      }
    },
    [setLanguage, modal]
  );

  const langs = React.useMemo(
    () => [
      { label: translate('settings.english'), value: 'en' },
      { label: translate('settings.arabic'), value: 'ar' },
    ],
    []
  );

  const selectedLanguage = React.useMemo(
    () => langs.find((lang) => lang.value === language),
    [language, langs]
  );

  return (
    <>
      <Item
        text="settings.language"
        value={selectedLanguage?.label}
        onPress={() =>
          Platform.OS === 'web' ? setVisibility(true) : modal.present()
        }
      />
      {Platform.OS !== 'web' && (
        <Options
          ref={modal.ref}
          options={langs}
          onSelect={onSelect}
          value={selectedLanguage?.value}
          visibility={visibility}
        />
      )}
    </>
  );
};
