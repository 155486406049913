import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useColorScheme } from 'nativewind';
import * as React from 'react';

import { Settings } from '@/screens';
import { colors } from '@/ui';
// import { Pressable, Text } from '@/ui';

export type MyTripsStackParamList = {
  // MyTrips: undefined;
  Settings: undefined;
};

const Stack = createNativeStackNavigator<MyTripsStackParamList>();

// const GoToAddPost = () => {
//   return (
//     <Pressable onPress={() => {}} className="p-2">
//       <Text className="text-primary-300">Create</Text>
//     </Pressable>
//   );
// };

type Props = {
  navigation: any;
  route: any;
};

export const SettingsNavigator = ({ navigation, route }: Props) => {
  const { colorScheme } = useColorScheme();
  React.useLayoutEffect(() => {}, [navigation, route]);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle:
          colorScheme === 'dark'
            ? { backgroundColor: colors.themeblue[500] }
            : { backgroundColor: colors.white },
      }}
    >
      {/* <Stack.Group
        screenOptions={{
          // eslint-disable-next-line react/no-unstable-nested-components
          headerRight: () => <GoToAddPost />,
        }}
      >
        <Stack.Screen name="HomeScreen" component={Home} />
      </Stack.Group> */}

      {/* <Stack.Screen name="MyTrips" component={MyTrips} /> */}
      <Stack.Screen
        name="Settings"
        options={{ headerLeft: () => null }}
        component={Settings}
      />
    </Stack.Navigator>
  );
};
