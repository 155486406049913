import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { MyTripChecklist } from './types';

type Variables = { id: string };
type Response = MyTripChecklist;

export const useMyTripChecklist = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'checklist',
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    return clientWithAuthHeader
      .get(`myTrip/${variables.id}/${primaryKey}`)
      .then((response) => response.data);
  },
});
