import * as React from 'react';
import type { SvgProps } from 'react-native-svg';
import Svg, { Path } from 'react-native-svg';

export function Copy({ color = '#000', ...props }: SvgProps) {
  return (
    <Svg width={14} height={14} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 2C6.48 2 2 6.48 2 12c0 1.74.46 3.36 1.26 4.77l1.67-1.67C5.3 14.05 5 13.05 5 12c0-4.42 3.58-8 8-8s8 3.58 8 8-3.58 8-8 8c-1.05 0-2.05-.3-2.9-.82l-1.67 1.67c1.41.8 3.03 1.26 4.77 1.26 5.52 0 10-4.48 10-10S17.52 2 12 2zM9.2 10.57l-1.68 1.68c.52.64.98 1.32 1.41 2.03.79-1.18 1.8-2.17 3-3l-2.73-2.73c-1.18 1.2-2.17 2.21-3 3.02.7.43 1.39.89 2.03 1.4zm5.25 2.2c-.78.79-1.76 1.44-2.87 1.92l2.73 2.73c1.18-1.18 2.17-2.19 3.02-3l-2.88-2.88c-.6.46-1.14.97-1.67 1.23z"
        fill={color}
      />
    </Svg>
  );
}
