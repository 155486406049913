import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useColorScheme } from 'nativewind';
import * as React from 'react';

import { MyTrips } from '@/screens';
import { colors } from '@/ui';

import { MyTripDetailNavigator } from './my-trips-details-navigator';

export type MyTripsStackParamList = {
  MyTrips: undefined;
  MyTripDetails: { id: string; bookingType: string };
};

const Stack = createNativeStackNavigator<MyTripsStackParamList>();

// const GoToAddPost = () => {
//   return (
//     <Pressable onPress={() => {}} className="p-2">
//       <Text className="text-primary-300">Create</Text>
//     </Pressable>
//   );
// };

type Props = {
  navigation: any;
  route: any;
};

export const MyTripsNavigator = ({ navigation, route }: Props) => {
  const { colorScheme } = useColorScheme();
  React.useLayoutEffect(() => {}, [navigation, route]);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle:
          colorScheme === 'dark'
            ? { backgroundColor: colors.themeblue[500] }
            : { backgroundColor: colors.white },
      }}
    >
      {/* <Stack.Group
        screenOptions={{
          // eslint-disable-next-line react/no-unstable-nested-components
          headerRight: () => <GoToAddPost />,
        }}
      >
        <Stack.Screen name="HomeScreen" component={Home} />
      </Stack.Group> */}

      <Stack.Screen
        name="MyTrips"
        options={{ headerLeft: () => null }}
        component={MyTrips}
      />
      <Stack.Screen name="MyTripDetails" component={MyTripDetailNavigator} />
    </Stack.Navigator>
  );
};
