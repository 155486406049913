import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { searchLocation } from './types';

type Response = searchLocation[];
type Variables = {
  gps: string | null;
  categoryType: string;
  distance: number | null;
}; // as react-query-kit is strongly typed, we need to specify the type of the variables as void in case we don't need them

export const useSearchLocation = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'searchLocation', // we recommend using  endpoint base url as primaryKey
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    // in case if variables is needed, we can use destructuring to get it from queryKey array like this: ({ queryKey: [primaryKey, variables] })
    // primaryKey is 'posts' in this case
    return clientWithAuthHeader
      .get(
        `${primaryKey}/nearby?type=${variables?.categoryType}&range=${variables?.distance}&at=${variables?.gps}`
      )
      .then((response) => response.data?.items);
  },
});
