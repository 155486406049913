import React, { useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';

// import type { Post } from '@/api';
import {
  colors,
  Image,
  Pressable,
  ScrollView,
  showErrorMessage,
  Text,
  View,
} from '@/ui';
const { width } = Dimensions.get('window');
import { useColorScheme } from 'nativewind';
import { showMessage } from 'react-native-flash-message';

import { useTripRemoveSaved, useTripSave } from '@/api';
import { Bookmark, Copy, Rate } from '@/ui/icons';

// type Props = Post & { onPress?: () => void };
// @ts-ignore
export const Card = ({
  // @ts-ignore
  // suggestedMembers,
  // @ts-ignore
  name,
  // @ts-ignore
  // nights,
  onPress = () => {},
  // @ts-ignore
  photos,
  // @ts-ignore
  // itineraryCounts,
  // @ts-ignore
  ratings,
  // @ts-ignore
  clones,
  // @ts-ignore
  costRange,
  //@ts-ignore,
  saved,
  //@ts-ignore
  id,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { colorScheme } = useColorScheme();
  const [savedStatus, setSavedStatus] = useState(saved);
  const iconColor = colorScheme === 'dark' ? colors.white : colors.white;
  const { mutate: saveTrip, isLoading: savingLoader } = useTripSave();
  const { mutate: removeSavedTrip, isLoading: removeLoader } =
    useTripRemoveSaved();

  //@ts-ignore
  const handleScroll = (event) => {
    const offset = event.nativeEvent.contentOffset.x;
    const index = Math.round((offset * 2) / width);
    setCurrentIndex(index);
  };

  const handleBookmark = async () => {
    if (!savedStatus) {
      // save API call
      saveTrip(
        {
          id: id,
        },
        {
          onSuccess: () => {
            showMessage({
              message: 'Trip saved successfully',
              type: 'success',
            });
            setSavedStatus(!savedStatus);
          },
          onError: () => {
            showErrorMessage('Error saving post');
          },
        }
      );
    } else {
      //delete saved API call
      removeSavedTrip(
        {
          id: id,
        },
        {
          onSuccess: () => {
            showMessage({
              message: 'Trip unsaved successfully',
              type: 'success',
            });
            setSavedStatus(!savedStatus);
          },
          onError: () => {
            showErrorMessage('Error unsaving post');
          },
        }
      );
    }
  };
  return (
    <Pressable
      className="relative m-2 block w-[92%] overflow-hidden rounded-xl bg-neutral-200 shadow-xl dark:bg-themeblue-500"
      onPress={onPress}
    >
      {/* <Pressable
      className="m-2 block overflow-hidden rounded-xl  bg-neutral-200 p-2 shadow-xl dark:bg-charcoal-900"
      onPress={onPress}
    > */}
      {/* <Touchable onPress={onPress}> */}
      <ScrollView
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        scrollEventThrottle={200}
      >
        {/* @ts-ignore*/}
        {photos.map((image, index) => (
          <Pressable onPress={onPress}>
            <Image
              key={index}
              source={{ uri: image }}
              style={styles.image}
              // contentFit="cover"
            />
          </Pressable>
        ))}
      </ScrollView>
      <View className="absolute top-0 flex h-[15%] w-[100%] flex-row justify-center bg-black px-4 opacity-50" />
      <View className="absolute top-0 flex h-[15%] w-[100%] flex-row items-center justify-between  px-4">
        <Text
          variant="md"
          className="text-white"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.title}
        >
          {name}
        </Text>
        {/* <View className="flex-row items-center"> */}
        {/* <Copy color={iconColor} />
                <Text className="p-2">{clones} Clones</Text> */}
        <Pressable
          className="pt-1"
          onPress={() => handleBookmark()}
          disabled={savingLoader || removeLoader}
        >
          {/* @ts-ignore */}
          <Bookmark color={iconColor} saved={savedStatus} />
        </Pressable>
        {/* </View> */}
      </View>
      <View className="absolute bottom-0 flex h-[25%] w-[100%] justify-center bg-black opacity-50" />
      <View className="absolute bottom-0 flex h-[25%] w-[100%] flex-row justify-between p-2">
        {/* <Text variant="md" className="text-white" style={styles.title}>
                {name}
              </Text>  */}
        <View className="flex-col py-1 px-2">
          <View className="flex-row items-center">
            <Rate color={iconColor} />
            <Text variant="xs" className="text-white">
              {' '}
              {ratings}
            </Text>
          </View>
          <View className="flex-row items-center">
            <Copy color={iconColor} />
            <Text variant="xs" className="text-white">
              {' '}
              {clones}
            </Text>
          </View>
        </View>
        <View>
          <View className="flex items-center justify-center rounded-xl bg-slate-800 py-1 px-2">
            <Text variant="xs" numberOfLines={3} style={styles.title}>
              {costRange.min}{' '}
              {costRange.max && costRange.max !== costRange.min
                ? ' - ' + costRange.max
                : 'INR /'}
            </Text>
            <Text variant="xs" numberOfLines={3} style={styles.title}>
              {costRange.max ? 'INR / ' : ''}Person
            </Text>
          </View>
        </View>
      </View>
      <View className="absolute bottom-0 w-[100%] flex-row justify-center p-2">
        {/* @ts-ignore */}
        {photos?.map((_image, activeIndex) => (
          <View
            className={`m-[1px] rounded-full ${
              activeIndex === currentIndex ? 'bg-themeblue-400' : 'bg-white'
            } p-[2px]`}
          />
        ))}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  image: {
    width: width / 2 - 15,
    height: 320, // Set your desired height
    // resizeMode: 'cover',
    // borderRadius: 24,
  },
  title: {
    // fontSize: 24,
    fontWeight: 'bold',
    color: 'white', // Text color
    // textShadowColor: 'black', // Outline color
    // textShadowOffset: { width: 0, height: 0 }, // Outline offset
    // textShadowRadius: 8, // Outline blur radius
  },

  // }
});
