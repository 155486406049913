import { Env } from '@env';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Platform } from 'react-native';
import * as z from 'zod';

import { openLinkInBrowser } from '@/core';
import { Button, ControlledInput, Image, Pressable, Text, View } from '@/ui';

const schema = z.object({
  name: z.string(),
  email: z
    .string({
      required_error: 'Email is required',
    })
    .email('Invalid email format'),
  password: z
    .string({
      required_error: 'Password is required',
    })
    .min(6, 'Password must be at least 6 characters'),
});

export type FormType = z.infer<typeof schema>;

export type LoginFormProps = {
  onSubmit?: SubmitHandler<FormType>;
  disable?: boolean;
};

export const RegisterForm = ({
  onSubmit = () => {},
  disable,
}: LoginFormProps) => {
  const { navigate } = useNavigation();
  const { handleSubmit, control } = useForm<FormType>({
    resolver: zodResolver(schema),
  });
  return (
    <View className="flex-1 justify-center p-4">
      <Text testID="form-title" variant="h1" className="pb-6 text-center">
        Sign Up
      </Text>

      <ControlledInput
        testID="name"
        control={control}
        name="name"
        label="Name"
        placeholder="Enter Name"
      />

      <ControlledInput
        testID="email-input"
        control={control}
        name="email"
        label="Email"
        placeholder="Enter Email"
      />
      <ControlledInput
        testID="password-input"
        control={control}
        name="password"
        label="Password"
        placeholder="Enter Password"
        secureTextEntry={true}
      />
      <Button
        testID="login-button"
        label="Create Account"
        onPress={handleSubmit(onSubmit)}
        variant="secondary"
        disabled={disable}
      />
      <Pressable
        onPress={() => navigate('Login')}
        className="flex w-[100%] flex-row justify-center p-4"
      >
        <Text>Sign In</Text>
      </Pressable>
      <View className="flex w-[100%] flex-row justify-center pt-16">
        <Pressable
          onPress={() =>
            openLinkInBrowser(
              Env.API_URL + '/auth/google?client=' + Platform.OS
            )
          }
          disabled={disable}
          className="flex-row p-2"
        >
          <Image
            source={{
              uri: 'https://developers.google.com/static/identity/images/branding_guideline_sample_nt_rd_sl.svg',
            }}
            className="w-[24px]"
          />
          <Text className="text-primary-300">Login with Google</Text>
        </Pressable>
        {/* <GoogleSigninButton
          size={GoogleSigninButton.Size.Icon}
          color={GoogleSigninButton.Color.Dark}
          onPress={() => openLinkInBrowser(Env.API_URL + '/auth/google')}
          disabled={disable}
        /> */}
      </View>
    </View>
  );
};
