import { useColorScheme } from 'nativewind';
import React from 'react';

import { openLinkInBrowser } from '@/core';
// import type { Post } from '@/api';
import { colors, Pressable, Text, View } from '@/ui';
// const { width } = Dimensions.get('window');
import { Location } from '@/ui/icons';
const maxLength = 1000;

//@ts-ignore
const formatTime = (timeString) => {
  const date = new Date(timeString);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};

//@ts-ignore
const TimeRangeDisplay = ({ start, end }) => {
  const formattedStartTime = formatTime(start);
  const formattedEndTime = formatTime(end);
  return (
    <Text variant="xs" className="px-2 py-1">
      {`${formattedStartTime} ${end ? `- ${formattedEndTime}` : ''}`}
    </Text>
  );
};
// type Props = Post & { onPress?: () => void };
// @ts-ignore
export const LocationCard = (item) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const { colorScheme } = useColorScheme();
  const iconColor =
    colorScheme === 'dark' ? colors.neutral[400] : colors.neutral[500];
  // const [currentIndex, setCurrentIndex] = React.useState(0);

  //@ts-ignore
  // const handleScroll = (event) => {
  //   const offset = event.nativeEvent.contentOffset.x;
  //   const index = Math.round(offset / width);
  //   setCurrentIndex(index);
  // };

  const toggle = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Pressable
      className={`relative m-2 block overflow-hidden  rounded-md border-[1px] border-solid  bg-themeblue-200 dark:bg-themeblue-500 ${
        item?.typeLocation === 'food'
          ? 'divide-teal-700 border-teal-700'
          : 'divide-[cornflowerblue] border-[cornflowerblue]'
      } divide-y-[1px] p-2 shadow-xl`}
      // onPress={onPress}
      onPress={toggle}
    >
      <View className="flex flex-row items-start justify-between">
        <Text variant="md" className="w-[60%]">
          {item?.locationName}
        </Text>
        <TimeRangeDisplay start={item?.time?.start} end={item?.time?.end} />
      </View>

      <View>
        <View>
          <Text variant="xs" numberOfLines={showDetails ? undefined : 2}>
            {showDetails
              ? item?.suggestions?.join(', ')
              : `${item?.suggestions?.join(', ')?.slice(0, maxLength)}...`}
          </Text>
          {/* <TouchableOpacity onPress={toggleShowFullText}> */}
          <Text variant="xs">{showDetails ? 'Read less' : 'Read more'}</Text>
          {/* </TouchableOpacity> */}
        </View>

        <Text variant="xs">Address: {item?.locationAddress}</Text>
        <View className="flex flex-row items-center justify-between pt-4">
          <Pressable
            className="flex-row items-center rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-300 px-2 dark:bg-slate-700"
            onPress={() =>
              openLinkInBrowser(
                `https://www.google.com/maps?q=${item?.coordinates?.latitude},${item?.coordinates?.longitude}`
              )
            }
          >
            <Location color={iconColor} />
            <View className="flex flex-col">
              <Text variant="sm" className="pl-2">
                Open with
              </Text>
              <Text variant="sm" className="pl-2">
                Google Maps
              </Text>
            </View>
          </Pressable>
          <View className="flex w-[33%] flex-row-reverse justify-center rounded-md bg-themeblue-400 p-2">
            <Text className="text-white">
              {item?.cost?.min} {item?.cost?.max ? ' - ' + item?.cost?.max : ''}
              {' INR'}
              {/* {item.price.currency} */}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

// });
