import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { TripInfo } from './types';

type Variables = { id: string };
type Response = TripInfo;

export const useTripInfo = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'about',
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    return clientWithAuthHeader
      .get(`trip/${variables.id}/${primaryKey}`)
      .then((response) => response.data);
  },
});
