import React from 'react';
import { Dimensions, StyleSheet } from 'react-native';

// import type { Post } from '@/api';
import {
  Button,
  colors,
  Copy,
  Image,
  Pressable,
  Rate,
  ScrollView,
  Text,
  View,
} from '@/ui';
const { width } = Dimensions.get('window');
import { useColorScheme } from 'nativewind';

// type Props = Post & { onPress?: () => void };
// @ts-ignore
export const Card = ({
  // @ts-ignore
  id,
  // @ts-ignore
  bookingType,
  // @ts-ignore
  members,
  // @ts-ignore
  name,
  // @ts-ignore
  onPress = () => {},
  // @ts-ignore
  photos,
  // @ts-ignore
  itineraryCounts,
  // @ts-ignore
  ratings,
  // @ts-ignore
  clones,
  // @ts-ignore
  dates,
  // cost,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const { colorScheme } = useColorScheme();
  const iconColor = colorScheme === 'dark' ? colors.white : colors.white;

  //@ts-ignore
  const handleScroll = (event) => {
    const offset = event.nativeEvent.contentOffset.x;
    const index = Math.round(offset / width);
    setCurrentIndex(index);
  };
  return (
    <Pressable
      className=" m-2 flex flex-row overflow-hidden  rounded-xl border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 shadow-xl dark:bg-themeblue-500"
      onPress={onPress}
    >
      {/* <Pressable
      className="m-2 block overflow-hidden rounded-xl  bg-neutral-200 p-2 shadow-xl dark:bg-charcoal-900"
      onPress={onPress}
    > */}
      {/* <TouchableWithoutFeedback onPress={onPress}> */}
      <View className="w-[50%] flex-col justify-between p-2">
        <View>
          <Text variant="md">Members: {members?.min}</Text>
          <View className="py-4">
            <Text>Itinerary </Text>
            <Text variant="xs" numberOfLines={3}>
              {itineraryCounts?.hotel} Places
            </Text>
            <Text variant="xs" numberOfLines={3}>
              {itineraryCounts?.visit} Activities
            </Text>
            <Text variant="xs" numberOfLines={3}>
              {itineraryCounts?.food} Street Food / Restaurants
            </Text>
          </View>
          <View className="py-4">
            <Text>Cloning ID: </Text>
            <Text variant="xs">{id}</Text>
          </View>
        </View>
        <View>
          <Text>Cloning Mode: </Text>
          <Button
            variant="secondary"
            label={bookingType === 'manual' ? 'Manual' : 'AutoBook'}
            onPress={onPress}
          />
        </View>
      </View>

      <ScrollView
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        scrollEventThrottle={200}
        className="relative ml-2 rounded-xl"
      >
        {/* @ts-ignore*/}
        {photos.map((image, index) => (
          <Pressable onPress={onPress}>
            <Image
              key={index}
              source={{ uri: image }}
              style={styles.image}
              contentFit="cover"
            />
          </Pressable>
        ))}
      </ScrollView>
      <View className="absolute top-0 right-0 flex h-[15%] w-[48%] flex-row justify-center rounded-t-xl bg-black px-4 opacity-50" />
      <View className="absolute top-0 right-0 flex h-[15%] w-[48%] flex-row items-center justify-between rounded-t-xl  px-4">
        <Text
          variant="md"
          className="text-white"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.title}
        >
          {name}
        </Text>
        {/* <View className="flex-row items-center"> */}
        {/* <Copy color={iconColor} />
                <Text className="p-2">{clones} Clones</Text> */}

        {/* </View> */}
      </View>
      <View className="absolute bottom-0 right-0 flex h-[25%] w-[48%] justify-center rounded-b-xl bg-black opacity-50" />
      <View className="absolute bottom-0 right-0 flex h-[25%] w-[48%] flex-row justify-between rounded-b-xl p-2">
        {/* <Text variant="md" className="text-white" style={styles.title}>
                {name}
              </Text>  */}
        <View className="flex-col py-1 px-2">
          <View className="flex-row items-center">
            <Rate color={iconColor} />
            <Text variant="xs" className="text-white">
              {' '}
              {ratings}
            </Text>
          </View>
          <View className="flex-row items-center">
            <Copy color={iconColor} />
            <Text variant="xs" className="text-white">
              {' '}
              {clones}
            </Text>
          </View>
        </View>
        <View>
          <View className="flex items-center justify-center rounded-xl bg-slate-800 py-1 px-2">
            <Text variant="xs" numberOfLines={3} style={styles.title}>
              {new Date(dates?.start).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </Text>
            {dates.end && (
              <Text variant="xs" numberOfLines={3} style={styles.title}>
                {new Date(dates?.end).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
              </Text>
            )}
          </View>
        </View>
      </View>
      <View className="absolute bottom-0 right-0 w-[48%] flex-row justify-center p-2">
        {/* @ts-ignore */}
        {photos?.map((_image, activeIndex) => (
          <View
            className={`m-[1px] rounded-full ${
              activeIndex === currentIndex ? 'bg-themeblue-400' : 'bg-white'
            } p-[2px]`}
          />
        ))}
      </View>
      {/* </TouchableWithoutFeedback> */}

      {/* <Image
        className="h-56 w-full object-cover "
        source={{
          uri: 'https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        }}
      /> */}
      {/* <Text
        variant="lg"
        numberOfLines={1}
        className="absolute top-4 left-4 font-bold"
        style={styles.title}
      >
        {name}
      </Text>
      <Pressable onPress={onPress}>
        {currentIndex === 0 && (
          <View className="absolute bottom-16 left-4">
            <Text variant="md" numberOfLines={3} style={styles.title}>
              {members?.min} Persons
            </Text>
            <Text variant="md" numberOfLines={3} style={styles.title}>
              {Math.max(
                1,
                Math.ceil(
                  (new Date(dates?.end).getTime() -
                    new Date(dates?.start).getTime()) /
                    (1000 * 60 * 60 * 24)
                )
              )}
              N{' '}
              {Math.max(
                1,
                Math.ceil(
                  (new Date(dates?.end).getTime() -
                    new Date(dates?.start).getTime()) /
                    (1000 * 60 * 60 * 24)
                )
              ) + 1}
              D
            </Text>
          </View>
        )}
        {currentIndex === 1 && (
          <View className="absolute bottom-16 left-4">
            <Text variant="md" numberOfLines={3} style={styles.title}>
              {itineraryCounts?.hotel} Places
            </Text>
            <Text variant="md" numberOfLines={3} style={styles.title}>
              {itineraryCounts?.visit} Activities
            </Text>
            <Text variant="md" numberOfLines={3} style={styles.title}>
              {itineraryCounts?.food} Street Food / Restaurants
            </Text>
          </View>
        )}

        <View className="flex flex-row items-center justify-between  border-black p-2">
          <View className="flex-row">
            <Rate color={iconColor} />
            <Text className="pl-2"> {ratings} Ratings</Text>
          </View>
          <View className="flex-row">
            <Copy color={iconColor} />
            <Text className="pl-2">{clones} Clones</Text>
          </View>
        </View>
      </Pressable>
      <View className=" absolute top-4 right-4 flex items-center justify-center rounded-xl bg-slate-800 py-1 px-2">
        <Text variant="md" numberOfLines={3} style={styles.title}>
          {new Date(dates?.start).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}
        </Text>
        {dates.end && (
          <Text variant="md" numberOfLines={3} style={styles.title}>
            {new Date(dates?.end).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
          </Text>
        )}
      </View> */}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  image: {
    width: width / 2 - 15,
    height: 320, // Set your desired height
    // resizeMode: 'cover',
    // borderRadius: 24,
  },
  title: {
    // fontSize: 24,
    fontWeight: 'bold',
    color: 'white', // Text color
    // textShadowColor: 'black', // Outline color
    // textShadowOffset: { width: 0, height: 0 }, // Outline offset
    // textShadowRadius: 8, // Outline blur radius
  },

  // }
});
