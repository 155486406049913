import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { Button } from 'react-native-elements';
import { TextInput } from 'react-native-gesture-handler';

import { useCheckMailOTP, useSendMailOTP } from '@/api';
import { setToken } from '@/core/auth/utils';
import { Text, View } from '@/ui';

import { Item } from './item';

type Props = {
  decodedToken: any;
  setDecodedToken: any;
};

export const EmailItem = ({ decodedToken, setDecodedToken }: Props) => {
  const [emailSent, setEmailSent] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const [isVerifying, setIsVerifying] = React.useState(false);

  const { refetch: sendRefetch } = useSendMailOTP({
    variables: {},
  });

  const { mutate: checkMailOTP } = useCheckMailOTP();

  const handleSendMail = async () => {
    try {
      if (!emailSent) {
        await sendRefetch();
        setEmailSent(true);
        alert('Email Sent! OTP valid for 5 min...');
      }
    } catch (error) {
      //@ts-ignore
      console.error('Error sending OTP: ', error.message);
    }
  };

  const handleVerifyOTP = async () => {
    setIsVerifying(true);
    checkMailOTP(
      { email: decodedToken.email, otp },
      {
        onSuccess: (data) => {
          if (data.status === 'success') {
            setToken({ access: data.jwtToken, refresh: 'refresh-token' });
            try {
              setDecodedToken(jwtDecode<JwtPayload>(data.jwtToken));
            } catch (error) {
              console.error('Error setting decodedToken...', error);
            }
            alert('Email verified successfully!');
          } else {
            alert('Invalid OTP. Please try again...');
          }
        },
        onError: (error) => {
          console.error('Error verifying OTP: ', error);
          alert('Invalid OTP. Please try again...');
        },
        onSettled: () => {
          setIsVerifying(false);
        },
      }
    );
  };

  return (
    <>
      <Item
        text="settings.profileEmail"
        value={decodedToken?.email}
        icon={
          !decodedToken?.verificationStatus?.email && (
            <Text
              variant="md"
              className="text-neutral-600 dark:text-white"
              onPress={handleSendMail}
            >
              Verify
            </Text>
          )
        }
      />

      {emailSent && !decodedToken?.verificationStatus?.email && (
        <View className="flex-1 flex-row items-center justify-between px-4 py-2">
          <View className="flex-row items-center">
            <TextInput
              placeholder="Enter OTP"
              value={otp}
              onChangeText={setOtp}
              keyboardType="numeric"
              style={{
                margin: 5,
                padding: 5,
                color: '#fff',
                backgroundColor: '#525270',
                borderRadius: 2,
              }}
            />
            <Button
              title="Verify"
              onPress={handleVerifyOTP}
              disabled={isVerifying}
            />
          </View>
        </View>
      )}
    </>
  );
};
