import * as React from 'react';
import type { SvgProps } from 'react-native-svg';
import Svg, { Path } from 'react-native-svg';

import { colors } from '../theme';

export const Bookmark = ({
  color = colors.neutral[500],
  ...props
}: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M17 2H7c-1.1 0-1.99.9-1.99 2L5 20l7-3 7 3V4c0-1.1-.9-2-2-2zm-5 14l-5-2.18V4h10v9.82L12 16z ${
        //@ts-ignore
        props.saved ? 'M12 16l5-2.18V4H7v9.82L12 16z' : ''
      }`}
      fill={color}
    />
  </Svg>
);
