import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { TripClone } from './types';

type Variables = {
  // phoneNumber: string;
  // member: number;
  // date: string;
  // bookingType: string;
  id: string;
};
type Response = TripClone;

export const useTripSave = createMutation<Response, Variables, AxiosError>({
  mutationFn: async (variables) =>
    clientWithAuthHeader({
      url: `trip/${variables.id}/save`,
      method: 'POST',
      // data: variables,
    }).then((response) => response.data),
});
