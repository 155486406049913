import 'react-native-gesture-handler';
import './index.css';

import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { decode, encode } from 'base-64';
import * as SplashScreen from 'expo-splash-screen'; //@ts-ignore
// import mixpanel from 'mixpanel-browser';
// import posthog from 'posthog-js';
import React from 'react';
import { StyleSheet } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { APIProvider } from '@/api';
import { hydrateAuth, loadSelectedTheme } from '@/core';
import { RootNavigator } from '@/navigation';

// mixpanel.init('9cbe328884725aee18ee4c62d9f72c00', {
//   debug: true,
//   track_pageview: true,
//   persistence: 'localStorage',
// });

// // Set this to a unique identifier for the user performing the event.
// mixpanel.identify('USER_ID');

// // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
// mixpanel.track('Sign Up', {
//   'Signup Type': 'Referral',
// });

if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

hydrateAuth();
loadSelectedTheme();
SplashScreen.preventAutoHideAsync();

const App = () => {
  // posthog.init('phc_xuIfUTfkOWpOtAM7Zm4FE6Sqd7Ds7jOb6BntEaeEsAb', {
  //   api_host: 'https://app.posthog.com',
  // });
  // posthog.capture('my event', { property: 'value' });

  return (
    <GestureHandlerRootView style={styles.container}>
      <BottomSheetModalProvider>
        <APIProvider>
          <RootNavigator />
          <FlashMessage position="top" />
        </APIProvider>
      </BottomSheetModalProvider>
    </GestureHandlerRootView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default App;
