// import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useColorScheme } from 'nativewind';
import * as React from 'react';
import { Share } from 'react-native';

import { Home } from '@/screens';
import { colors, Pressable, Text } from '@/ui';

import { TripDetailNavigator } from './trip-detail-navigator';

export type HomeStackParamList = {
  // Feed: undefined;
  // Post: { id: number };
  // AddPost: undefined;
  TripDetail: { id: string };
  Home: undefined;
};

const Stack = createNativeStackNavigator<HomeStackParamList>();

//@ts-ignore
const GoToAddPost = ({ route }) => {
  // const { navigate } = useNavigation();
  const postLink = `https://app.clonemytrips.com/trip/${route?.params?.id}`;

  const handleShare = async () => {
    try {
      const result = await Share.share({
        message: `Check out this trip: ${postLink}`,
        //
      });

      if (result.action === Share.sharedAction) {
        console.log('Post shared successfully');
      } else if (result.action === Share.dismissedAction) {
        console.log('Share cancelled');
      }
    } catch (error) {
      //@ts-ignore
      console.error('Error sharing post:', error.message);
    }
  };
  return (
    <Pressable onPress={handleShare} className="p-2">
      <Text className="text-themeblue-500">Share</Text>
    </Pressable>
  );
};

type Props = {
  navigation: any;
  route: any;
};

export const HomeNavigator = ({ navigation, route }: Props) => {
  const { colorScheme } = useColorScheme();
  React.useLayoutEffect(() => {
    // const tabHiddenRoutes = ['ChatNavigator'];
    // if (tabHiddenRoutes.includes(getFocusedRouteNameFromRoute(route))) {
    //   navigation.setOptions({ tabBarStyle: { display: 'none' } });
    // } else {
    //   navigation.setOptions({
    //     tabBarStyle: [
    //       // { display: 'none' },
    //       {
    //         backgroundColor: 'transparent',
    //         position: 'absolute',
    //         elevation: 0,
    //         bottom: 5,
    //       },
    //       { borderTopWidth: 0, borderTopColor: colors.neutral[200] },
    //     ],
    //     tabBarBackground: () => (
    //       // <BlurView
    //       //   tint="light"
    //       //   intensity={Platform.OS === 'ios' ? 40 : 100}
    //       //   style={{
    //       //     position: 'absolute',
    //       //     top: -5,
    //       //     left: 0,
    //       //     bottom: -5,
    //       //     right: 0,
    //       //   }}
    //       // />
    //     ),
    //   });
    // }
  }, [navigation, route]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle:
          colorScheme === 'dark'
            ? { backgroundColor: colors.themeblue[500] }
            : { backgroundColor: colors.white },
      }}
    >
      <Stack.Screen
        name="Home"
        options={{ headerLeft: () => null }}
        component={Home}
      />
      <Stack.Group
        screenOptions={({ route }) => ({
          headerRight: () => <GoToAddPost route={route} />, // Pass route prop
        })}
      >
        <Stack.Screen name="TripDetail" component={TripDetailNavigator} />
        {/* <Stack.Screen name="HomeScreen" component={Home} /> */}
        {/* <Stack.Screen name="Post" component={Post} /> */}
      </Stack.Group>
    </Stack.Navigator>
  );
};
