import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { TripCommonDetail } from './types';

type Variables = { id: string };
type Response = TripCommonDetail;

export const useTripCommonDetail = createQuery<Response, Variables, AxiosError>(
  {
    primaryKey: 'tripDetail',
    // eslint-disable-next-line unused-imports/no-unused-vars
    queryFn: ({ queryKey: [primaryKey, variables] }) => {
      return clientWithAuthHeader
        .post(`trip/${variables.id}`)
        .then((response) => response.data);
    },
  }
);
