import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { MyTripInfo } from './types';

type Variables = { id: string };
type Response = MyTripInfo;

export const useMyTripInfo = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'about',
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    return clientWithAuthHeader
      .get(`myTrip/${variables.id}/${primaryKey}`)
      .then((response) => response?.data?.response);
  },
});
