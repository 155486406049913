import * as Location from 'expo-location';
import { Linking } from 'react-native';
import type { StoreApi, UseBoundStore } from 'zustand';

import { showErrorMessage } from '@/ui';

export function openLinkInBrowser(url: string) {
  Linking.canOpenURL(url).then(() => Linking.openURL(url));
}

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

export async function getCurrentLocation() {
  try {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      showErrorMessage('Permission to access location was denied');
      return;
    }

    const location = await Location.getCurrentPositionAsync({});
    // location = {
    //   coords: {
    //     accuracy: 600,
    //     altitude: 0,
    //     altitudeAccuracy: 0,
    //     heading: 0,
    //     latitude: 37.4220936,
    //     longitude: -122.083922,
    //     speed: 0,
    //   },
    //   mocked: false,
    //   timestamp: 1708718815095,
    // };

    return location;
  } catch (error) {
    console.error(error);
  }
}

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  let store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (let k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};
