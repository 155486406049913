import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { type RouteProp, useNavigation } from '@react-navigation/native';
import {
  createNativeStackNavigator,
  type NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import * as Location from 'expo-location';
import { useColorScheme } from 'nativewind';
import type { ComponentType } from 'react';
import * as React from 'react';
import { Share } from 'react-native';
import type { SvgProps } from 'react-native-svg';

import { UserProfile } from '@/screens';
import {
  colors,
  Feed as FeedIcon,
  Home as HomeIcon,
  Pressable,
  Settings as SettingsIcon,
  Style as StyleIcon,
  Text,
  View,
} from '@/ui';

// import { FeedNavigator } from './feed-navigator';
import { HomeNavigator } from './home-navigator';
import { MyTripsNavigator } from './my-trips-navigator';
import { NearbyPageNavigator } from './nearby-page-navigator';
import { SettingsNavigator } from './settings-navigator';

export type TabParamList = {
  // Style: undefined;
  Profile: { id: string };
  HomeNavigator: undefined;
  // FeedNavigator: undefined;
  NearbyPageNavigator: undefined;
  MyTripsNavigator: undefined;
  SettingsNavigator: undefined;
};

type TabType = {
  name: keyof TabParamList;
  component: ComponentType<any>;
  label: string;
};

type TabIconsType = {
  [key in keyof TabParamList]: (props: SvgProps) => JSX.Element;
};

const Tab = createBottomTabNavigator<TabParamList>();

const AppStackNavigator = createNativeStackNavigator();

//@ts-ignore
const GoToAddPost = ({ route }) => {
  // const { navigate } = useNavigation();
  const postLink = `https://app.clonemytrips.com/profile/${route?.params?.id}`;

  const handleShare = async () => {
    try {
      const result = await Share.share({
        message: `Check out this trip: ${postLink}`,
        //
      });

      if (result.action === Share.sharedAction) {
        console.log('Post shared successfully');
      } else if (result.action === Share.dismissedAction) {
        console.log('Share cancelled');
      }
    } catch (error) {
      //@ts-ignore
      console.error('Error sharing post:', error.message);
    }
  };
  return (
    <Pressable onPress={handleShare} className="p-2">
      <Text className="text-themeblue-500">Share</Text>
    </Pressable>
  );
};

const GoToHome = () => {
  const { colorScheme } = useColorScheme();
  const { navigate } = useNavigation();
  return (
    <Pressable
      onPress={() => {
        //@ts-ignore
        navigate('TabNavigator');
      }}
      className="p-2"
    >
      <BarIcon
        name={'HomeNavigator'}
        color={colorScheme === 'dark' ? colors.white : colors.neutral[400]}
      />
    </Pressable>
  );
};

export const AppStackNavigatorScreen = () => {
  const { colorScheme } = useColorScheme();
  return (
    <AppStackNavigator.Navigator>
      <AppStackNavigator.Screen
        name="TabNavigator"
        component={TabNavigator}
        options={{
          headerShown: false,
        }}
      />
      <AppStackNavigator.Group
        screenOptions={({ route }) => ({
          headerRight: () => (
            <View className="flex flex-row">
              <GoToHome />
              <GoToAddPost route={route} />
            </View>
          ), // Pass route prop
        })}
      >
        <AppStackNavigator.Screen
          name="Profile"
          component={UserProfile}
          options={{
            headerStyle:
              colorScheme === 'dark'
                ? { backgroundColor: colors.themeblue[500] }
                : { backgroundColor: colors.white },
          }}
        />
      </AppStackNavigator.Group>
    </AppStackNavigator.Navigator>
  );
};

const tabsIcons: TabIconsType = {
  HomeNavigator: (props: SvgProps) => <HomeIcon {...props} />,
  // Style: (props: SvgProps) => <StyleIcon {...props} />,
  // FeedNavigator: (props: SvgProps) => <FeedIcon {...props} />,
  NearbyPageNavigator: (props: SvgProps) => <StyleIcon {...props} />,
  MyTripsNavigator: (props: SvgProps) => <FeedIcon {...props} />,
  SettingsNavigator: (props: SvgProps) => <SettingsIcon {...props} />,
  Profile: () => <SettingsIcon />,
};

export type TabList<T extends keyof TabParamList> = {
  navigation: NativeStackNavigationProp<TabParamList, T>;
  route: RouteProp<TabParamList, T>;
};

const tabs: TabType[] = [
  {
    name: 'HomeNavigator',
    component: HomeNavigator,
    label: 'Home',
  },
  {
    name: 'NearbyPageNavigator',
    component: NearbyPageNavigator,
    label: 'Nearby',
  },
  {
    name: 'MyTripsNavigator',
    component: MyTripsNavigator,
    label: 'MyTrips',
  },
  {
    name: 'SettingsNavigator',
    component: SettingsNavigator,
    label: 'Settings',
  },
];

type BarIconType = {
  name: keyof TabParamList;
  color: string;
};

const BarIcon = ({ color, name, ...reset }: BarIconType) => {
  const Icon = tabsIcons[name];
  return <Icon color={color} {...reset} />;
};

export const TabNavigator = () => {
  const { colorScheme } = useColorScheme();
  React.useEffect(() => {
    async function getLocationPermission() {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        alert('Permission to access location was denied');
        return;
      }
    }
    getLocationPermission();
  }, []);
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarInactiveTintColor:
          colorScheme === 'dark' ? colors.white : colors.neutral[400],
        // eslint-disable-next-line react/no-unstable-nested-components
        tabBarIcon: ({ color }) => <BarIcon name={route.name} color={color} />,
        tabBarActiveTintColor: colors.themeblue[400],
        tabBarStyle:
          colorScheme === 'dark'
            ? { backgroundColor: colors.themeblue[500] }
            : { backgroundColor: colors.white },
      })}
    >
      <Tab.Group
        screenOptions={{
          headerShown: false,
        }}
      >
        {tabs.map(({ name, component, label }) => {
          return (
            <Tab.Screen
              key={name}
              name={name}
              component={component}
              options={{
                title: label,
                tabBarTestID: `${name}-tab`,
              }}
            />
          );
        })}
      </Tab.Group>
    </Tab.Navigator>
  );
};
