import { NavigationContainer as RNNavigationContainer } from '@react-navigation/native';
import * as React from 'react';
import { Linking } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { getToken } from '@/core/auth/utils';
import { setItem } from '@/core/storage';

import { useThemeConfig } from './use-theme-config';

export const NavigationContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const linking = {
    prefixes: ['https://clonemytrips.com', 'clonemytrips://'],
    config: {
      screens: {
        Auth: {
          screens: {
            Login: 'login/:token?',
            Register: 'register',
          },
        },
        App: {
          screens: {
            TabNavigator: {
              screens: {
                HomeNavigator: {
                  screens: {
                    TripDetail: {
                      path: 'trip/:id',
                      screens: {
                        Itinerary: 'itinerary',
                        About: 'about',
                        Stays: 'stays',
                        Checklist: 'checklist',
                        Cost: 'cost',
                      },
                    },
                    Home: 'home',
                  },
                },
                MyTripsNavigator: {
                  screens: {
                    MyTripDetails: {
                      path: 'myTrip/:id',
                      screens: {
                        Itinerary: 'itinerary',
                        About: 'about',
                        Stays: 'stays',
                        Checklist: 'checklist',
                        Cost: 'cost',
                      },
                    },
                    MyTrips: 'myTrips',
                  },
                },
                NearbyPageNavigator: {
                  screens: {
                    Nearby: 'nearby',
                  },
                },
                SettingsNavigator: {
                  screens: {
                    Settings: 'settings',
                  },
                },
              },
            },
            Profile: {
              // screens: {
              //   Profile: {
              path: 'profile/:id',
              //   },
              // },
            },
          },
        },
      },
    },
  };

  React.useEffect(() => {
    console.log('AAAA');
  }, []);

  Linking.getInitialURL().then((initialUrl) => {
    const jwtToken = getToken();
    const tripIndex = initialUrl?.indexOf('trip/');
    if (tripIndex && tripIndex !== -1 && initialUrl) {
      const idPart = initialUrl?.substring(tripIndex + 5); // +5 to skip "trip/"
      if (idPart && !jwtToken) {
        setItem('TRIP_URL', idPart);
      }
    }
  });

  const theme = useThemeConfig();
  return (
    <SafeAreaProvider>
      {/* @ts-ignore */}
      <RNNavigationContainer linking={linking} theme={theme}>
        {children}
      </RNNavigationContainer>
    </SafeAreaProvider>
  );
};
