import Slider from '@react-native-community/slider';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native'; //@ts-ignore

import { useSearchLocation } from '@/api';
import { getCurrentLocation } from '@/core';
import {
  ActivityIndicator,
  colors,
  EmptyList,
  ScrollView,
  Text,
  View,
} from '@/ui';

import { LocationCard } from './location-card';

// const tempData = [
//   {
//     title: 'Stanza Living Manisa House',
//     id: 'here:pds:place:356tek9g-b377817f1f36b5680bb869ee0aabb625',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Stanza Living Manisa House, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59239,
//       lng: 73.99727,
//     },
//     access: [
//       {
//         lat: 18.59239,
//         lng: 73.99713,
//       },
//     ],
//     distance: 280,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         www: [
//           {
//             value:
//               'https://www.stanzaliving.com/pune/pg-in-wagholi/female/manisa-house',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Samrudhi Lodging',
//     id: 'here:pds:place:356tek9g-d00661f8e66d6aa678d2e835e7425c65',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Samrudhi Lodging, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59078,
//       lng: 73.99776,
//     },
//     access: [
//       {
//         lat: 18.59078,
//         lng: 73.99778,
//       },
//     ],
//     distance: 282,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224596159',
//       },
//     ],
//   },
//   {
//     title: 'Samruddhi Lodge',
//     id: 'here:pds:place:356tek9g-70680f230f62d8c5be230688ff18368f',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Samruddhi Lodge, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59032,
//       lng: 73.99775,
//     },
//     access: [
//       {
//         lat: 18.59028,
//         lng: 73.99769,
//       },
//     ],
//     distance: 289,
//     categories: [
//       {
//         id: '500-5100-0057',
//         name: 'Guest House',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//     ],
//   },
//   {
//     title: 'Hotel Yashraj Inn',
//     id: 'here:pds:place:356tek9g-2bd4f931b9679bfe83f1922b3b9a45ed',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Yashraj Inn, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58914,
//       lng: 73.99723,
//     },
//     access: [
//       {
//         lat: 18.58922,
//         lng: 73.99712,
//       },
//     ],
//     distance: 302,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '500-5000-0054',
//         name: 'Motel',
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166368746',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166368747',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224253375',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224253395',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-077',
//         name: 'Vegetarian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912060701618',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//               {
//                 id: '500-5000-0054',
//               },
//             ],
//           },
//           {
//             value: '+912060780956',
//           },
//         ],
//         www: [
//           {
//             value:
//               'http://www.oyorooms.com/40354?utm_source=tripadvisor&utm_medium=listing&utm_campaign=PUN398',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//               {
//                 id: '500-5000-0054',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/h/40354?utm_source=Bing_bp&utm_medium=listing&utm_campaign=PUN398',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     payment: {
//       methods: [
//         {
//           id: 'mastercard',
//           accepted: true,
//         },
//       ],
//     },
//   },
//   {
//     title: 'Social Hostel',
//     id: 'here:pds:place:356jx7ps-366201df270f0aca046a66980308862d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Social Hostel, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58692,
//       lng: 73.99463,
//     },
//     access: [
//       {
//         lat: 18.58681,
//         lng: 73.99474,
//       },
//     ],
//     distance: 451,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569483',
//       },
//     ],
//   },
//   {
//     title: 'Madtra Deluxe-Viman Nagar',
//     id: 'here:pds:place:356tek9e-49106a12313c68a4250184bc446d21e7',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Madtra Deluxe-Viman Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58663,
//       lng: 73.99393,
//     },
//     access: [
//       {
//         lat: 18.58604,
//         lng: 73.99358,
//       },
//     ],
//     distance: 496,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224634413',
//       },
//     ],
//   },
//   {
//     title: 'Oyo-Vithalwadi',
//     id: 'here:pds:place:356tek9e-d2b61b1c37c1137eb3fb9d75cb825174',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Oyo-Vithalwadi, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58597,
//       lng: 73.99358,
//     },
//     access: [
//       {
//         lat: 18.58587,
//         lng: 73.99368,
//       },
//     ],
//     distance: 576,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224631380',
//       },
//     ],
//   },
//   {
//     title: 'Fab Hotel Mantra Deluxe',
//     id: 'here:pds:place:356tek9e-57b8cf08651f4e7b2d48fff6299977e0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Fab Hotel Mantra Deluxe, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58595,
//       lng: 73.99346,
//     },
//     access: [
//       {
//         lat: 18.58598,
//         lng: 73.9936,
//       },
//     ],
//     distance: 582,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [],
//     contacts: [
//       {
//         www: [
//           {
//             value:
//               'https://www.fabhotels.com/hotels-in-pune/fabhotel-mantra-deluxe.html',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/h/73925?utm_source=Bing_bp&utm_medium=listing&utm_campaign=PUN772',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Satav Boys Hostel',
//     id: 'here:pds:place:356jx7ps-8558f366b7d9058ed03e0b45c7cd8708',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Satav Boys Hostel, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5906,
//       lng: 74.00139,
//     },
//     access: [
//       {
//         lat: 18.59047,
//         lng: 74.00142,
//       },
//     ],
//     distance: 665,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176567468',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919011851868',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919011851868',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Dattakripa Girls Hostel',
//     id: 'here:pds:place:356jx7ps-a3d2243e191f07386ce48dd43b9d4d36',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Dattakripa Girls Hostel, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59037,
//       lng: 74.00161,
//     },
//     access: [
//       {
//         lat: 18.59051,
//         lng: 74.00154,
//       },
//     ],
//     distance: 690,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333560',
//       },
//     ],
//   },
//   {
//     title: 'Sangeeta Girls Hostel',
//     id: 'here:pds:place:356jx7ps-9e832b1d7b79068cc4fb8978c68f909b',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Sangeeta Girls Hostel, Haveli Rural Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5908,
//       lng: 74.00186,
//     },
//     access: [
//       {
//         lat: 18.59065,
//         lng: 74.00192,
//       },
//     ],
//     distance: 714,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174691947',
//       },
//     ],
//   },
//   {
//     title: 'Vedant Boys Hostel',
//     id: 'here:pds:place:356jx7ps-2a6228561ec60f0f50777324cd043cd0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Vedant Boys Hostel, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59116,
//       lng: 74.00207,
//     },
//     access: [
//       {
//         lat: 18.59112,
//         lng: 74.00194,
//       },
//     ],
//     distance: 736,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176564567',
//       },
//     ],
//   },
//   {
//     title: 'Bhausaheb Boys Hostel',
//     id: 'here:pds:place:356jx7ps-ced5d7cef2bf0d631c47bce2d57ef532',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Bhausaheb Boys Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58545,
//       lng: 73.991,
//     },
//     access: [
//       {
//         lat: 18.58549,
//         lng: 73.99114,
//       },
//     ],
//     distance: 748,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176564279',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919881616944',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919881616944',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Anand Gharkul Boys Hostel',
//     id: 'here:pds:place:356jx7ps-bd6acc28e7f2041954683d962dba0742',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Anand Gharkul Boys Hostel, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5897,
//       lng: 74.00234,
//     },
//     access: [
//       {
//         lat: 18.58972,
//         lng: 74.00248,
//       },
//     ],
//     distance: 777,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570954',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919823167171',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919011556161',
//           },
//           {
//             value: '+919823167171',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Oyo Life Pun536 Wagholi',
//     id: 'here:pds:place:356tek9e-bd9702ce027bce7c710ce8d76196b1a1',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Oyo Life Pun536 Wagholi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58612,
//       lng: 73.98815,
//     },
//     access: [
//       {
//         lat: 18.58611,
//         lng: 73.98811,
//       },
//     ],
//     distance: 907,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911246201217',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Stanza Living Ripon House',
//     id: 'here:pds:place:356tek9g-c0dce6319e44dd83cf1dfaa1620b62b0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Stanza Living Ripon House, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59298,
//       lng: 74.00385,
//     },
//     access: [
//       {
//         lat: 18.59296,
//         lng: 74.00371,
//       },
//     ],
//     distance: 950,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         www: [
//           {
//             value:
//               'https://www.stanzaliving.com/pune/pg-in-wagholi/co_ed/ripon-house',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Amit Boys Hostel',
//     id: 'here:pds:place:356jx7ps-95779cd8265c061f9bfd46c650951e5f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Amit Boys Hostel, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59411,
//       lng: 74.00357,
//     },
//     access: [
//       {
//         lat: 18.59411,
//         lng: 74.00357,
//       },
//     ],
//     distance: 960,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176559834',
//       },
//     ],
//   },
//   {
//     title: 'Anandrang Hostel',
//     id: 'here:pds:place:356jx7ps-0848b4da39d40a8bcd5ce9aeda9294f9',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Anandrang Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58404,
//       lng: 73.98915,
//     },
//     access: [
//       {
//         lat: 18.58404,
//         lng: 73.98915,
//       },
//     ],
//     distance: 991,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176569487',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918087631012',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Santosh Beer Bar',
//     id: 'here:pds:place:356jx7ps-e2e1b3a47a3d0854322e5a2e6ecd7499',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Santosh Beer Bar, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58358,
//       lng: 73.9898,
//     },
//     access: [
//       {
//         lat: 18.58347,
//         lng: 73.98988,
//       },
//     ],
//     distance: 991,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981039',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912230770108',
//           },
//         ],
//       },
//     ],
//     payment: {
//       methods: [
//         {
//           id: 'mastercard',
//           accepted: true,
//         },
//       ],
//     },
//   },
//   {
//     title: 'Hotel Nanashree Executive',
//     id: 'here:pds:place:356tek9e-4242d2b9da49875d2f5801cf7b343acc',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Nanashree Executive, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58288,
//       lng: 73.98957,
//     },
//     access: [
//       {
//         lat: 18.58291,
//         lng: 73.98971,
//       },
//     ],
//     distance: 1069,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104855967',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981502',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174728447',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '23937117',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '800-077',
//         name: 'Vegetarian',
//         primary: true,
//       },
//       {
//         id: '202-000',
//         name: 'Indian',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+918002468357',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: '+918067466725',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: '+919011727979',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: '+919168334545',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919423242834',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: '+919822082278',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.facebook.com/331373363662599',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Gurukripa Hostel',
//     id: 'here:pds:place:356d408k-6616d712ab5c0a71530d0027af6f7bd1',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Gurukripa Hostel, Bhawdi Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Bhawdi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.584,
//       lng: 73.98783,
//     },
//     access: [
//       {
//         lat: 18.58402,
//         lng: 73.98797,
//       },
//     ],
//     distance: 1088,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917709128661',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Anand Lodge',
//     id: 'here:pds:place:356tek9e-153be83af2d14e43a644d21e625bfebb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Anand Lodge, Sant Tukaram Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sant Tukaram Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58238,
//       lng: 73.9868,
//     },
//     access: [
//       {
//         lat: 18.5825,
//         lng: 73.9868,
//       },
//     ],
//     distance: 1293,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981198',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174652173',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919766638758',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919561185507',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: '+919766638758',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Moonlight Lodging Boarding',
//     id: 'here:pds:place:356jx7ps-73ad05379060013ab8ffd08ff9a20f62',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Moonlight Lodging Boarding, Kopargaon Shirdi Ahmednagar Pune Highway, Sant Tukaram Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sant Tukaram Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58178,
//       lng: 73.98696,
//     },
//     access: [
//       {
//         lat: 18.58178,
//         lng: 73.98696,
//       },
//     ],
//     distance: 1332,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176562135',
//       },
//     ],
//   },
//   {
//     title: 'Hotel Supriya',
//     id: 'here:pds:place:356tek9e-5665402649fde98907b83a230f9961a8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Supriya, Keshnand Theur Road, Jeje Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jeje Nagar',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57921,
//       lng: 73.99208,
//     },
//     access: [
//       {
//         lat: 18.57909,
//         lng: 73.99198,
//       },
//     ],
//     distance: 1343,
//     categories: [
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174731936',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '21491521',
//       },
//     ],
//     contacts: [
//       {
//         www: [
//           {
//             value: 'https://www.oyorooms.com/104645',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/104645-spot-on-spot-on-74501-hotel-supriya-garden-pune',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '500-5000-0053',
//           },
//         ],
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Green9-Kaleodha',
//     id: 'here:pds:place:356tek9e-0f2ca25ff8cf4cdf96b745db6a218fc5',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Green9-Kaleodha, Keshnand Theur Road, Mukhai Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Mukhai Nagar',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57879,
//       lng: 73.99266,
//     },
//     access: [
//       {
//         lat: 18.57867,
//         lng: 73.99257,
//       },
//     ],
//     distance: 1376,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '600-6900-0096',
//         name: 'Specialty Store',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224232290',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224232311',
//       },
//     ],
//   },
//   {
//     title: 'Spot On 79501 Sai Lodge',
//     id: 'here:pds:place:356tek9e-592691f2a9d70744f565497cb13af733',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Spot On 79501 Sai Lodge, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57739,
//       lng: 73.99972,
//     },
//     access: [
//       {
//         lat: 18.57741,
//         lng: 73.99961,
//       },
//     ],
//     distance: 1585,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '23345507',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911246201217',
//           },
//           {
//             value: '+919313931393',
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.oyorooms.com/110969',
//           },
//           {
//             value:
//               'https://www.oyorooms.com/h/110969?utm_source=Google_gmb&utm_medium=Organic&utm_campaign=PUN1465&latitude=18.57738&longitude=73.99971&locale=en',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Kapila Resorts',
//     id: 'here:pds:place:356tek9e-3d13efa87f584e469ccbe8c2e9bb54f1',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Kapila Resorts, Awhalwadi Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Awhalwadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57953,
//       lng: 73.9853,
//     },
//     access: [
//       {
//         lat: 18.57953,
//         lng: 73.9853,
//       },
//     ],
//     distance: 1636,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919049994061',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919049994061',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.kapilaresorts.com',
//           },
//           {
//             value: 'http://www.kapilaresorts.in',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: 'https://www.facebook.com/369099726866693',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Girls Hostel',
//     id: 'here:pds:place:356jx7ps-2635adf9c652092ede696732094e7033',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Girls Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57588,
//       lng: 73.99979,
//     },
//     access: [
//       {
//         lat: 18.57583,
//         lng: 73.99993,
//       },
//     ],
//     distance: 1747,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570311',
//       },
//     ],
//   },
//   {
//     title: 'RR Boys Hostel',
//     id: 'here:pds:place:356tek9e-ecd4f3233ad617e464e00b4ac06a8d0c',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'RR Boys Hostel, Domkhel Road, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       street: 'Domkhel Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57921,
//       lng: 73.98294,
//     },
//     access: [
//       {
//         lat: 18.57921,
//         lng: 73.98293,
//       },
//     ],
//     distance: 1829,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224617073',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919865795555',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Tittos-Vitthalwadi',
//     id: 'here:pds:place:356tek9e-754f42453e5c47458e2396858a078dd0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Tittos-Vitthalwadi, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58096,
//       lng: 73.98084,
//     },
//     access: [
//       {
//         lat: 18.58081,
//         lng: 73.98083,
//       },
//     ],
//     distance: 1868,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224258457',
//       },
//     ],
//   },
//   {
//     title: 'Hotel Sai Residency',
//     id: 'here:pds:place:356jx7ps-761792b55795088f4ebbcc414d4045d8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Sai Residency, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58086,
//       lng: 73.98047,
//     },
//     access: [
//       {
//         lat: 18.58083,
//         lng: 73.98047,
//       },
//     ],
//     distance: 1906,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//       },
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104855377',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174512901',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919820374923',
//           },
//         ],
//         mobile: [
//           {
//             value: '+918149040081',
//             categories: [
//               {
//                 id: '500-5100-0058',
//               },
//             ],
//           },
//           {
//             value: '+919820374923',
//             categories: [
//               {
//                 id: '500-5100-0058',
//               },
//             ],
//           },
//           {
//             value: '+919833370269',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Mahalaxmi Boys Hostel',
//     id: 'here:pds:place:356tek9e-115d8b7f9a5064ab07378b0625b4cae5',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Mahalaxmi Boys Hostel, Anand Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Anand Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57916,
//       lng: 73.98161,
//     },
//     access: [
//       {
//         lat: 18.57918,
//         lng: 73.98149,
//       },
//     ],
//     distance: 1933,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224570722',
//       },
//     ],
//   },
//   {
//     title: 'Balgruh Wagholi',
//     id: 'here:pds:place:356jx7ps-ded2e716881c06eede0071169e4c349f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Balgruh Wagholi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59055,
//       lng: 74.01348,
//     },
//     access: [
//       {
//         lat: 18.59053,
//         lng: 74.01334,
//       },
//     ],
//     distance: 1939,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176561308',
//       },
//     ],
//   },
//   {
//     title: 'A-One Hotel',
//     id: 'here:pds:place:356jx7ps-ee8823f4e92e0c0f69079fff9f605185',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'A-One Hotel, SH-60, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58096,
//       lng: 73.97965,
//     },
//     access: [
//       {
//         lat: 18.58081,
//         lng: 73.97965,
//       },
//     ],
//     distance: 1970,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1148029683',
//       },
//     ],
//   },
//   {
//     title: 'Yashodam Boys Hostel',
//     id: 'here:pds:place:356jx7ps-236b405d0aef0c83a8b73e9a686a75db',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Yashodam Boys Hostel, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57693,
//       lng: 73.98283,
//     },
//     access: [
//       {
//         lat: 18.57691,
//         lng: 73.98299,
//       },
//     ],
//     distance: 2025,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174596013',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919921124242',
//           },
//           {
//             value: '+919970042711',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Nanashree Executive',
//     id: 'here:pds:place:356tek9e-32463e84d8b2974d983e56a85e5fe332',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Nanashree Executive, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58082,
//       lng: 73.97864,
//     },
//     access: [
//       {
//         lat: 18.58093,
//         lng: 73.97852,
//       },
//     ],
//     distance: 2068,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Aarohi Girls Hostel',
//     id: 'here:pds:place:356tek9e-ea3b597796fb79be4eed9a8c85bd7112',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Aarohi Girls Hostel, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57877,
//       lng: 73.98007,
//     },
//     access: [
//       {
//         lat: 18.57882,
//         lng: 73.9799,
//       },
//     ],
//     distance: 2083,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224403230',
//       },
//     ],
//   },
//   {
//     title: 'Hotel New Kaveri',
//     id: 'here:pds:place:356jx7ps-4afc8b009cdd09eaf3aec48761755fc0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel New Kaveri, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58084,
//       lng: 73.97761,
//     },
//     access: [
//       {
//         lat: 18.58069,
//         lng: 73.97766,
//       },
//     ],
//     distance: 2158,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1148029676',
//       },
//     ],
//   },
//   {
//     title: 'Bhakti Girls Hostel',
//     id: 'here:pds:place:356jx7ps-19bf4767743f0db999f7f83f49bcb6ed',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Bhakti Girls Hostel, Domkhel Road, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       street: 'Domkhel Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57522,
//       lng: 73.98258,
//     },
//     access: [
//       {
//         lat: 18.57521,
//         lng: 73.98272,
//       },
//     ],
//     distance: 2190,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174595906',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919011056905',
//           },
//           {
//             value: '+919850694466',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Swapnshilp',
//     id: 'here:pds:place:356jx7ps-4302c8d99659019d0b89c5a776b17542',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Swapnshilp, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57777,
//       lng: 73.9794,
//     },
//     access: [
//       {
//         lat: 18.57763,
//         lng: 73.9794,
//       },
//     ],
//     distance: 2210,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097420402',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912025530053',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Ranvara-Kalubai Nagar',
//     id: 'here:pds:place:356tek9e-f3353b4c276b2012b49577892cfd755f',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Ranvara-Kalubai Nagar, Domkhel Road, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       street: 'Domkhel Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57486,
//       lng: 73.98269,
//     },
//     access: [
//       {
//         lat: 18.57485,
//         lng: 73.98273,
//       },
//     ],
//     distance: 2216,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224636927',
//       },
//     ],
//   },
//   {
//     title: 'Swara Hostel',
//     id: 'here:pds:place:356tek9e-265ee30096c31aecb3c43bf4fd0315dc',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Swara Hostel, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57408,
//       lng: 73.98387,
//     },
//     access: [
//       {
//         lat: 18.57408,
//         lng: 73.98373,
//       },
//     ],
//     distance: 2218,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224636089',
//       },
//     ],
//   },
//   {
//     title: 'Mauli Boys Hostel',
//     id: 'here:pds:place:356jx7ps-68b31e9442cc0fb7cb463bf1d4b930e1',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Mauli Boys Hostel, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57504,
//       lng: 73.98231,
//     },
//     access: [
//       {
//         lat: 18.5752,
//         lng: 73.98231,
//       },
//     ],
//     distance: 2224,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418033',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174595905',
//       },
//     ],
//   },
//   {
//     title: 'Vaibhav Boys Hostel',
//     id: 'here:pds:place:356jx7ps-1c0121cd980f0caeacb324c70ccb5bf7',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Vaibhav Boys Hostel, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57504,
//       lng: 73.98231,
//     },
//     access: [
//       {
//         lat: 18.5752,
//         lng: 73.98231,
//       },
//     ],
//     distance: 2224,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418035',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174595907',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919921561000',
//           },
//           {
//             value: '+919960864809',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Shriram Shani',
//     id: 'here:pds:place:356jx7ps-ad83c998314b0bf809e6ea1db8ab9070',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Shriram Shani, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57452,
//       lng: 73.98276,
//     },
//     access: [
//       {
//         lat: 18.57455,
//         lng: 73.98273,
//       },
//     ],
//     distance: 2242,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097419849',
//       },
//     ],
//   },
//   {
//     title: 'Paradise Girls Hostel',
//     id: 'here:pds:place:356tek9e-1c28e3100eca34e8cae266c518676f03',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Paradise Girls Hostel, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57768,
//       lng: 73.979,
//     },
//     access: [
//       {
//         lat: 18.57764,
//         lng: 73.97907,
//       },
//     ],
//     distance: 2248,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224736069',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+917387995969',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Prasad Boys Hostel',
//     id: 'here:pds:place:356jx7ps-68449b3a592e0874fcdafb81391b99aa',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Prasad Boys Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57356,
//       lng: 73.98414,
//     },
//     access: [
//       {
//         lat: 18.57372,
//         lng: 73.98414,
//       },
//     ],
//     distance: 2252,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144051138',
//       },
//     ],
//   },
//   {
//     title: 'Yashraj Boys Hostel',
//     id: 'here:pds:place:356jx7ps-e1f26577c3930d8ede6ca5c0236a416f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Yashraj Boys Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57387,
//       lng: 73.98334,
//     },
//     access: [
//       {
//         lat: 18.57379,
//         lng: 73.98321,
//       },
//     ],
//     distance: 2267,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1105873543',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144051134',
//       },
//     ],
//   },
//   {
//     title: 'Shivling Boys Hostel',
//     id: 'here:pds:place:356jx7ps-c64e96b661e400e4440c9daec9bf72dc',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shivling Boys Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57355,
//       lng: 73.98356,
//     },
//     access: [
//       {
//         lat: 18.57342,
//         lng: 73.98348,
//       },
//     ],
//     distance: 2285,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144051139',
//       },
//     ],
//   },
//   {
//     title: 'Shri Gyanraj Hostel',
//     id: 'here:pds:place:356jx7ps-b780b67cba9504ad93d6cd8331ba69f9',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Gyanraj Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57397,
//       lng: 73.98279,
//     },
//     access: [
//       {
//         lat: 18.57403,
//         lng: 73.98264,
//       },
//     ],
//     distance: 2290,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174542902',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919175479949',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Jay Ganga Hostel',
//     id: 'here:pds:place:356jx7ps-6650bdba2e480921013ba4cdeac3a3a8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Jay Ganga Hostel, Baif Road, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       street: 'Baif Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57725,
//       lng: 73.97864,
//     },
//     access: [
//       {
//         lat: 18.5772,
//         lng: 73.9784,
//       },
//     ],
//     distance: 2308,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418526',
//       },
//     ],
//   },
//   {
//     title: 'Oyo Life Pun928 Wagholi',
//     id: 'here:pds:place:356tek9e-d0d9f6d152c7e7d7cd021d2f61521dd3',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Oyo Life Pun928 Wagholi, Awhalwadi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Awhalwadi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57278,
//       lng: 73.98394,
//     },
//     access: [
//       {
//         lat: 18.57304,
//         lng: 73.98397,
//       },
//     ],
//     distance: 2337,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911246201615',
//           },
//         ],
//         www: [
//           {
//             value:
//               'https://www.oyorooms.com/hotels-in-Pune/?utm_source\\\\u003dGoogle_gmb_so&utm_medium\\\\u003dOrganic&utm_campaign\\\\u003dPUN928',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Ram Ganga',
//     id: 'here:pds:place:356jx7ps-99ca8b3a28ba0cbfef8f510ab994df39',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Ram Ganga, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57845,
//       lng: 73.97697,
//     },
//     access: [
//       {
//         lat: 18.57848,
//         lng: 73.97682,
//       },
//     ],
//     distance: 2362,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097419850',
//       },
//     ],
//   },
//   {
//     title: 'Chandbhaga Hostel',
//     id: 'here:pds:place:356tek9e-dfe733307731acf0eaeb790e35913f89',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Chandbhaga Hostel, Baif Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Baif Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57674,
//       lng: 73.97838,
//     },
//     access: [
//       {
//         lat: 18.57676,
//         lng: 73.97849,
//       },
//     ],
//     distance: 2366,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224607655',
//       },
//     ],
//   },
//   {
//     title: 'Bhairavnath Hotel',
//     id: 'here:pds:place:356jx7ps-8e37dc872b760d46e57dd59c27f32d97',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Bhairavnath Hotel, Kopargaon Shirdi Ahmednagar Pune Highway, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57971,
//       lng: 73.97495,
//     },
//     access: [
//       {
//         lat: 18.57959,
//         lng: 73.97499,
//       },
//     ],
//     distance: 2463,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1164929932',
//       },
//     ],
//   },
//   {
//     title: 'Muktai Dattasamruddhinagar Boys Hostel',
//     id: 'here:pds:place:356jx7ps-0a126de13f03091565849042524ae163',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Muktai Dattasamruddhinagar Boys Hostel, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.59918,
//       lng: 74.01682,
//     },
//     access: [
//       {
//         lat: 18.59918,
//         lng: 74.01677,
//       },
//     ],
//     distance: 2466,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176566682',
//       },
//     ],
//   },
//   {
//     title: 'Rahul Executive Lodge',
//     id: 'here:pds:place:356tek9u-b08e4a6d6db977e68b99cbe04bd7e5c9',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Rahul Executive Lodge, Kopargaon Shirdi Ahmednagar Pune Highway, Ram Nagar, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       subdistrict: 'Ram Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.60556,
//       lng: 74.01404,
//     },
//     access: [
//       {
//         lat: 18.60558,
//         lng: 74.01402,
//       },
//     ],
//     distance: 2574,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Fab Hotel Rahul Executive',
//     id: 'here:pds:place:356tek9u-ee5ab1f8725eddb2b1d557eb7de58524',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Fab Hotel Rahul Executive, Kopargaon Shirdi Ahmednagar Pune Highway, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.60591,
//       lng: 74.01453,
//     },
//     access: [
//       {
//         lat: 18.60598,
//         lng: 74.01441,
//       },
//     ],
//     distance: 2639,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '26878301',
//       },
//     ],
//     contacts: [
//       {
//         www: [
//           {
//             value:
//               'https://www.fabhotels.com/hotels-in-pune/fabhotel-rahul-executive.html',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Oyo 40158 Rahul Executive',
//     id: 'here:pds:place:356tek9u-baaa9474a7174e6ea0e0f5e9c046535e',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Oyo 40158 Rahul Executive, Kopargaon Shirdi Ahmednagar Pune Highway, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.60594,
//       lng: 74.01455,
//     },
//     access: [
//       {
//         lat: 18.60601,
//         lng: 74.01443,
//       },
//     ],
//     distance: 2643,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1212583016',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '19743262',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '23381320',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911246201217',
//           },
//         ],
//         www: [
//           {
//             value:
//               'http://www.oyorooms.com/58618?utm_source=tripadvisor&utm_medium=listing&utm_campaign=PUN679',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: 'https://www.oyorooms.com',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Shraddha Lodge',
//     id: 'here:pds:place:356tek9u-7913df43c1543a26e241924a44ef39b7',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Shraddha Lodge, Kopargaon Shirdi Ahmednagar Pune Highway, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.60589,
//       lng: 74.01466,
//     },
//     access: [
//       {
//         lat: 18.60603,
//         lng: 74.01444,
//       },
//     ],
//     distance: 2648,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Khandave Boys Hostel',
//     id: 'here:pds:place:356jx7ps-765f98548e8307598e53f271e77fa77c',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Khandave Boys Hostel, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5704,
//       lng: 73.98238,
//     },
//     access: [
//       {
//         lat: 18.57054,
//         lng: 73.9823,
//       },
//     ],
//     distance: 2649,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418130',
//       },
//     ],
//   },
//   {
//     title: 'Kanifnath-Haveli',
//     id: 'here:pds:place:356tek9g-266c821cac8b4de9ae3ee327aca513ce',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Kanifnath-Haveli, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.61364,
//       lng: 73.98672,
//     },
//     access: [
//       {
//         lat: 18.6137,
//         lng: 73.98688,
//       },
//     ],
//     distance: 2673,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001410967',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912027281543',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Sattyajeet Lodging Boarding',
//     id: 'here:pds:place:356jx7ps-e37a4e85e3620a8d26a51385e0c08e0b',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Sattyajeet Lodging Boarding, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57823,
//       lng: 73.97112,
//     },
//     access: [
//       {
//         lat: 18.57806,
//         lng: 73.97118,
//       },
//     ],
//     distance: 2895,
//     categories: [
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418743',
//       },
//     ],
//   },
//   {
//     title: "Hotel the Royal's",
//     id: 'here:pds:place:356tek9e-8ce8e8ef3ac2cd9bfea198a8a08c61ea',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         "Hotel the Royal's, Wagholi Lohagaon Road, Wagholi, Haveli 412207, India",
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Wagholi Lohagaon Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58687,
//       lng: 73.96705,
//     },
//     access: [
//       {
//         lat: 18.58645,
//         lng: 73.96693,
//       },
//     ],
//     distance: 2990,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Oyo 10939 Hotel Sangam',
//     id: 'here:pds:place:356tek9e-f3a5c48d0fb64dd18f9649a5c29f6db1',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Oyo 10939 Hotel Sangam, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57812,
//       lng: 73.96894,
//     },
//     access: [
//       {
//         lat: 18.57819,
//         lng: 73.96908,
//       },
//     ],
//     distance: 3103,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1212580470',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912069209999',
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.oyorooms.com',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Oyo 74535 Hotel Luxury Inn',
//     id: 'here:pds:place:356tek9e-9d4d72264c85b3b2b9765ae0cb08d47d',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Oyo 74535 Hotel Luxury Inn, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57412,
//       lng: 73.96891,
//     },
//     access: [
//       {
//         lat: 18.5741,
//         lng: 73.96898,
//       },
//     ],
//     distance: 3334,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911244588630',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Yashraj Medical',
//     id: 'here:pds:place:356jx7ps-4343af3570ec0bc93343f969495ad940',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Yashraj Medical, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57683,
//       lng: 73.96584,
//     },
//     access: [
//       {
//         lat: 18.57669,
//         lng: 73.96588,
//       },
//     ],
//     distance: 3460,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1148029690',
//       },
//     ],
//     contacts: [
//       {
//         www: [
//           {
//             value: 'https://www.facebook.com/265634854195837',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Jogeshwari-Haveli',
//     id: 'here:pds:place:356tek9s-9bff89d82fcad2d0be1e7a82e8633090',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Jogeshwari-Haveli, Theur Kesnand Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Theur Kesnand Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57312,
//       lng: 74.02208,
//     },
//     access: [
//       {
//         lat: 18.57304,
//         lng: 74.02222,
//       },
//     ],
//     distance: 3467,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001428783',
//       },
//     ],
//   },
//   {
//     title: 'Saideep Lodge',
//     id: 'here:pds:place:356tek9e-cb3c0a828e40d6aa1f8746ea34300d7b',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Saideep Lodge, Priyanka Nagari-Pasalkar Colony, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Priyanka Nagari-Pasalkar Colony',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57574,
//       lng: 73.96425,
//     },
//     access: [
//       {
//         lat: 18.57574,
//         lng: 73.96419,
//       },
//     ],
//     distance: 3664,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5100-0057',
//         name: 'Guest House',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224644351',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '21042976',
//       },
//     ],
//   },
//   {
//     title: 'Vidya Niketan Hostel',
//     id: 'here:pds:place:356jx7ps-8ad5a9233d71054441e0506ee705cecb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Vidya Niketan Hostel, Priyanka Nagari-Pasalkar Colony, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Priyanka Nagari-Pasalkar Colony',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57606,
//       lng: 73.96401,
//     },
//     access: [
//       {
//         lat: 18.57606,
//         lng: 73.96418,
//       },
//     ],
//     distance: 3670,
//     categories: [
//       {
//         id: '500-5100-0055',
//         name: 'Hostel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981037',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919922901905',
//           },
//           {
//             value: '+919922901906',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Royal Inn Guest House-Wagholi',
//     id: 'here:pds:place:356tek9e-0a07186ce4d544bc8b1c572065f38cdb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Royal Inn Guest House-Wagholi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57619,
//       lng: 73.96321,
//     },
//     access: [
//       {
//         lat: 18.5762,
//         lng: 73.96338,
//       },
//     ],
//     distance: 3739,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144057981',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912032515809',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hello World Eon',
//     id: 'here:pds:place:356tek9e-1efed075eff5421980325fbf7a256ed6',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hello World Eon, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57694,
//       lng: 73.96262,
//     },
//     access: [
//       {
//         lat: 18.57681,
//         lng: 73.96263,
//       },
//     ],
//     distance: 3760,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+918880008888',
//           },
//         ],
//         www: [
//           {
//             value: 'helloworld.com',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Yash Residency',
//     id: 'here:pds:place:356jx7ps-6ebcae74249108427a31654dbedd6a8f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Yash Residency, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57619,
//       lng: 73.96286,
//     },
//     access: [
//       {
//         lat: 18.57617,
//         lng: 73.96265,
//       },
//     ],
//     distance: 3773,
//     categories: [
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981281',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '6833044',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912065001925',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: '+9165001924',
//           },
//         ],
//         mobile: [
//           {
//             value: '+917558209966',
//             categories: [
//               {
//                 id: '500-5100-0000',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.yashresidency.com',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//         email: [
//           {
//             value: 'reservations@yashresidency.com',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '500-5000-0053',
//           },
//         ],
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotelsurya Lodging & Guest House',
//     id: 'here:pds:place:356tek9e-28502de0da4948f094e22c428c9b399b',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotelsurya Lodging & Guest House, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57606,
//       lng: 73.96281,
//     },
//     access: [
//       {
//         lat: 18.57604,
//         lng: 73.96267,
//       },
//     ],
//     distance: 3784,
//     categories: [
//       {
//         id: '500-5100-0057',
//         name: 'Guest House',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//     ],
//   },
//   {
//     title: 'Suvarna-Haveli',
//     id: 'here:pds:place:356tek9u-73014620fa3c46b1a834a9e2a6c3228e',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Suvarna-Haveli, Pune Nagar Road, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       street: 'Pune Nagar Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.61628,
//       lng: 74.01927,
//     },
//     access: [
//       {
//         lat: 18.6162,
//         lng: 74.01942,
//       },
//     ],
//     distance: 3798,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '998055071',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912135202555',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Surya Lodge',
//     id: 'here:pds:place:356tek9e-fbabd306b16047489e74c1e7f07c2422',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Surya Lodge, Kopargaon Shirdi Ahmednagar Pune Highway, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57582,
//       lng: 73.9625,
//     },
//     access: [
//       {
//         lat: 18.5757,
//         lng: 73.96255,
//       },
//     ],
//     distance: 3825,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//       {
//         id: '400-4300-0000',
//         name: 'Rest Area',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224225194',
//       },
//     ],
//   },
//   {
//     title: 'Sumit-Khed',
//     id: 'here:pds:place:356tek9g-86942708198a41d3ac6bc80356783a78',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Sumit-Khed, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.62352,
//       lng: 73.98259,
//     },
//     access: [
//       {
//         lat: 18.62364,
//         lng: 73.98271,
//       },
//     ],
//     distance: 3854,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001434474',
//       },
//     ],
//   },
//   {
//     title: 'Professional Student Accommodation',
//     id: 'here:pds:place:356tek9e-f7a956f68c3c52b8f8bba7b7e1a673e3',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Professional Student Accommodation, Kawade Wasti-Dr Kolte Patil Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Kawade Wasti-Dr Kolte Patil Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57863,
//       lng: 73.96065,
//     },
//     access: [
//       {
//         lat: 18.57877,
//         lng: 73.96066,
//       },
//     ],
//     distance: 3880,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224611520',
//       },
//     ],
//   },
//   {
//     title: 'Renuka Restaurant & Lodging',
//     id: 'here:pds:place:356tek9e-d792d42a77084f1ea58ce0a8cea99b84',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Renuka Restaurant & Lodging, SH-60, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57526,
//       lng: 73.96201,
//     },
//     access: [
//       {
//         lat: 18.57535,
//         lng: 73.96196,
//       },
//     ],
//     distance: 3899,
//     categories: [
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1070276298',
//       },
//     ],
//   },
//   {
//     title: 'Hotelblue Inn Resto',
//     id: 'here:pds:place:356tek9e-1cd371b19c984fa0b4b488befa505dd0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotelblue Inn Resto, Kopargaon Shirdi Ahmednagar Pune Highway, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57522,
//       lng: 73.962,
//     },
//     access: [
//       {
//         lat: 18.57534,
//         lng: 73.96194,
//       },
//     ],
//     distance: 3902,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '24098136',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911246201217',
//           },
//           {
//             value: '+917700009393',
//           },
//         ],
//         mobile: [
//           {
//             value: '+917700009393',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.facebook.com/1197072460378650',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: 'https://www.oyorooms.com/87212/',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '500-5000-0053',
//           },
//         ],
//         text: ['Mon-Sun: 06:00 - 22:30'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T060000',
//             duration: 'PT16H30M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Oyo 24125 Hotel Aakash',
//     id: 'here:pds:place:356tek9f-6275669e3b93758a2f4ec5953c3e5307',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Oyo 24125 Hotel Aakash, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58952,
//       lng: 73.95809,
//     },
//     access: [
//       {
//         lat: 18.58944,
//         lng: 73.95837,
//       },
//     ],
//     distance: 3903,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 06:00 - 23:30'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T060000',
//             duration: 'PT17H30M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'A Hotelakash',
//     id: 'here:pds:place:356tek9f-a0770a44296ca2117d5a922e8227a547',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'A Hotelakash, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58897,
//       lng: 73.95811,
//     },
//     access: [
//       {
//         lat: 18.58894,
//         lng: 73.95825,
//       },
//     ],
//     distance: 3904,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Sai Lodge',
//     id: 'here:pds:place:356tek9e-a8523c659d8859cdd358c28f96b3d746',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Sai Lodge, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57537,
//       lng: 73.96086,
//     },
//     access: [
//       {
//         lat: 18.57543,
//         lng: 73.96093,
//       },
//     ],
//     distance: 4002,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224645369',
//       },
//     ],
//   },
//   {
//     title: 'Flagship Ayush Greenhills Executive Lodging',
//     id: 'here:pds:place:356tek9s-5fc06fa80db28d94f0fa5d396820c674',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Flagship Ayush Greenhills Executive Lodging, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58877,
//       lng: 74.03369,
//     },
//     access: [
//       {
//         lat: 18.5889,
//         lng: 74.03367,
//       },
//     ],
//     distance: 4075,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [],
//     contacts: [
//       {
//         www: [
//           {
//             value: 'https://www.oyorooms.com/203964',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Loginn Plaza',
//     id: 'here:pds:place:356tek9d-a64172c61915f1a4b53411d318451ffa',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Loginn Plaza, Sai Satyam Park Lane No 2, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       street: 'Sai Satyam Park Lane No 2',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57487,
//       lng: 73.96015,
//     },
//     access: [
//       {
//         lat: 18.57487,
//         lng: 73.96008,
//       },
//     ],
//     distance: 4094,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Collection O 76845 Log Inn Plaza',
//     id: 'here:pds:place:356tek9d-aad4a0d957254036ba4d3ac622234b1e',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Collection O 76845 Log Inn Plaza, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57469,
//       lng: 73.95988,
//     },
//     access: [
//       {
//         lat: 18.57469,
//         lng: 73.95974,
//       },
//     ],
//     distance: 4128,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 06:00 - 23:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T060000',
//             duration: 'PT17H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Oyo 23501 Log Inn Plaza',
//     id: 'here:pds:place:356tek9d-c3664501f4de769151d3b9f0382771ad',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Oyo 23501 Log Inn Plaza, Kopargaon Shirdi Ahmednagar Pune Highway, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5748,
//       lng: 73.95982,
//     },
//     access: [
//       {
//         lat: 18.57468,
//         lng: 73.95987,
//       },
//     ],
//     distance: 4128,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '23381158',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911244588630',
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.oyorooms.com',
//             categories: [
//               {
//                 id: '500-5000-0000',
//               },
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/h/45801?utm_source=Bing_bp&utm_medium=listing&utm_campaign=PUN480',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Log Inn Plaza',
//     id: 'here:pds:place:356tek9d-aab3c4b80b8a8c0bd304527259ea9f45',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Log Inn Plaza, Sai Satyam Park Lane No 3, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       street: 'Sai Satyam Park Lane No 3',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5749,
//       lng: 73.9597,
//     },
//     access: [
//       {
//         lat: 18.57491,
//         lng: 73.95985,
//       },
//     ],
//     distance: 4135,
//     categories: [
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1070276294',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '17573317',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919172777559',
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.oyorooms.com/45801',
//             categories: [
//               {
//                 id: '500-5000-0000',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Stallion Residences',
//     id: 'here:pds:place:356tek9d-ef1bd3d1e7da2484a6d02dcdb4ac8ab0',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Stallion Residences, Sai Satyam Park Lane No 8, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       street: 'Sai Satyam Park Lane No 8',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57668,
//       lng: 73.95877,
//     },
//     access: [
//       {
//         lat: 18.57667,
//         lng: 73.95871,
//       },
//     ],
//     distance: 4144,
//     categories: [
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Loginn Plaza Resto Bar',
//     id: 'here:pds:place:356tek3r-50f2b8d86d53448bad7363186e937e1e',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Loginn Plaza Resto Bar, Sai Satyam Park Lane No 4, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       street: 'Sai Satyam Park Lane No 4',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57463,
//       lng: 73.95949,
//     },
//     access: [
//       {
//         lat: 18.57464,
//         lng: 73.95963,
//       },
//     ],
//     distance: 4168,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//       {
//         id: '200-2000-0000',
//         name: 'Nightlife-Entertainment',
//       },
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//       },
//       {
//         id: '500-5100-0057',
//         name: 'Guest House',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1212580511',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224239907',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224239927',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224239949',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919172777559',
//           },
//         ],
//       },
//     ],
//     payment: {
//       methods: [
//         {
//           id: 'mastercard',
//           accepted: true,
//         },
//       ],
//     },
//   },
//   {
//     title: 'Hotel Jay Bhawani-Vitthalwadi',
//     id: 'here:pds:place:356tek9d-f3c5f200c98ebdad5de75ff0f2ca7e29',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Jay Bhawani-Vitthalwadi, Kopargaon Shirdi Ahmednagar Pune Highway, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57338,
//       lng: 73.95767,
//     },
//     access: [
//       {
//         lat: 18.5735,
//         lng: 73.9576,
//       },
//     ],
//     distance: 4401,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224606375',
//       },
//     ],
//   },
//   {
//     title: 'Mahendra Lodge & Restaurant',
//     id: 'here:pds:place:356jx7ps-9480146a84dd0f2d005296ee035a028c',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Mahendra Lodge & Restaurant, SH-60, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57335,
//       lng: 73.95752,
//     },
//     access: [
//       {
//         lat: 18.57344,
//         lng: 73.95747,
//       },
//     ],
//     distance: 4417,
//     categories: [
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981071',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981078',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Collection O 30037 Hotel Maple',
//     id: 'here:pds:place:356tek9d-e95dcf846cb74f55b8a614b608ffe5fa',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Collection O 30037 Hotel Maple, Kopargaon Shirdi Ahmednagar Pune Highway, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57321,
//       lng: 73.95732,
//     },
//     access: [
//       {
//         lat: 18.57333,
//         lng: 73.95725,
//       },
//     ],
//     distance: 4443,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//       {
//         id: '500-5000-0000',
//         name: 'Hotel/Motel',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097334845',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '1930052',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '1940769',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912040774077',
//           },
//           {
//             value: '+918002468357',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.oyorooms.com/45968',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: 'https://www.mapplehotels.com/mapple-pune/index.html',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value: 'https://www.oyorooms.com',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Edition O Maple Hotel Wagholi Pune',
//     id: 'here:pds:place:356773bc-8c910318ba6e0425719ba984f5272331',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Edition O Maple Hotel Wagholi Pune, Kopargaon Shirdi Ahmednagar Pune Highway, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5732,
//       lng: 73.9573,
//     },
//     access: [
//       {
//         lat: 18.57332,
//         lng: 73.95723,
//       },
//     ],
//     distance: 4445,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912040774077',
//           },
//           {
//             value: '+918698301301',
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.mapplehotels.com',
//           },
//           {
//             value: 'https://www.facebook.com/2177141435873886',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Rajyog & Lodging',
//     id: 'here:pds:place:356tek9f-29d6c255a4f062bed523459160ef5cc0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Rajyog & Lodging, Lohegaon, Pune 411047, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Pune',
//       district: 'Lohegaon',
//       postalCode: '411047',
//     },
//     position: {
//       lat: 18.59201,
//       lng: 73.95259,
//     },
//     access: [
//       {
//         lat: 18.59199,
//         lng: 73.95261,
//       },
//     ],
//     distance: 4481,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1231652826',
//       },
//     ],
//   },
//   {
//     title: 'Oyo 86170 Hotel Rajyog',
//     id: 'here:pds:place:356tek9f-9584753273b36f30f71ba58584abb40a',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Oyo 86170 Hotel Rajyog, Lohegaon, Pune 411047, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Pune',
//       district: 'Lohegaon',
//       postalCode: '411047',
//     },
//     position: {
//       lat: 18.59207,
//       lng: 73.9525,
//     },
//     access: [
//       {
//         lat: 18.59197,
//         lng: 73.9526,
//       },
//     ],
//     distance: 4490,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//     references: [],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 06:00 - 22:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T060000',
//             duration: 'PT16H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Oyo-Vitthalwadi',
//     id: 'here:pds:place:356tek9d-7c1c3ba25ab7af7c57e8b78ab2abb796',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Oyo-Vitthalwadi, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5727,
//       lng: 73.95684,
//     },
//     access: [
//       {
//         lat: 18.57268,
//         lng: 73.95679,
//       },
//     ],
//     distance: 4513,
//     categories: [
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Collection O 23697 Krushna Rooms',
//     id: 'here:pds:place:356tek9d-0abbe25b9c93ecfa7a5194aa28ab5486',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Collection O 23697 Krushna Rooms, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5727,
//       lng: 73.95684,
//     },
//     access: [
//       {
//         lat: 18.57268,
//         lng: 73.95679,
//       },
//     ],
//     distance: 4513,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '500-5100-0058',
//         name: 'Bed and Breakfast',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '10113713',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '24113283',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+911246201600',
//           },
//         ],
//         www: [
//           {
//             value:
//               'http://www.oyorooms.com/46024?utm_source=tripadvisor&utm_medium=listing&utm_campaign=PUN489',
//             categories: [
//               {
//                 id: '500-5100-0000',
//               },
//             ],
//           },
//           {
//             value: 'https://www.oyorooms.com/46024',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/46024/?utm_source=Google_gmb&utm_medium=Organic&utm_campaign=PUN489&latitude=18.5728&longitude=73.95691&locale=en',
//             categories: [
//               {
//                 id: '500-5100-0058',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Krushna Lodge',
//     id: 'here:pds:place:356tek9d-4f7807b52f57e30da1ac500cc4cb0a54',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Krushna Lodge, Kopargaon Shirdi Ahmednagar Pune Highway, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57298,
//       lng: 73.95668,
//     },
//     access: [
//       {
//         lat: 18.57301,
//         lng: 73.95666,
//       },
//     ],
//     distance: 4515,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Samadhan Restaurant & Lodging',
//     id: 'here:pds:place:356tek9s-9e348fc9bd982651d97edc614dc44aa7',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Samadhan Restaurant & Lodging, Vadebolhai Road, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       street: 'Vadebolhai Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57346,
//       lng: 74.03399,
//     },
//     access: [
//       {
//         lat: 18.5736,
//         lng: 74.03397,
//       },
//     ],
//     distance: 4538,
//     categories: [
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176566400',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570346',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919823507171',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: '+919923507171',
//             categories: [
//               {
//                 id: '500-5100-0000',
//               },
//             ],
//           },
//         ],
//         mobile: [
//           {
//             value: '+919822336351',
//           },
//           {
//             value: '+919823507171',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: '+919923507171',
//           },
//         ],
//       },
//     ],
//   },
// ];

export const NearbyHotels = () => {
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(10);
  const { data, isLoading, isError, refetch } = useSearchLocation({
    variables: {
      categoryType: 'hotel', //@ts-ignore
      gps: `${location?.coords?.latitude},${location?.coords?.longitude}`,
      distance: distance * 1000,
    },
  });
  console.log(data);

  const debounedSetState = debounce((value) => {
    setDistance(value);
  }, 500);

  const renderItem = React.useCallback(
    //  @ts-ignore
    ({ item }) => <LocationCard {...item} />,
    []
  );

  useEffect(() => {
    (async () => {
      const currentLocation = await getCurrentLocation(); //@ts-ignore
      setLocation(currentLocation);
    })();
  }, []);

  useEffect(() => {
    refetch();
  }, [location, refetch, distance]);

  return (
    <>
      <ScrollView className="p-4 dark:bg-themeblue-600">
        <Text className="mb-4">Adjust the radius for nearby activities</Text>
        <Slider
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ width: '100%', height: 40 }}
          minimumValue={0}
          maximumValue={50}
          step={1}
          value={distance}
          minimumTrackTintColor={colors.themeblue[400]}
          maximumTrackTintColor={colors.themeblue[300]}
          thumbTintColor={colors.themeblue[400]}
          onValueChange={(value: number) => {
            debounedSetState(value);
          }}
        />
        <View className="flex-row justify-between">
          <Text className="m-2">1km</Text>
          <Text className="m-2">50km</Text>
        </View>
        <Text>Activities within {distance} kms</Text>
        {isLoading && (
          <View className="flex-1  justify-center">
            <ActivityIndicator />
          </View>
        )}
        {isError && (
          <View>
            <Text> Error Loading data </Text>
          </View>
        )}
        {data && (
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(_, index) => `item-${index}`}
            ListEmptyComponent={<EmptyList isLoading={false} />}
            // estimatedItemSize={199}
          />
        )}
      </ScrollView>
    </>
  );
};
