// import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { useColorScheme } from 'nativewind';
import * as React from 'react';

import { NearbyHotels, NearbyPlaces, NearbyRestaurants } from '@/screens';
import {
  colors, // Pressable, Text,
  EmptyList,
} from '@/ui';

export type NearbyStackParamList = {
  Places: undefined;
  Food: undefined;
  Hotels: undefined;
};

// const Stack = createNativeStackNavigator<NearbyStackParamList>();

// const GoToAddPost = () => {
//   return (
//     <Pressable onPress={() => {}} className="p-2">
//       <Text className="text-primary-300">Create</Text>
//     </Pressable>
//   );
// };
const Tab = createMaterialTopTabNavigator<NearbyStackParamList>();

type Props = {
  navigation: any;
  route: any;
};

export const NearbyNavigator = ({ navigation, route }: Props) => {
  const { colorScheme } = useColorScheme();
  React.useLayoutEffect(() => {
    navigation.setOptions({ title: route.params?.name });
  }, [navigation, route]);
  return (
    <NavigationContainer independent>
      <Tab.Navigator
        initialRouteName="Places"
        screenOptions={{
          tabBarLabelStyle: {
            fontSize: 12,
            fontWeight: 'bold',
            // color: colors.black[500],
          },
          tabBarIndicatorStyle: {
            backgroundColor:
              colorScheme === 'dark' ? colors.white : colors.themeblue[500],
            height: '100%',
          },
          tabBarActiveTintColor:
            colorScheme === 'dark' ? colors.themeblue[500] : colors.white,
          tabBarInactiveTintColor:
            colorScheme === 'dark' ? colors.white : colors.themeblue[500],
          swipeEnabled: false,
          lazy: true,
          lazyPlaceholder: () => <EmptyList isLoading={true} />,
          lazyPreloadDistance: 0,
          tabBarStyle: {
            // marginTop: Platform.OS === 'ios' ? 100 : 60,
            backgroundColor:
              colorScheme === 'dark' ? colors.themeblue[500] : colors.white,
            // position: 'absolute',
            // elevation: 0,
            // bottom: 5,
          },
        }}
      >
        <Tab.Screen name="Places" component={NearbyPlaces} />
        <Tab.Screen name="Food" component={NearbyRestaurants} />
        <Tab.Screen name="Hotels" component={NearbyHotels} />
      </Tab.Navigator>
    </NavigationContainer>
    // <Stack.Navigator
    //   screenOptions={{
    //     headerShown: false,
    //   }}
    // >
    //   <Stack.Group
    //     screenOptions={{
    //       // eslint-disable-next-line react/no-unstable-nested-components
    //       headerRight: () => <GoToAddPost />,
    //     }}
    //   >
    //     {/* <Stack.Screen name="HomeScreen" component={Home} /> */}
    //   </Stack.Group>

    //   <Stack.Screen name="Nearby" component={Nearby} />
    // </Stack.Navigator>
  );
};
