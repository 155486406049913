import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { RefreshControl } from 'react-native';

import { useMyTrips, useTrips } from '@/api';
import { Button, EmptyList, ScrollView, Text, View } from '@/ui';

import { Card as SavedCard } from '../home/card';
import { Card } from './card';

// const tempData = [
//   {
//     id: 1,
//     name: 'Uttrakhand',
//     // tags: ['trending', 'cost-optimized', 'short-transit', 'luxury'],
//     places: 14,
//     food: 7,
//     activities: 5,
//     members: 4,
//     staynights: 3,
//     likes: 148,
//     ratings: 4.2,
//     clones: 70,
//     // cost: {
//     //   min: '5k',
//     //   max: '6k',
//     //   currency: 'INR',
//     // },
//     photos: [
//       'https://travelotouch.com/wp-content/uploads/2019/02/UttrakhandDE.jpg',
//       'https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/nainital-uttrakhand-india-jitendra-singh-is-a-new-delhi--shimla-based-photojournalist.jpg',
//       'https://t3.ftcdn.net/jpg/05/65/88/92/360_F_565889211_WaJFOlDRVW32jZFFwuLrUej99bQ69Sd3.jpg',
//     ],
//     status: 'completed',
//     date: {
//       start: '20th May 2023',
//     },
//   },
//   {
//     id: 2,
//     name: 'Kerala',
//     // tags: ['trending', 'cost-optimized', 'short-transit', 'luxury'],
//     places: 14,
//     food: 7,
//     activities: 5,
//     members: 2,
//     staynights: 3,
//     likes: 148,
//     ratings: 4.2,
//     clones: 70,
//     // cost: {
//     //   min: '5k',
//     //   max: '6k',
//     //   currency: 'INR',
//     // },
//     photos: [
//       'https://travelotouch.com/wp-content/uploads/2019/02/UttrakhandDE.jpg',
//       'https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/nainital-uttrakhand-india-jitendra-singh-is-a-new-delhi--shimla-based-photojournalist.jpg',
//       'https://t3.ftcdn.net/jpg/05/65/88/92/360_F_565889211_WaJFOlDRVW32jZFFwuLrUej99bQ69Sd3.jpg',
//     ],
//     status: 'upcoming',
//     date: {
//       start: '26th Feb 2024',
//       end: '1st Mar 2024',
//     },
//   },
// ];

export const MyTrips = () => {
  const [status, setStatus] = React.useState('all');
  const { data, isLoading, isError, refetch } = useMyTrips();
  const {
    data: savedTrip,
    // isError: savedError,
    refetch: savedRefetch,
  } = useTrips();
  const [myTripData, setMyTripData] = React.useState(data);
  const { navigate } = useNavigation();

  const renderItem = React.useCallback(
    //  @ts-ignore
    ({ item }) =>
      status === 'saved' ? (
        <SavedCard
          {...item}
          onPress={() =>
            // {
            //   console.log('click');
            // }
            {
              navigate('Home');
              navigate('TripDetail', { id: item.id });
            }
          }
        />
      ) : (
        <Card
          {...item}
          onPress={() =>
            // {
            //   console.log('click');
            // }
            navigate('MyTripDetails', {
              id: item.id,
              bookingType: item?.bookingType,
            })
          }
        />
      ),
    [navigate, status]
    // [navigate]
  );

  React.useEffect(() => {
    if (status === 'saved') {
      //@ts-ignore
      setMyTripData(savedTrip?.filter((trip) => trip?.saved));
    } else {
      const currentDate = new Date();

      // Sort the trips based on the start date
      //@ts-ignore
      const sortedTrips = //@ts-ignore
        data?.length > 1 //@ts-ignore
          ? data.sort((a, b) => {
              const startDateA = new Date(a.dates.start).getTime();
              const startDateB = new Date(b.dates.start).getTime();
              return startDateA - startDateB;
            })
          : data;
      if (status === 'all') {
        setMyTripData(sortedTrips);
      } else {
        // Filter the trips based on status and whether the date has passed
        const filteredTrips = sortedTrips?.filter((trip) => {
          // if (trip.dates.end) {
          //   return status === "upcoming" ? new Date(trip.dates.end) > currentDate : new Date(trip.dates.end) < currentDate;
          // } else {
          return status === 'upcoming'
            ? new Date(trip.dates.start) > currentDate
            : new Date(trip.dates.start) < currentDate;
          // }
        });

        // Update the trips state with the filtered trips
        setMyTripData(filteredTrips);
      }
    }
  }, [status, data, savedTrip]);
  // React.useEffect(() => {
  //   const results = tempData.filter((item) =>
  //     status === 0
  //       ? item.status === 'completed' || item.status === 'upcoming'
  //       : status === 1
  //       ? item.status === 'completed'
  //       : item.status === 'upcoming'
  //   );
  //   setMyTripData(results);
  // }, [status]);

  if (isError) {
    return (
      <View>
        <Text> Error Loading data </Text>
      </View>
    );
  }

  return (
    <>
      <ScrollView
        className="flex grow"
        refreshControl={
          <RefreshControl
            refreshing={isLoading} // Use isLoading state to control the refresh indicator
            onRefresh={() => {
              refetch();
              savedRefetch();
            }}
          />
        }
      >
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          className="flex grow-[0]"
        >
          <View className="mx-2">
            <Button
              label="Saved"
              variant={status === 'saved' ? 'secondary' : 'outline'}
              onPress={() => setStatus('saved')}
            />
          </View>
          <View className="mx-2">
            <Button
              label="All"
              variant={status === 'all' ? 'secondary' : 'outline'}
              onPress={() => setStatus('all')}
            />
          </View>
          <View className="mx-2">
            <Button
              label="Upcoming"
              variant={status === 'upcoming' ? 'secondary' : 'outline'}
              onPress={() => setStatus('upcoming')}
            />
          </View>
          <View className="mx-2">
            <Button
              label="Completed"
              variant={status === 'completed' ? 'secondary' : 'outline'}
              onPress={() => setStatus('completed')}
            />
          </View>
        </ScrollView>
        <FlashList
          data={myTripData}
          numColumns={status === 'saved' ? 2 : 1}
          renderItem={renderItem}
          keyExtractor={(_, index) => `item-${index}`}
          ListEmptyComponent={<EmptyList isLoading={isLoading} />}
          estimatedItemSize={199}
        />
      </ScrollView>
    </>
  );
};
