import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { OTP } from './types';

type Variables = {};
type Response = OTP;

export const useSendMailOTP = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'sendMailOTP',
  queryFn: async ({ queryKey: [primaryKey, _variables] }) => {
    return clientWithAuthHeader
      .get(`verify/${primaryKey}`)
      .then((response) => response.data);
  },
  enabled: false,
});
