import { Env } from '@env';
import axios from 'axios';

import { getToken } from '@/core/auth/utils';
export const client = axios.create({
  baseURL: Env.API_URL,
});

export const clientWithAuthHeader = axios.create({
  baseURL: Env.API_URL,
});

// Add a request interceptor
clientWithAuthHeader.interceptors.request.use(
  function (config) {
    // Get the access token from wherever you store it
    const token = getToken(); // Replace this with your actual token
    // Add the Authorization header to the request
    config.headers.Authorization = `${token.access}`; //Bearer
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
