import React from 'react';

import { useTripCost } from '@/api';
import {
  ActivityIndicator,
  FocusAwareStatusBar,
  ScrollView,
  Text,
  View,
} from '@/ui';

//@ts-ignore
export const TripDetailCost = ({ route }) => {
  const { data, isLoading, isError } = useTripCost({
    variables: { id: route.params.id },
  });

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }

  const calculateFullCost = () => {
    let minCost = 0;
    let maxCost = 0;

    // Calculate checklistCost
    data?.checklistCost?.forEach((item) => {
      minCost += item.cost.min;
      maxCost += item.cost.max || item.cost.min;
    });

    // Calculate itineraryCost
    data?.itineraryCost?.forEach((item) => {
      minCost += item.cost.min;
      maxCost += item.cost.max || item.cost.min;
    });

    // Calculate hotelCost
    data?.hotelCost?.forEach((item) => {
      minCost += item.cost.min;
      maxCost += item.cost.max || item.cost.min;
    });

    // Calculate foodCost
    data?.foodCost?.forEach((item) => {
      minCost += item.cost.min;
      maxCost += item.cost.max || item.cost.min;
    });
    return { minCost, maxCost };
  };

  const { minCost, maxCost } = calculateFullCost();

  return (
    <ScrollView className="relative block overflow-scroll bg-white p-4 shadow-xl dark:bg-themeblue-600">
      <Text variant="xl">Total Members: {data.member}</Text>
      <View className="py-4">
        <Text variant="md">Hotels Cost</Text>
        <View className="mt-2 divide-y-[1px] divide-[cornflowerblue] rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 px-2 dark:bg-themeblue-500">
          {data?.hotelCost?.map((item) => {
            return item?.cost?.min ? (
              <View className="flex-row justify-between py-2">
                <Text variant="sm">
                  {item?.name} {'\n'}({item?.members} Members &{' '}
                  {item?.night || 1}N {(item?.night || 1) + 1}D)
                </Text>
                <Text variant="sm">
                  {item?.cost?.min}{' '}
                  {item?.cost?.max ? ' - ' + item?.cost?.max : ''}
                </Text>
              </View>
            ) : null;
          })}
        </View>
      </View>
      <View className="py-4">
        <Text variant="md">Food Cost</Text>
        <View className="mt-2 divide-y-[1px] divide-[cornflowerblue] rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 px-2 dark:bg-themeblue-500">
          {data?.foodCost?.map((item) => {
            return item?.cost?.min ? (
              <View className="flex-row justify-between py-2">
                <Text variant="sm">{item?.name}</Text>
                <Text variant="sm">
                  {item?.cost?.min}{' '}
                  {item?.cost?.max ? ' - ' + item?.cost?.max : ''}
                </Text>
              </View>
            ) : null;
          })}
        </View>
      </View>
      <View className="py-4">
        <Text variant="md">Itinerary Cost</Text>
        <View className="mt-2 divide-y-[1px] divide-[cornflowerblue] rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 px-2 dark:bg-themeblue-500">
          {data?.itineraryCost?.map((item) => {
            return item?.cost?.min ? (
              <View className="flex-row justify-between py-2">
                <Text variant="sm">{item?.name}</Text>
                <Text variant="sm">
                  {item?.cost?.min}{' '}
                  {item?.cost?.max ? ' - ' + item?.cost?.max : ''}
                </Text>
              </View>
            ) : null;
          })}
        </View>
      </View>
      <View className="py-4">
        <Text variant="md">Other Cost</Text>
        <View className="mt-2 divide-y-[1px] divide-[cornflowerblue] rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 px-2 dark:bg-themeblue-500">
          {data?.checklistCost?.map((item) => {
            return item?.cost?.min ? (
              <View className="flex-row justify-between py-2">
                <Text variant="sm">{item?.name}</Text>
                <Text variant="sm">
                  {item?.cost?.min}{' '}
                  {item?.cost?.max ? ' - ' + item?.cost?.max : ''}
                </Text>
              </View>
            ) : null;
          })}
        </View>
      </View>
      <View className="flex-row justify-between">
        <Text variant="xl">Total Cost</Text>
        <Text variant="xl">
          {minCost} - {maxCost}
        </Text>
      </View>
      <View className="pb-16" />
    </ScrollView>
  );
};
