import { useNavigation } from '@react-navigation/native';
import { useColorScheme } from 'nativewind';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';

import { useMyTripInfo } from '@/api';
import {
  colors,
  FocusAwareStatusBar,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
} from '@/ui';
import { ArrowRight, Copy, Rate } from '@/ui/icons';

const { width } = Dimensions.get('window');
const maxLength = 1000;

//@ts-ignore
export const MyTripDetailInfo = ({ route }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { navigate } = useNavigation();
  const { data, isLoading, isError } = useMyTripInfo({
    variables: { id: route.params.id },
  });

  const { colorScheme } = useColorScheme();
  const iconColor =
    colorScheme === 'dark' ? colors.neutral[400] : colors.neutral[500];

  const [showFullText, setShowFullText] = React.useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }
  //@ts-ignore
  const handleScroll = (event) => {
    const offset = event.nativeEvent.contentOffset.x;
    const index = Math.round(offset / width);
    setCurrentIndex(index);
  };
  return (
    <ScrollView className="relative block overflow-scroll bg-white p-2 shadow-xl dark:bg-themeblue-600">
      <View className="mb-2 rounded-xl border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 p-2 dark:bg-themeblue-500">
        <View className="flex flex-row items-center justify-between">
          <Text variant="h1">{data?.originalTrip?.name}</Text>
          <View className="flex flex-row items-center justify-between  border-black p-2">
            <View className="flex-row items-center">
              <Rate color={iconColor} />
              <Text variant="xs" className="pl-2">
                {data?.originalTrip?.ratings}
              </Text>
            </View>
            <View className="flex-row items-center pl-2">
              <Copy color={iconColor} />
              <Text variant="xs" className="pl-2">
                {data?.clones}
              </Text>
            </View>
          </View>
        </View>

        <Text variant="sm">
          Locations: {data?.originalTrip?.cities?.join(', ')}
        </Text>
        <Text variant="xs" numberOfLines={showFullText ? undefined : 2}>
          {showFullText
            ? data?.originalTrip?.descriptions
            : `${data?.originalTrip?.descriptions.slice(0, maxLength)}...`}
        </Text>
        <TouchableOpacity onPress={toggleShowFullText}>
          <Text variant="xs">{showFullText ? 'Read less' : 'Read more'}</Text>
        </TouchableOpacity>
        <View className="flex-row flex-wrap">
          {data?.originalTrip?.tags?.map((item) => {
            return (
              <View className="m-1 rounded-md bg-themeblue-400 px-2  py-1">
                <Text variant="xs" className="text-white">
                  {item}
                </Text>
              </View>
            );
          })}
        </View>
        <Text className="pt-1" variant="sm">
          Trip Members: {data?.members?.min} Persons
        </Text>
        <Text className="pt-1" variant="sm">
          Suggested Time of Visit:{' '}
          {data?.originalTrip?.bestMonths?.start +
            ' - ' +
            data?.originalTrip?.bestMonths?.end}
        </Text>
        <Text className="pt-1" variant="sm">
          Supported SIM Network:{' '}
          {data?.originalTrip?.supportedNetworks?.join(', ')}
        </Text>
      </View>
      <View className="mb-4">
        <Text variant="xl" className="p-2">
          Explore
        </Text>
        <ScrollView
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          onScroll={handleScroll}
          scrollEventThrottle={500}
          nestedScrollEnabled={true}
        >
          {/* @ts-ignore*/}
          {data?.originalTrip?.photos?.map((image, index) => (
            <Image
              key={index}
              source={{ uri: image }} //@ts-ignore
              style={styles.image}
              contentFit="cover"
            />
          ))}
        </ScrollView>
      </View>

      <Text variant="xl" className="mb-2">
        Government Rules
      </Text>
      <View className="mb-2 rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-100 p-2 dark:bg-themeblue-500">
        <Text className="pt-1" variant="md">
          {data?.originalTrip?.govtguidelines
            ?.map((str, index) => `${index + 1}. ${str}`)
            ?.join('\n')}
        </Text>
      </View>
      <Text variant="xl" className="mb-2">
        Transit Support & Avg Fares
      </Text>
      <View className="mb-2 rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-100 p-2 dark:bg-themeblue-500">
        <View className="divide-y-[1px] divide-[cornflowerblue]">
          {data?.originalTrip?.supportedTransit?.map((item) => {
            return (
              <View className="flex flex-row items-center justify-between">
                <View className="flex flex-col">
                  <Text variant="md">{item.name}</Text>
                  <Text variant="md">{item.availability}</Text>
                </View>
                <Text variant="md">
                  {item.price.min}{' '}
                  {item.price.max ? ' - ' + item.price.max : ''}
                  {' INR'}
                  {/* {item.fares.currency} */}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
      <Text variant="xl" className="mb-2">
        Food Options
      </Text>

      <View className="mb-2 rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-100 p-2 dark:bg-themeblue-500">
        <Text variant="md">
          {data?.originalTrip?.supportedFoodChains?.join(', ')}
        </Text>
      </View>
      <Text variant="xl" className="mb-2">
        Special Notes & Suggestions
      </Text>

      <View className="mb-2 rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-100 p-2 dark:bg-themeblue-500">
        <Text variant="md">
          {data?.originalTrip?.specialNote
            ?.map((str, index) => `${index + 1}. ${str}`)
            ?.join('\n')}
        </Text>
      </View>
      <Text variant="xl" className="mb-2">
        Trip Creator
      </Text>

      <Pressable
        className="mb-2 flex flex-row items-center justify-between rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-100 p-2 dark:bg-themeblue-500"
        onPress={() => navigate('Profile', { id: data?.creator?.id })}
      >
        <Text variant="md">{data?.creator?.name}</Text>
        <ArrowRight color={iconColor} />
      </Pressable>
      <View className="pb-16" />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 162, //width / 2 - 15,
    height: 288, // Set your desired height
    // resizeMode: 'cover',
    //@ts-ignore
    margin: '5px',
    borderRadius: 12,
  },
});
