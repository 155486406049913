import React from 'react';
import { showMessage } from 'react-native-flash-message';

import { useAuthRegister } from '@/api';
import { useAuth } from '@/core';
import { useSoftKeyboardEffect } from '@/core/keyboard';
import { FocusAwareStatusBar } from '@/ui';

import type { LoginFormProps } from './register-form';
import { RegisterForm } from './register-form';

export const Register = () => {
  const signIn = useAuth.use.signIn();
  const { mutate: apiRegister, isLoading } = useAuthRegister();
  useSoftKeyboardEffect();

  const onSubmit: LoginFormProps['onSubmit'] = (data) => {
    apiRegister(
      { ...data },
      {
        onSuccess: (res) => {
          showMessage({
            message: res?.message,
            type: 'success',
          });
          signIn({ access: res?.token, refresh: 'refresh-token' });

          // here you can navigate to the post list and refresh the list data
          //queryClient.invalidateQueries(usePosts.getKey());
        },
        onError: (res) => {
          //@ts-ignore
          showErrorMessage(res?.response?.data?.message);
        },
      }
    );
    // signIn({ access: 'access-token', refresh: 'refresh-token' });
  };
  return (
    <>
      <FocusAwareStatusBar />
      <RegisterForm onSubmit={onSubmit} disable={isLoading} />
    </>
  );
};
