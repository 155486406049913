import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { VerifySuccess } from './types';

type Variables = {
  email: string;
  otp: string;
};
type Response = VerifySuccess;

export const useCheckMailOTP = createMutation<Response, Variables, AxiosError>({
  mutationFn: async (variables) => {
    return clientWithAuthHeader({
      url: `verify/checkMailOTP`,
      method: 'POST',
      data: variables,
    }).then((response) => response.data);
  },
});
