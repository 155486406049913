import { useNavigation } from '@react-navigation/native';
// import { FlashList } from '@shopify/flash-list';
// import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import React, { useEffect } from 'react';
import { RefreshControl } from 'react-native';

import { useTrips } from '@/api';
import { getItem } from '@/core/storage';
import {
  Button,
  EmptyList,
  ScrollView,
  // EmptyList, FocusAwareStatusBar,
  Text,
  View,
} from '@/ui';

import { Card } from './card';

export const Home = () => {
  const { navigate } = useNavigation();
  const [activeTag, setActiveTag] = React.useState(0);
  const { data, isLoading, isError, refetch } = useTrips();

  const [tripData, setTripData] = React.useState(data?.reverse());

  useEffect(() => {
    const navigationToOriginalUrl = getItem('TRIP_URL');
    if (navigationToOriginalUrl) {
      //@ts-ignore
      navigate('TripDetail', { id: navigationToOriginalUrl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = React.useCallback(
    //  @ts-ignore
    ({ item }) => (
      <Card
        {...item}
        onPress={() =>
          // {
          //   console.log('click');
          // }
          navigate('TripDetail', { id: item.id })
        }
      />
    ),
    [navigate]
    // [navigate]
  );

  React.useEffect(() => {
    const results = data?.filter((item) =>
      item.tags.includes(
        activeTag === 0
          ? 'trending'
          : activeTag === 1
          ? 'cost-optimized'
          : activeTag === 2
          ? 'nature'
          : 'luxury'
      )
    );
    setTripData(results);
  }, [data, activeTag]);

  if (isError) {
    return (
      <View>
        <Text> Error Loading data </Text>
      </View>
    );
  }

  return (
    <>
      <ScrollView
        className="flex grow"
        refreshControl={
          <RefreshControl
            refreshing={isLoading} // Use isLoading state to control the refresh indicator
            onRefresh={() => refetch()}
          />
        }
      >
        {/* <View
          className={`flex-1 px-4 ${Platform.OS === 'ios' ? 'pt-16' : 'pt-4'} `}
        >
          <Text variant="lg" className="font-bold">
            Home
          </Text>
        </View> */}
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          className="flex grow-[0]"
        >
          <View className="mx-2">
            <Button
              label="Trending"
              variant={activeTag === 0 ? 'secondary' : 'outline'}
              onPress={() => setActiveTag(0)}
            />
          </View>
          <View className="mx-2">
            <Button
              label="Cost-Optimized"
              variant={activeTag === 1 ? 'secondary' : 'outline'}
              onPress={() => setActiveTag(1)}
            />
          </View>
          <View className="mx-2">
            <Button
              label="Nature"
              variant={activeTag === 2 ? 'secondary' : 'outline'}
              onPress={() => setActiveTag(2)}
            />
          </View>
          <View className="mx-2">
            <Button
              label="Luxury"
              variant={activeTag === 3 ? 'secondary' : 'outline'}
              onPress={() => setActiveTag(3)}
            />
          </View>
        </ScrollView>
        <FlashList
          data={tripData}
          numColumns={2}
          renderItem={renderItem}
          keyExtractor={(_, index) => `item-${index}`}
          ListEmptyComponent={<EmptyList isLoading={isLoading} />}
          estimatedItemSize={199}
        />
      </ScrollView>
    </>
  );
};
