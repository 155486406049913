import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { TripItineraryUpdate } from './types';

type Variables = {
  clonedItineraryId: string;
  status: string;
};
type Response = TripItineraryUpdate;

export const useTripUpdateItinerary = createMutation<
  Response,
  Variables,
  AxiosError
>({
  mutationFn: async (variables) =>
    clientWithAuthHeader({
      url: `myTrip/update/itinerary`,
      method: 'POST',
      data: variables,
    }).then((response) => response.data),
});
