import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useColorScheme } from 'nativewind';
import * as React from 'react';

import { colors } from '@/ui';

import { NearbyNavigator } from './nearby-navigator';

export type MyTripsStackParamList = {
  // MyTrips: undefined;
  Nearby: undefined;
};

const Stack = createNativeStackNavigator<MyTripsStackParamList>();

// const GoToAddPost = () => {
//   return (
//     <Pressable onPress={() => {}} className="p-2">
//       <Text className="text-primary-300">Create</Text>
//     </Pressable>
//   );
// };

type Props = {
  navigation: any;
  route: any;
};

export const NearbyPageNavigator = ({ navigation, route }: Props) => {
  const { colorScheme } = useColorScheme();
  React.useLayoutEffect(() => {}, [navigation, route]);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle:
          colorScheme === 'dark'
            ? { backgroundColor: colors.themeblue[500] }
            : { backgroundColor: colors.white },
      }}
    >
      {/* <Stack.Group
        screenOptions={{
          // eslint-disable-next-line react/no-unstable-nested-components
          headerRight: () => <GoToAddPost />,
        }}
      >
        <Stack.Screen name="HomeScreen" component={Home} />
      </Stack.Group> */}

      {/* <Stack.Screen name="MyTrips" component={MyTrips} /> */}
      <Stack.Screen
        name="Nearby"
        options={{ headerLeft: () => null }}
        component={NearbyNavigator}
      />
    </Stack.Navigator>
  );
};
