import type { AxiosError } from 'axios';
import { createQuery } from 'react-query-kit';

import { clientWithAuthHeader } from '../common';
import type { Payment } from './types';

type Response = Payment;
type Variables = { tripId: string | undefined }; // as react-query-kit is strongly typed, we need to specify the type of the variables as void in case we don't need them

export const usePaymentLink = createQuery<Response, Variables, AxiosError>({
  primaryKey: 'tripId', // we recommend using  endpoint base url as primaryKey
  queryFn: ({ queryKey: [primaryKey, variables] }) => {
    // in case if variables is needed, we can use destructuring to get it from queryKey array like this: ({ queryKey: [primaryKey, variables] })
    // primaryKey is 'posts' in this case
    return clientWithAuthHeader
      .get(`/pay/pay?${primaryKey}=${variables?.tripId}`)
      .then((response) => response.data);
  },
  enabled: false, // Prevent automatic fetching
});
