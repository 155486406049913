import { FlashList } from '@shopify/flash-list';
import React from 'react';

import { useMyTripHotel } from '@/api';
import {
  ActivityIndicator,
  FocusAwareStatusBar,
  ScrollView,
  Text,
  View,
} from '@/ui';

import { DaywiseDropdown } from './daywise-dropdown';

//@ts-ignore
export const MyTripDetailStays = ({ route }) => {
  const { data, isLoading, isError } = useMyTripHotel({
    variables: { id: route.params.id },
  });

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }
  const organizedItinerary = data?.hotelItems?.reduce((acc, item) => {
    //@ts-ignore
    if (!acc[item.dayNumber]) {
      //@ts-ignore
      acc[item.dayNumber] = [];
    }
    //@ts-ignore
    acc[item.dayNumber].push(item);
    return acc;
  }, {});
  //@ts-ignore
  const renderDaySection = ({ item }) => (
    <DaywiseDropdown item={item} bookingType={route?.params?.bookingType} />
  );

  return (
    <ScrollView className="bg-white dark:bg-themeblue-600">
      <FlashList
        data={Object.keys(organizedItinerary).map((dayNumber) => ({
          day: parseInt(dayNumber, 10),
          //@ts-ignore
          data: organizedItinerary[dayNumber],
        }))}
        keyExtractor={(item) => item.day.toString()}
        renderItem={renderDaySection}
      />
      <View className="pb-16" />
    </ScrollView>
  );
};
