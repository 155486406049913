import Slider from '@react-native-community/slider';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native'; //@ts-ignore

import { useSearchLocation } from '@/api';
import { getCurrentLocation } from '@/core';
import {
  ActivityIndicator,
  colors,
  EmptyList,
  ScrollView,
  Text,
  View,
} from '@/ui';

import { LocationCard } from './location-card';

// const tempData = [
//   {
//     title: 'Nationals Bar',
//     id: 'here:pds:place:356tek9g-ba5db2a8a996da403d3c276fa4d9b2e1',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Nationals Bar, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58994,
//       lng: 73.99679,
//     },
//     access: [
//       {
//         lat: 18.59001,
//         lng: 73.99692,
//       },
//     ],
//     distance: 213,
//     categories: [
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shinde Park',
//     id: 'here:pds:place:356tek9g-ff6a5eca5958fe3983ca017fa1290774',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Shinde Park, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59281,
//       lng: 73.99912,
//     },
//     access: [
//       {
//         lat: 18.59274,
//         lng: 73.99924,
//       },
//     ],
//     distance: 473,
//     categories: [
//       {
//         id: '550-5510-0202',
//         name: 'Park-Recreation Area',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224404535',
//       },
//     ],
//   },
//   {
//     title: 'Ganesh Mandir',
//     id: 'here:pds:place:356jx7ps-81b2622e07980dcf821e7e0fa246ae5d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Ganesh Mandir, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59206,
//       lng: 74.00252,
//     },
//     access: [
//       {
//         lat: 18.59211,
//         lng: 74.00267,
//       },
//     ],
//     distance: 794,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144027542',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356jx7ps-1dd7f55b4e8405d9865dd492718334b9',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Mandir, Jagtap Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jagtap Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59866,
//       lng: 73.99568,
//     },
//     access: [
//       {
//         lat: 18.59881,
//         lng: 73.99569,
//       },
//     ],
//     distance: 859,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138029691',
//       },
//     ],
//   },
//   {
//     title: 'Samuraj Park',
//     id: 'here:pds:place:356tek9g-34a8671b733b025a847998ba5d671c3d',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Samuraj Park, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59573,
//       lng: 74.00168,
//     },
//     access: [
//       {
//         lat: 18.59581,
//         lng: 74.00157,
//       },
//     ],
//     distance: 875,
//     categories: [
//       {
//         id: '550-5510-0202',
//         name: 'Park-Recreation Area',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Charmi Bar',
//     id: 'here:pds:place:356tek9g-19af0abbd850dc46d5e3e901fa304248',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Charmi Bar, Kopargaon Shirdi Ahmednagar Pune Highway, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59609,
//       lng: 74.00203,
//     },
//     access: [
//       {
//         lat: 18.59619,
//         lng: 74.00193,
//       },
//     ],
//     distance: 928,
//     categories: [
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Kalubai Mandir',
//     id: 'here:pds:place:356jx7ps-eaf167325da701c5ade95d850a02292f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Kalubai Mandir, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59363,
//       lng: 74.00374,
//     },
//     access: [
//       {
//         lat: 18.5936,
//         lng: 74.00359,
//       },
//     ],
//     distance: 960,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138029698',
//       },
//     ],
//   },
//   {
//     title: 'Baba Hardas Ram',
//     id: 'here:pds:place:356jx7ps-917c785456c802c956f742ea1241b476',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Baba Hardas Ram, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58724,
//       lng: 74.00598,
//     },
//     access: [
//       {
//         lat: 18.58734,
//         lng: 74.00611,
//       },
//     ],
//     distance: 1221,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1142348580',
//       },
//     ],
//   },
//   {
//     title: 'Shri Datt Mandir',
//     id: 'here:pds:place:356jx7ps-09f6d9d5a9380e0fed5f469fbe1d4fd0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Datt Mandir, Sant Tukaram Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sant Tukaram Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58292,
//       lng: 73.98705,
//     },
//     access: [
//       {
//         lat: 18.58291,
//         lng: 73.98691,
//       },
//     ],
//     distance: 1231,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570158',
//       },
//     ],
//   },
//   {
//     title: 'Shri Tolda Mandir',
//     id: 'here:pds:place:356tek9g-241441fef84b85e27cba4a79f846d4e7',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Shri Tolda Mandir, Jagtap Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jagtap Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60001,
//       lng: 74.0029,
//     },
//     access: [
//       {
//         lat: 18.60001,
//         lng: 74.00289,
//       },
//     ],
//     distance: 1301,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224615578',
//       },
//     ],
//   },
//   {
//     title: 'Fun Square Cinema',
//     id: 'here:pds:place:356jx7ps-64893141af660a852554817c1be45dc2',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Fun Square Cinema, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58013,
//       lng: 73.98947,
//     },
//     access: [
//       {
//         lat: 18.58014,
//         lng: 73.98942,
//       },
//     ],
//     distance: 1342,
//     categories: [
//       {
//         id: '200-2100-0019',
//         name: 'Cinema',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097419871',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912114222072',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Akshay Garden Family Restro & Bar',
//     id: 'here:pds:place:356jx7ps-dbefa3f838040e6aa73e3c3b321ca729',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Akshay Garden Family Restro & Bar, SH-60, Sant Tukaram Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sant Tukaram Nagar',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58185,
//       lng: 73.98673,
//     },
//     access: [
//       {
//         lat: 18.58171,
//         lng: 73.9868,
//       },
//     ],
//     distance: 1342,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097420810',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981195',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224735598',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912027052642',
//           },
//           {
//             value: '+919763311386',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//         mobile: [
//           {
//             value: '+918149124513',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//           {
//             value: '+919765759617',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//         www: [
//           {
//             value: 'http://www.dtdc.com',
//             categories: [
//               {
//                 id: '100-1000-0000',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '100-1000-0000',
//           },
//         ],
//         text: ['Mon-Sun: 09:00 - 21:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T090000',
//             duration: 'PT12H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Supriya',
//     id: 'here:pds:place:356tek9e-5665402649fde98907b83a230f9961a8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Supriya, Keshnand Theur Road, Jeje Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jeje Nagar',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57921,
//       lng: 73.99208,
//     },
//     access: [
//       {
//         lat: 18.57909,
//         lng: 73.99198,
//       },
//     ],
//     distance: 1344,
//     categories: [
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//         primary: true,
//       },
//       {
//         id: '500-5000-0053',
//         name: 'Hotel',
//       },
//       {
//         id: '500-5100-0059',
//         name: 'Holiday Park',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174731936',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '21491521',
//       },
//     ],
//     contacts: [
//       {
//         www: [
//           {
//             value: 'https://www.oyorooms.com/104645',
//             categories: [
//               {
//                 id: '500-5100-0059',
//               },
//             ],
//           },
//           {
//             value:
//               'https://www.oyorooms.com/104645-spot-on-spot-on-74501-hotel-supriya-garden-pune',
//             categories: [
//               {
//                 id: '500-5000-0053',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '500-5000-0053',
//           },
//         ],
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Aarohi Park',
//     id: 'here:pds:place:356tek9g-280a1bcd1cec468ff5ea65b613034e59',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Aarohi Park, Jagtap Wasti Road, Jagtap Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jagtap Wasti',
//       street: 'Jagtap Wasti Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60272,
//       lng: 73.99822,
//     },
//     access: [
//       {
//         lat: 18.60272,
//         lng: 73.99823,
//       },
//     ],
//     distance: 1349,
//     categories: [
//       {
//         id: '550-5510-0202',
//         name: 'Park-Recreation Area',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224414547',
//       },
//     ],
//   },
//   {
//     title: 'Family Resto Bar',
//     id: 'here:pds:place:356tek9u-52eeb0b7a9e81126664872668ac84c29',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Family Resto Bar, Bokari Road, Gade Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gade Wasti',
//       street: 'Bokari Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59241,
//       lng: 74.00784,
//     },
//     access: [
//       {
//         lat: 18.59228,
//         lng: 74.00787,
//       },
//     ],
//     distance: 1354,
//     categories: [
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Vitthal Temple',
//     id: 'here:pds:place:356jx7ps-993da00878fc07df0589fc72081ebbcb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Vitthal Temple, Keshnand Theur Road, Jeje Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jeje Nagar',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57896,
//       lng: 73.99188,
//     },
//     access: [
//       {
//         lat: 18.57909,
//         lng: 73.99198,
//       },
//     ],
//     distance: 1376,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097419683',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174734774',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174734780',
//       },
//     ],
//   },
//   {
//     title: 'Tukai Mata Mandir',
//     id: 'here:pds:place:356tek9e-6e6d18a50996a39ff9caee1c715476c9',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Tukai Mata Mandir, Keshnand Theur Road, Jeje Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jeje Nagar',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57895,
//       lng: 73.99168,
//     },
//     access: [
//       {
//         lat: 18.57918,
//         lng: 73.99185,
//       },
//     ],
//     distance: 1383,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shiv Mandir',
//     id: 'here:pds:place:356tek9e-606e76cb61843a650478533806be806e',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Shiv Mandir, Mukhai Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Mukhai Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57879,
//       lng: 73.992,
//     },
//     access: [
//       {
//         lat: 18.57875,
//         lng: 73.99209,
//       },
//     ],
//     distance: 1392,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Devsthan Mandir',
//     id: 'here:pds:place:356jx7ps-c2fb168136500245716c92186b3832d7',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Devsthan Mandir, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59422,
//       lng: 74.00809,
//     },
//     access: [
//       {
//         lat: 18.59416,
//         lng: 74.00794,
//       },
//     ],
//     distance: 1418,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138029690',
//       },
//     ],
//   },
//   {
//     title: 'Shivnari Resto & Bar',
//     id: 'here:pds:place:356tek9e-dff8b6431148416e8d841948ae60537e',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shivnari Resto & Bar, Sant Tukaram Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sant Tukaram Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58152,
//       lng: 73.98573,
//     },
//     access: [
//       {
//         lat: 18.58153,
//         lng: 73.9859,
//       },
//     ],
//     distance: 1440,
//     categories: [
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981431',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224224917',
//       },
//     ],
//   },
//   {
//     title: 'Shree Ganesh Mandir',
//     id: 'here:pds:place:356tek9e-4f437586f2644be4d3a348750289d706',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shree Ganesh Mandir, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58515,
//       lng: 73.98276,
//     },
//     access: [
//       {
//         lat: 18.58511,
//         lng: 73.98279,
//       },
//     ],
//     distance: 1450,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//       {
//         id: '600-6900-0096',
//         name: 'Specialty Store',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097422081',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224224552',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224224573',
//       },
//     ],
//   },
//   {
//     title: 'Shri Datta Mandir',
//     id: 'here:pds:place:356tek9e-ee9b163b97278e4935279f8c1bebc2e2',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Datta Mandir, Old Avhalwadi Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Old Avhalwadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58071,
//       lng: 73.98604,
//     },
//     access: [
//       {
//         lat: 18.58072,
//         lng: 73.9861,
//       },
//     ],
//     distance: 1486,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Kalubai Mandir',
//     id: 'here:pds:place:356jx7ps-4309ff3ec2220ae5619d23f80ca6fa0c',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Kalubai Mandir, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5835,
//       lng: 73.98325,
//     },
//     access: [
//       {
//         lat: 18.58365,
//         lng: 73.98323,
//       },
//     ],
//     distance: 1498,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1142348600',
//       },
//     ],
//   },
//   {
//     title: 'Yamai Mata Devsthan',
//     id: 'here:pds:place:356tek9e-c9845a2eb6ce3632d5dab2fdabc35563',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Yamai Mata Devsthan, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57877,
//       lng: 73.98846,
//     },
//     access: [
//       {
//         lat: 18.57878,
//         lng: 73.98839,
//       },
//     ],
//     distance: 1525,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224576209',
//       },
//     ],
//   },
//   {
//     title: 'Shani Mandir',
//     id: 'here:pds:place:356jx7ps-a764ca75d6c7080c840e1dfc7010ac0a',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shani Mandir, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58474,
//       lng: 73.98207,
//     },
//     access: [
//       {
//         lat: 18.58459,
//         lng: 73.98212,
//       },
//     ],
//     distance: 1536,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097421922',
//       },
//     ],
//   },
//   {
//     title: 'Shree Shiv Mandir',
//     id: 'here:pds:place:356jx7ps-9d99a75be8f90b79172759350e109780',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shree Shiv Mandir, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58474,
//       lng: 73.98207,
//     },
//     access: [
//       {
//         lat: 18.58476,
//         lng: 73.98212,
//       },
//     ],
//     distance: 1536,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097421927',
//       },
//     ],
//   },
//   {
//     title: 'Jama Masjid',
//     id: 'here:pds:place:356jx7ps-e5c4c651fbaa0c4e526c8f3b185a3485',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Jama Masjid, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58447,
//       lng: 73.98195,
//     },
//     access: [
//       {
//         lat: 18.58447,
//         lng: 73.98212,
//       },
//     ],
//     distance: 1561,
//     categories: [
//       {
//         id: '300-3200-0034',
//         name: 'Mosque',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097421924',
//       },
//     ],
//   },
//   {
//     title: 'Shri Bhairavnath Mandir',
//     id: 'here:pds:place:356jx7ps-dcbe10ffaeec08b75472797cd45309a5',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Bhairavnath Mandir, Wagholi Gayran, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gayran',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5856,
//       lng: 73.98091,
//     },
//     access: [
//       {
//         lat: 18.58545,
//         lng: 73.98097,
//       },
//     ],
//     distance: 1608,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097424524',
//       },
//     ],
//   },
//   {
//     title: 'Lumbini Budha Vihar',
//     id: 'here:pds:place:356jx7ps-044b41391eb70aa4cbb9f721460d0eb1',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Lumbini Budha Vihar, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58199,
//       lng: 73.9831,
//     },
//     access: [
//       {
//         lat: 18.58203,
//         lng: 73.98327,
//       },
//     ],
//     distance: 1609,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//       {
//         id: '300-3200-0309',
//         name: 'Other Place of Worship',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1142348576',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1173731486',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174480936',
//       },
//     ],
//   },
//   {
//     title: 'Ganesh Temple',
//     id: 'here:pds:place:356jx7ps-9e4fad677b1d0d84d109038963a1d2fd',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Ganesh Temple, Bhawdi Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Bhawdi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60378,
//       lng: 73.98786,
//     },
//     access: [
//       {
//         lat: 18.60377,
//         lng: 73.98802,
//       },
//     ],
//     distance: 1616,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1098545096',
//       },
//     ],
//   },
//   {
//     title: 'Hotel Ruchira',
//     id: 'here:pds:place:356jx7ps-96e7e4f869f60d8b1e698d561f619bd4',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hotel Ruchira, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58087,
//       lng: 73.98363,
//     },
//     access: [
//       {
//         lat: 18.58092,
//         lng: 73.98379,
//       },
//     ],
//     distance: 1648,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147980895',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147980898',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919822328875',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Shri Sant Tukaram Maharaj Mandir',
//     id: 'here:pds:place:356jx7ps-4db6a45a3d730b4bdc2b16b6058ac34d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Sant Tukaram Maharaj Mandir, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58201,
//       lng: 73.98256,
//     },
//     access: [
//       {
//         lat: 18.58201,
//         lng: 73.9824,
//       },
//     ],
//     distance: 1653,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097419512',
//       },
//     ],
//   },
//   {
//     title: 'Shri Sant Gora Kumbhar Mandir',
//     id: 'here:pds:place:356jx7ps-4e830bb4355e08867d07e281f9d17986',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Sant Gora Kumbhar Mandir, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58485,
//       lng: 73.98078,
//     },
//     access: [
//       {
//         lat: 18.58498,
//         lng: 73.98069,
//       },
//     ],
//     distance: 1653,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1142348584',
//       },
//     ],
//   },
//   {
//     title: 'Gauspak Baba Dargah',
//     id: 'here:pds:place:356jx7ps-cbaaba61aa2c015c76cb163e32589b27',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Gauspak Baba Dargah, Wagholi Gaon, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58471,
//       lng: 73.98061,
//     },
//     access: [
//       {
//         lat: 18.58482,
//         lng: 73.98049,
//       },
//     ],
//     distance: 1676,
//     categories: [
//       {
//         id: '300-3200-0034',
//         name: 'Mosque',
//         primary: true,
//       },
//       {
//         id: '300-3200-0309',
//         name: 'Other Place of Worship',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1142348650',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356tek9e-e8e2fb51f53bc7206e4390333a0a5aa9',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Ganesh Mandir, Wagholi Gaon-Burunjwadi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gaon-Burunjwadi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58396,
//       lng: 73.97982,
//     },
//     access: [
//       {
//         lat: 18.58381,
//         lng: 73.97985,
//       },
//     ],
//     distance: 1787,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097420877',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356jx7ps-c93b9f826be10abb3f364e1df411ab3c',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Mandir, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59421,
//       lng: 73.97844,
//     },
//     access: [
//       {
//         lat: 18.59425,
//         lng: 73.97858,
//       },
//     ],
//     distance: 1791,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144026520',
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Peer Baba Mandir',
//     id: 'here:pds:place:356jx7ps-a02741158b6700baad8a84e1671212b0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Peer Baba Mandir, Wagholi Gayran, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gayran',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58627,
//       lng: 73.9787,
//     },
//     access: [
//       {
//         lat: 18.58623,
//         lng: 73.97854,
//       },
//     ],
//     distance: 1803,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144026535',
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Gurukrupa Sevashram',
//     id: 'here:pds:place:356jx7ps-e84b8bcc283e01c75087395bfa798340',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Gurukrupa Sevashram, Gurukrupa Sevashram, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Gurukrupa Sevashram',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58737,
//       lng: 74.01184,
//     },
//     access: [
//       {
//         lat: 18.58743,
//         lng: 74.01197,
//       },
//     ],
//     distance: 1811,
//     categories: [
//       {
//         id: '300-3200-0033',
//         name: 'Ashram',
//         primary: true,
//       },
//       {
//         id: '300-3200-0000',
//         name: 'Religious Place',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176565225',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+912032902689',
//           },
//         ],
//         mobile: [
//           {
//             value: '+919371015251',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Dargah Sharif',
//     id: 'here:pds:place:356jx7ps-207179e64cbd0859b99f51aa1212ead7',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Dargah Sharif, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58027,
//       lng: 73.98202,
//     },
//     access: [
//       {
//         lat: 18.58023,
//         lng: 73.98219,
//       },
//     ],
//     distance: 1819,
//     categories: [
//       {
//         id: '300-3200-0034',
//         name: 'Mosque',
//         primary: true,
//       },
//       {
//         id: '300-3200-0309',
//         name: 'Other Place of Worship',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981328',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Temple',
//     id: 'here:pds:place:356jx7ps-8ec13cfffc9d075a6d826a4b03f6ab03',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Temple, Anand Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Anand Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57992,
//       lng: 73.98199,
//     },
//     access: [
//       {
//         lat: 18.57989,
//         lng: 73.98183,
//       },
//     ],
//     distance: 1847,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097419178',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097420285',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356tek9e-c4af87b644768577c11ad1967c4c380f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Ganesh Mandir, Wagholi Gayran, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gayran',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58705,
//       lng: 73.97774,
//     },
//     access: [
//       {
//         lat: 18.58694,
//         lng: 73.97763,
//       },
//     ],
//     distance: 1879,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144026532',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144387071',
//       },
//     ],
//   },
//   {
//     title: 'Sai Mandir',
//     id: 'here:pds:place:356tek9e-3f743f75fdd2e6ac789d86c9e8574a41',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Sai Mandir, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57931,
//       lng: 73.98216,
//     },
//     access: [
//       {
//         lat: 18.57931,
//         lng: 73.98214,
//       },
//     ],
//     distance: 1879,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356tek9g-191e8fe6c6e9082ac9b99611330b5700',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Mandir, Jagtap Wasti, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Jagtap Wasti',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60787,
//       lng: 73.99785,
//     },
//     access: [
//       {
//         lat: 18.60792,
//         lng: 73.9977,
//       },
//     ],
//     distance: 1903,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138029696',
//       },
//     ],
//   },
//   {
//     title: 'Maruti Mandir',
//     id: 'here:pds:place:356tek9e-e0b0c67971a3a573843db0c528e858fd',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Maruti Mandir, Wagholi Gayran, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Wagholi Gayran',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58697,
//       lng: 73.97749,
//     },
//     access: [
//       {
//         lat: 18.58701,
//         lng: 73.97751,
//       },
//     ],
//     distance: 1906,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Kolhapur Mahalaxmi Mata Mandir',
//     id: 'here:pds:place:356jx7ps-08eae8225da50d61509371333ca48a9a',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Kolhapur Mahalaxmi Mata Mandir, SH-60, Anand Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Anand Nagar',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5806,
//       lng: 73.98058,
//     },
//     access: [
//       {
//         lat: 18.58074,
//         lng: 73.98059,
//       },
//     ],
//     distance: 1914,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147980862',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Temple',
//     id: 'here:pds:place:356tek9e-024f4fb6af4092e01c5461452ac71e20',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Temple, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57773,
//       lng: 73.98331,
//     },
//     access: [
//       {
//         lat: 18.57774,
//         lng: 73.98315,
//       },
//     ],
//     distance: 1924,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418523',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ram Mandir',
//     id: 'here:pds:place:356jx7ps-d35910b839a3095262d62c1b400b0866',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ram Mandir, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59609,
//       lng: 73.97755,
//     },
//     access: [
//       {
//         lat: 18.59603,
//         lng: 73.97767,
//       },
//     ],
//     distance: 1934,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144026519',
//       },
//     ],
//     openingHours: [
//       {
//         text: ['Mon-Fri, Sun: 00:00 - 24:00'],
//         isOpen: false,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Pune Nagar Wagholi',
//     id: 'here:pds:place:356tek9e-62a49de9726b4bdf850feb243d54863b',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Pune Nagar Wagholi, Kopargaon Shirdi Ahmednagar Pune Highway, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       street: 'Kopargaon Shirdi Ahmednagar Pune Highway',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5806,
//       lng: 73.98028,
//     },
//     access: [
//       {
//         lat: 18.58073,
//         lng: 73.98028,
//       },
//     ],
//     distance: 1939,
//     categories: [
//       {
//         id: '300-3000-0000',
//         name: 'Landmark-Attraction',
//         primary: true,
//       },
//       {
//         id: '100-1000-0009',
//         name: 'Fast Food',
//       },
//     ],
//   },
//   {
//     title: 'Shree Matoba Mandir',
//     id: 'here:pds:place:356jx7ps-72b76c9bac5c045d6a1eaa8710632686',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shree Matoba Mandir, SH-60, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5806,
//       lng: 73.98017,
//     },
//     access: [
//       {
//         lat: 18.58072,
//         lng: 73.98016,
//       },
//     ],
//     distance: 1948,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147980891',
//       },
//     ],
//   },
//   {
//     title: 'Place of Worship',
//     id: 'here:pds:place:356jx7ps-1a37baa0fa9c0960c200592d224d6e41',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Place of Worship, Keshnand Theur Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57633,
//       lng: 74.00543,
//     },
//     access: [
//       {
//         lat: 18.57648,
//         lng: 74.0055,
//       },
//     ],
//     distance: 1959,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174732501',
//       },
//     ],
//   },
//   {
//     title: 'Mhasoba Temple',
//     id: 'here:pds:place:356jx7ps-fac16106b6be0702ef0f9f2dc255d3cb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Mhasoba Temple, Suyog Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Suyog Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60178,
//       lng: 73.98015,
//     },
//     access: [
//       {
//         lat: 18.60165,
//         lng: 73.98024,
//       },
//     ],
//     distance: 1981,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144026522',
//       },
//     ],
//   },
//   {
//     title: 'One Church Pune Wagholi',
//     id: 'here:pds:place:356tek9e-c3365718c2bd1771e57d5f2364a19f27',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'One Church Pune Wagholi, Sambhaji Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sambhaji Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58023,
//       lng: 73.97984,
//     },
//     access: [
//       {
//         lat: 18.5801,
//         lng: 73.97979,
//       },
//     ],
//     distance: 2001,
//     categories: [
//       {
//         id: '300-3200-0030',
//         name: 'Church',
//         primary: true,
//       },
//       {
//         id: '300-3200-0000',
//         name: 'Religious Place',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1220727948',
//       },
//     ],
//   },
//   {
//     title: 'Ganpati Mandir',
//     id: 'here:pds:place:356tek9e-dd7521eb52ca677d0bcb74c6d61c97f4',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Ganpati Mandir, Anand Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Anand Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57849,
//       lng: 73.98121,
//     },
//     access: [
//       {
//         lat: 18.57862,
//         lng: 73.98122,
//       },
//     ],
//     distance: 2015,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Hanuman Temple',
//     id: 'here:pds:place:356jx7ps-52f51900a3400b6f892e15439246e0e3',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hanuman Temple, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60104,
//       lng: 73.97915,
//     },
//     access: [
//       {
//         lat: 18.6012,
//         lng: 73.9791,
//       },
//     ],
//     distance: 2019,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1144026513',
//       },
//     ],
//   },
//   {
//     title: 'Vitthal Rukmini Mandir',
//     id: 'here:pds:place:356jx7ps-0f8aeea0e3420258cae1f11d0c4ed773',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Vitthal Rukmini Mandir, SH-60, Vitthalwadi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Vitthalwadi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60175,
//       lng: 74.01054,
//     },
//     access: [
//       {
//         lat: 18.60184,
//         lng: 74.01044,
//       },
//     ],
//     distance: 2023,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097334101',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1166365261',
//       },
//     ],
//   },
//   {
//     title: 'Shri Vitthal Rukmini Mandir',
//     id: 'here:pds:place:356jx7ps-14c8569520ef0f73cddf488db7cf6290',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Vitthal Rukmini Mandir, Katkewadi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Katkewadi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60637,
//       lng: 74.00625,
//     },
//     access: [
//       {
//         lat: 18.60637,
//         lng: 74.00608,
//       },
//     ],
//     distance: 2079,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138004646',
//       },
//     ],
//   },
//   {
//     title: 'Jay Mata di Mandir',
//     id: 'here:pds:place:356jx7ps-f565f6a3b41c063bada833807d7c4b26',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Jay Mata di Mandir, Vitthalwadi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Vitthalwadi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.59721,
//       lng: 74.01371,
//     },
//     access: [
//       {
//         lat: 18.59717,
//         lng: 74.01355,
//       },
//     ],
//     distance: 2083,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138029700',
//       },
//     ],
//   },
//   {
//     title: 'Kalbhirawnath Mandir',
//     id: 'here:pds:place:356jx7ps-f749f8c969fe0a76d784d68ea0df8037',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Kalbhirawnath Mandir, Katkewadi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Katkewadi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60699,
//       lng: 74.00606,
//     },
//     access: [
//       {
//         lat: 18.6069,
//         lng: 74.00594,
//       },
//     ],
//     distance: 2125,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138004647',
//       },
//     ],
//   },
//   {
//     title: 'Place of Worship',
//     id: 'here:pds:place:356jx7ps-14b3f06a7f4507a52cdbf203153d6095',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Place of Worship, Awhalwadi Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Awhalwadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57321,
//       lng: 73.98701,
//     },
//     access: [
//       {
//         lat: 18.57326,
//         lng: 73.98717,
//       },
//     ],
//     distance: 2149,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174596957',
//       },
//     ],
//   },
//   {
//     title: 'Shri Khandoba Mandir',
//     id: 'here:pds:place:356tek9e-c0e79e6db1410ace84218749b54b7268',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Khandoba Mandir, Awhalwadi Road, Ganesh Nagar, Awhalwadi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Awhalwadi',
//       subdistrict: 'Ganesh Nagar',
//       street: 'Awhalwadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57296,
//       lng: 73.98711,
//     },
//     access: [
//       {
//         lat: 18.57297,
//         lng: 73.98725,
//       },
//     ],
//     distance: 2171,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224635575',
//       },
//     ],
//   },
//   {
//     title: 'Ganesh Satav GS',
//     id: 'here:pds:place:356tek9e-2c36afe2e5cc4c91a5302c133c8a04ca',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Ganesh Satav GS, Ganesh Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ganesh Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.575,
//       lng: 73.98256,
//     },
//     access: [
//       {
//         lat: 18.575,
//         lng: 73.98272,
//       },
//     ],
//     distance: 2212,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//       {
//         id: '300-3200-0000',
//         name: 'Religious Place',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174595908',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919011004646',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hanuman Lake',
//     id: 'here:pds:place:356tek9e-060f9ae1771f4c5c5f494acca1a97458',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Hanuman Lake, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58061,
//       lng: 73.97706,
//     },
//     access: [
//       {
//         lat: 18.58064,
//         lng: 73.97711,
//       },
//     ],
//     distance: 2221,
//     categories: [
//       {
//         id: '300-3000-0025',
//         name: 'Historical Monument',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356tek9u-8be3924370eae92a7bf48980c5c44d12',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Mandir, Vitthalwadi, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Vitthalwadi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.60038,
//       lng: 74.0137,
//     },
//     access: [
//       {
//         lat: 18.60023,
//         lng: 74.01367,
//       },
//     ],
//     distance: 2224,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176559388',
//       },
//     ],
//   },
//   {
//     title: 'Dubenagar Sai Mandir',
//     id: 'here:pds:place:356tek9e-e2321f4e813005be6e01b8386a5b5f56',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Dubenagar Sai Mandir, Dube Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Dube Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5715,
//       lng: 74.00088,
//     },
//     access: [
//       {
//         lat: 18.5715,
//         lng: 74.00088,
//       },
//     ],
//     distance: 2249,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shiv Mandir',
//     id: 'here:pds:place:356jx7ps-70467959840e036290ce0b5eb1b8176f',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shiv Mandir, Dube Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Dube Nagar',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57138,
//       lng: 74.00342,
//     },
//     access: [
//       {
//         lat: 18.57134,
//         lng: 74.00356,
//       },
//     ],
//     distance: 2348,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176564167',
//       },
//     ],
//   },
//   {
//     title: 'Shri Khandoba Mandir',
//     id: 'here:pds:place:356jx7ps-2a46fbb49e1b0d9ec27515e950deb71a',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Khandoba Mandir, Ram Nagar, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       subdistrict: 'Ram Nagar',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.60592,
//       lng: 74.01159,
//     },
//     access: [
//       {
//         lat: 18.60602,
//         lng: 74.01169,
//       },
//     ],
//     distance: 2407,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176559218',
//       },
//     ],
//   },
//   {
//     title: 'Shiv Temple',
//     id: 'here:pds:place:356tek9s-f5ff9380edee499c82aade59cb2068f3',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shiv Temple, Keshnand Theur Road, Hi Cliff Industrial Estate, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Hi Cliff Industrial Estate',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57536,
//       lng: 74.01151,
//     },
//     access: [
//       {
//         lat: 18.57521,
//         lng: 74.01148,
//       },
//     ],
//     distance: 2451,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001430751',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333498',
//       },
//     ],
//   },
//   {
//     title: 'Shri Bhrahmachaitanya Kanifnath Datta Ganesh Mandir',
//     id: 'here:pds:place:356jx7ps-e65cb10e78240e46b1b8a6d0c49a5058',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Bhrahmachaitanya Kanifnath Datta Ganesh Mandir, Ram Nagar-Jirkhel Wasti, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       subdistrict: 'Ram Nagar-Jirkhel Wasti',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.60941,
//       lng: 74.01001,
//     },
//     access: [
//       {
//         lat: 18.60935,
//         lng: 74.00987,
//       },
//     ],
//     distance: 2585,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138004650',
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356tek9e-e7f9cdfa3eef50861cda60ed3c136406',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Ganesh Mandir, Navin Bhadale Wasti-Anusaya Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Navin Bhadale Wasti-Anusaya Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57678,
//       lng: 73.97498,
//     },
//     access: [
//       {
//         lat: 18.57677,
//         lng: 73.97515,
//       },
//     ],
//     distance: 2641,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418095',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224629200',
//       },
//     ],
//   },
//   {
//     title: 'Matoshri Park',
//     id: 'here:pds:place:356jx7ps-ea7a407f1c35038f99750dd7b91bca7e',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Matoshri Park, Keshnand Theur Road, Hi Cliff Industrial Estate, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Hi Cliff Industrial Estate',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57487,
//       lng: 74.01359,
//     },
//     access: [
//       {
//         lat: 18.57473,
//         lng: 74.01356,
//       },
//     ],
//     distance: 2647,
//     categories: [
//       {
//         id: '550-5510-0202',
//         name: 'Park-Recreation Area',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174732203',
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919890886161',
//           },
//           {
//             value: '+919921286161',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hanuman Temple',
//     id: 'here:pds:place:356jx7ps-75cfad6ea07c0fe70efa3526bb9aca63',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hanuman Temple, Wagholi Lohagaon Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Wagholi Lohagaon Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58608,
//       lng: 73.96885,
//     },
//     access: [
//       {
//         lat: 18.58594,
//         lng: 73.96881,
//       },
//     ],
//     distance: 2817,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418813',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1172645591',
//       },
//     ],
//   },
//   {
//     title: 'Shri Vitthal Rukmini Mandir',
//     id: 'here:pds:place:356tek9u-698dcace105e7f79341fb9a32457e882',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Vitthal Rukmini Mandir, Ram Nagar-Jirkhel Wasti, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       subdistrict: 'Ram Nagar-Jirkhel Wasti',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.61136,
//       lng: 74.01174,
//     },
//     access: [
//       {
//         lat: 18.61144,
//         lng: 74.0116,
//       },
//     ],
//     distance: 2868,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138004645',
//       },
//     ],
//   },
//   {
//     title: 'Radha Swami Satsang Ground',
//     id: 'here:pds:place:356tek9s-c2af07e5c57d42559e0a74b2fe7fe528',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Radha Swami Satsang Ground, Keshnand Theur Road, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57429,
//       lng: 74.01604,
//     },
//     access: [
//       {
//         lat: 18.57415,
//         lng: 74.01601,
//       },
//     ],
//     distance: 2884,
//     categories: [
//       {
//         id: '300-3200-0033',
//         name: 'Ashram',
//         primary: true,
//       },
//       {
//         id: '300-3200-0309',
//         name: 'Other Place of Worship',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001430757',
//       },
//     ],
//   },
//   {
//     title: 'Hotel Navashri Garden Restaurant',
//     id: 'here:pds:place:356jx7ps-95921580ec5a086f1af06a5bd0cde40d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Navashri Garden Restaurant, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5782,
//       lng: 73.97105,
//     },
//     access: [
//       {
//         lat: 18.57804,
//         lng: 73.97111,
//       },
//     ],
//     distance: 2903,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097418742',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981539',
//       },
//       {
//         supplier: {
//           id: 'tripadvisor',
//         },
//         id: '5071653',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+919562000000',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Hotel Satyajeet Restaurant & Bar',
//     id: 'here:pds:place:356jx7ps-1f86732402f601214055efb974ecaf67',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hotel Satyajeet Restaurant & Bar, SH-60, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57811,
//       lng: 73.97084,
//     },
//     access: [
//       {
//         lat: 18.57795,
//         lng: 73.97092,
//       },
//     ],
//     distance: 2927,
//     categories: [
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//         primary: true,
//       },
//       {
//         id: '200-2000-0011',
//         name: 'Bar or Pub',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174481201',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1174481202',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shri Ganesh Mandir',
//     id: 'here:pds:place:356tek9e-01720b43b8f297d52ecd84bb1a756e39',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Shri Ganesh Mandir, Domkhel, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Domkhel',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.56542,
//       lng: 73.98252,
//     },
//     access: [
//       {
//         lat: 18.56564,
//         lng: 73.98201,
//       },
//     ],
//     distance: 3133,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Nisargeshwar Temple',
//     id: 'here:pds:place:356tek9e-34919c1daed5938edc738b15357270a6',
//     language: 'en',
//     resultType: 'place',
//     address: {
//       label: 'Nisargeshwar Temple, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.58836,
//       lng: 73.96536,
//     },
//     access: [
//       {
//         lat: 18.58836,
//         lng: 73.96536,
//       },
//     ],
//     distance: 3146,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//   },
//   {
//     title: 'Shri Datta Temple',
//     id: 'here:pds:place:356jx7ps-1bf564ff2981030e8db448c40ae49085',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Datta Temple, Domkhel, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Domkhel',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.56616,
//       lng: 73.98053,
//     },
//     access: [
//       {
//         lat: 18.5661,
//         lng: 73.98068,
//       },
//     ],
//     distance: 3155,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097421153',
//       },
//     ],
//   },
//   {
//     title: 'Ganesh Temple',
//     id: 'here:pds:place:356jx7ps-71216d50be8f027886d680421535a607',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Ganesh Temple, Keshnand Theur Road, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       street: 'Keshnand Theur Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57374,
//       lng: 74.01929,
//     },
//     access: [
//       {
//         lat: 18.57376,
//         lng: 74.01926,
//       },
//     ],
//     distance: 3190,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333535',
//       },
//     ],
//   },
//   {
//     title: 'Prajapeeta Bramhakumari Ishwariy Vishwa Vidyalaya',
//     id: 'here:pds:place:356jx7ps-0b11631b47180e1b61ad8e1af4a1fe61',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Prajapeeta Bramhakumari Ishwariy Vishwa Vidyalaya, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57417,
//       lng: 74.01993,
//     },
//     access: [
//       {
//         lat: 18.57411,
//         lng: 74.0198,
//       },
//     ],
//     distance: 3216,
//     categories: [
//       {
//         id: '800-8200-0173',
//         name: 'Higher Education',
//         primary: true,
//       },
//       {
//         id: '300-3200-0000',
//         name: 'Religious Place',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570841',
//       },
//     ],
//   },
//   {
//     title: 'Shri Hanuman',
//     id: 'here:pds:place:356tek9e-922a3c04d581442a863d1581c6e3edf0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Hanuman, Awhalwadi Road, Awhalwadi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Awhalwadi',
//       street: 'Awhalwadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.56272,
//       lng: 73.98762,
//     },
//     access: [
//       {
//         lat: 18.56272,
//         lng: 73.9876,
//       },
//     ],
//     distance: 3237,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001423069',
//       },
//     ],
//   },
//   {
//     title: 'Shri Bhairawnath Mandir',
//     id: 'here:pds:place:356jx7ps-284c4226fa6404e0d2133299144fd515',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Bhairawnath Mandir, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57298,
//       lng: 74.02089,
//     },
//     access: [
//       {
//         lat: 18.57311,
//         lng: 74.02091,
//       },
//     ],
//     distance: 3376,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333510',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570117',
//       },
//     ],
//   },
//   {
//     title: 'Hajarat Sahawali Pathan Baba Dargah',
//     id: 'here:pds:place:356jx7ps-6a25a896fa530f2d151ff6215543d3b5',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hajarat Sahawali Pathan Baba Dargah, Haveli Rural Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57293,
//       lng: 74.0215,
//     },
//     access: [
//       {
//         lat: 18.57309,
//         lng: 74.02157,
//       },
//     ],
//     distance: 3431,
//     categories: [
//       {
//         id: '300-3200-0034',
//         name: 'Mosque',
//         primary: true,
//       },
//       {
//         id: '300-3200-0309',
//         name: 'Other Place of Worship',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097333511',
//       },
//     ],
//   },
//   {
//     title: 'Om Chaitanya Kanifnath Paduka',
//     id: 'here:pds:place:356jx7ps-78a61b82e72f0c0228d9ba7c546ad9c8',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Om Chaitanya Kanifnath Paduka, Haveli Rural Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57286,
//       lng: 74.02188,
//     },
//     access: [
//       {
//         lat: 18.57289,
//         lng: 74.02189,
//       },
//     ],
//     distance: 3468,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//       {
//         id: '300-3200-0000',
//         name: 'Religious Place',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176570494',
//       },
//     ],
//   },
//   {
//     title: 'Hazrat Shah Avli Pathan Baba',
//     id: 'here:pds:place:356tek9s-32a67de1189d4e17b6b199ec16b8d3f0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Hazrat Shah Avli Pathan Baba, Haveli Rural Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Haveli Rural Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57276,
//       lng: 74.02184,
//     },
//     access: [
//       {
//         lat: 18.5729,
//         lng: 74.02188,
//       },
//     ],
//     distance: 3471,
//     categories: [
//       {
//         id: '300-3200-0034',
//         name: 'Mosque',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001430755',
//       },
//     ],
//   },
//   {
//     title: 'Hanuman Temple',
//     id: 'here:pds:place:356jx7ps-1f09ada38ffa08d626b84e47d8c8bf27',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hanuman Temple, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57231,
//       lng: 74.02183,
//     },
//     access: [
//       {
//         lat: 18.57226,
//         lng: 74.02199,
//       },
//     ],
//     distance: 3500,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097334145',
//       },
//     ],
//   },
//   {
//     title: 'Shri Vithal Rukhmini Mandir',
//     id: 'here:pds:place:356jx7ps-bc66f1e47989075ca678dca5d31ae3d1',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Vithal Rukhmini Mandir, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.5723,
//       lng: 74.02183,
//     },
//     access: [
//       {
//         lat: 18.57233,
//         lng: 74.02197,
//       },
//     ],
//     distance: 3501,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001430756',
//       },
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176568621',
//       },
//     ],
//   },
//   {
//     title: 'Shri Vishwakarma Samaj Mandir',
//     id: 'here:pds:place:356jx7ps-2d456116dac3094a9703c721de04ed00',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Vishwakarma Samaj Mandir, Theur Kesnand Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Theur Kesnand Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57208,
//       lng: 74.02178,
//     },
//     access: [
//       {
//         lat: 18.57203,
//         lng: 74.02192,
//       },
//     ],
//     distance: 3511,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176562271',
//       },
//     ],
//   },
//   {
//     title: 'Shankar Temple',
//     id: 'here:pds:place:356jx7ps-9ed15fb21a0b04931adda74af73d31e0',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shankar Temple, Kesnand Gavadewadi Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Kesnand Gavadewadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57195,
//       lng: 74.02211,
//     },
//     access: [
//       {
//         lat: 18.57198,
//         lng: 74.02213,
//       },
//     ],
//     distance: 3547,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097334129',
//       },
//     ],
//   },
//   {
//     title: 'Darga',
//     id: 'here:pds:place:356jx7ps-417336b6491d04bdee2f933eeb55a182',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Darga, Kesnand Gavadewadi Road, Kesnand Gaon, Kesnand, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Kesnand',
//       subdistrict: 'Kesnand Gaon',
//       street: 'Kesnand Gavadewadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57156,
//       lng: 74.0228,
//     },
//     access: [
//       {
//         lat: 18.57169,
//         lng: 74.02283,
//       },
//     ],
//     distance: 3632,
//     categories: [
//       {
//         id: '300-3200-0309',
//         name: 'Other Place of Worship',
//         primary: true,
//       },
//       {
//         id: '300-3200-0000',
//         name: 'Religious Place',
//       },
//       {
//         id: '300-3200-0034',
//         name: 'Mosque',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1176568835',
//       },
//     ],
//   },
//   {
//     title: 'Pune Pet Park, Aviary',
//     id: 'here:pds:place:356tek9g-0d68997b12d033ebb1ea77fa15d49869',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Pune Pet Park, Aviary, Diamond Water Park Road, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       street: 'Diamond Water Park Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.6008,
//       lng: 73.96203,
//     },
//     access: [
//       {
//         lat: 18.60111,
//         lng: 73.96161,
//       },
//     ],
//     distance: 3651,
//     categories: [
//       {
//         id: '550-5520-0228',
//         name: 'Animal Park',
//         primary: true,
//       },
//       {
//         id: '550-5510-0202',
//         name: 'Park-Recreation Area',
//       },
//     ],
//     contacts: [
//       {
//         phone: [
//           {
//             value: '+917263900195',
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.punepetpark.com',
//             categories: [
//               {
//                 id: '550-5520-0228',
//               },
//             ],
//           },
//           {
//             value: 'https://www.punepetpark.com/aviary',
//             categories: [
//               {
//                 id: '550-5510-0202',
//               },
//             ],
//           },
//         ],
//         email: [
//           {
//             value: 'punepetpark@gmail.com',
//             categories: [
//               {
//                 id: '550-5520-0228',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '550-5520-0228',
//           },
//         ],
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Shiv Temple',
//     id: 'here:pds:place:356jx7ps-939f8f3468ef081eb67081333c7f5151',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shiv Temple, Awhalwadi Road, Awhalwadi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Awhalwadi',
//       street: 'Awhalwadi Road',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.55896,
//       lng: 73.98719,
//     },
//     access: [
//       {
//         lat: 18.55897,
//         lng: 73.98736,
//       },
//     ],
//     distance: 3654,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1097424593',
//       },
//     ],
//   },
//   {
//     title: 'Datta Mandir',
//     id: 'here:pds:place:356jx7ps-a830a23400f20d90c6ec4ef60e4e486d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Datta Mandir, Priyanka Nagari-Malhar Indl Estate, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Priyanka Nagari-Malhar Indl Estate',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57499,
//       lng: 73.96448,
//     },
//     access: [
//       {
//         lat: 18.57501,
//         lng: 73.96464,
//       },
//     ],
//     distance: 3682,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1104856364',
//       },
//     ],
//   },
//   {
//     title: 'Guess Lounge & Bar',
//     id: 'here:pds:place:356tek9e-46ebcce00e214d8a9f088bca29e89115',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Guess Lounge & Bar, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57863,
//       lng: 73.9625,
//     },
//     access: [
//       {
//         lat: 18.57863,
//         lng: 73.96251,
//       },
//     ],
//     distance: 3697,
//     categories: [
//       {
//         id: '200-2000-0012',
//         name: 'Night Club',
//         primary: true,
//       },
//       {
//         id: '100-1000-0000',
//         name: 'Restaurant',
//       },
//       {
//         id: '500-5100-0000',
//         name: 'Lodging',
//       },
//       {
//         id: '700-7250-0136',
//         name: 'Business Facility',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1224417465',
//       },
//     ],
//     foodTypes: [
//       {
//         id: '202-000',
//         name: 'Indian',
//         primary: true,
//       },
//     ],
//     contacts: [
//       {
//         mobile: [
//           {
//             value: '+919168533408',
//           },
//         ],
//         www: [
//           {
//             value: 'https://www.facebook.com/100927218101557',
//             categories: [
//               {
//                 id: '200-2000-0012',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Maruti Mandir',
//     id: 'here:pds:place:356tek9e-81baa48fd12c0d6c104d957529e7f483',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Maruti Mandir, Priyanka Nagari, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Priyanka Nagari',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57533,
//       lng: 73.96239,
//     },
//     access: [
//       {
//         lat: 18.57534,
//         lng: 73.96226,
//       },
//     ],
//     distance: 3859,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147980901',
//       },
//     ],
//   },
//   {
//     title: 'Shri Hinglaj Mata Mandir',
//     id: 'here:pds:place:356jx7ps-8d5f7fec255b0929d771caa148177d0a',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label:
//         'Shri Hinglaj Mata Mandir, Sai Satyam Park, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Sai Satyam Park',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57688,
//       lng: 73.96136,
//     },
//     access: [
//       {
//         lat: 18.57673,
//         lng: 73.96136,
//       },
//     ],
//     distance: 3884,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1070276275',
//       },
//     ],
//   },
//   {
//     title: 'Hanuman Mandir',
//     id: 'here:pds:place:356jx7ps-c4249ce623b9059e338ddf98b3a6bd47',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Hanuman Mandir, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.59533,
//       lng: 74.03165,
//     },
//     access: [
//       {
//         lat: 18.59518,
//         lng: 74.03161,
//       },
//     ],
//     distance: 3885,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1138003549',
//       },
//     ],
//   },
//   {
//     title: 'Shri Jogeshwari Pratishthan',
//     id: 'here:pds:place:356tek9u-c1e2dcc7021e45b195df2db514d4050d',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Jogeshwari Pratishthan, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.58962,
//       lng: 74.03206,
//     },
//     access: [
//       {
//         lat: 18.5896,
//         lng: 74.03223,
//       },
//     ],
//     distance: 3900,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001428782',
//       },
//     ],
//   },
//   {
//     title: 'Ganesh Mandir',
//     id: 'here:pds:place:356jx7ps-6071392af75a074d88ddbb52bbbcd964',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Ganesh Mandir, SH-60, Ubale Nagar, Wagholi, Haveli 412207, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Wagholi',
//       subdistrict: 'Ubale Nagar',
//       street: 'SH-60',
//       postalCode: '412207',
//     },
//     position: {
//       lat: 18.57521,
//       lng: 73.96188,
//     },
//     access: [
//       {
//         lat: 18.5753,
//         lng: 73.96183,
//       },
//     ],
//     distance: 3913,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//       {
//         id: '600-6900-0000',
//         name: 'Consumer Goods',
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1147981274',
//       },
//     ],
//     openingHours: [
//       {
//         categories: [
//           {
//             id: '300-3200-0031',
//           },
//         ],
//         text: ['Mon-Sun: 00:00 - 24:00'],
//         isOpen: true,
//         structured: [
//           {
//             start: 'T000000',
//             duration: 'PT24H00M',
//             recurrence: 'FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Shri Gauli Baba',
//     id: 'here:pds:place:356tek9u-b3157b8c055b4979b8f4636dcc7839cb',
//     language: 'en-GB',
//     resultType: 'place',
//     address: {
//       label: 'Shri Gauli Baba, Lonikand, Haveli 412216, India',
//       countryCode: 'IND',
//       countryName: 'India',
//       stateCode: 'MH',
//       state: 'Maharashtra',
//       county: 'Pune',
//       city: 'Haveli',
//       district: 'Lonikand',
//       postalCode: '412216',
//     },
//     position: {
//       lat: 18.59211,
//       lng: 74.03219,
//     },
//     access: [
//       {
//         lat: 18.59212,
//         lng: 74.03235,
//       },
//     ],
//     distance: 3913,
//     categories: [
//       {
//         id: '300-3200-0031',
//         name: 'Temple',
//         primary: true,
//       },
//     ],
//     references: [
//       {
//         supplier: {
//           id: 'core',
//         },
//         id: '1001428781',
//       },
//     ],
//   },
// ];

export const NearbyPlaces = () => {
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(10);
  const { data, isLoading, isError, refetch } = useSearchLocation({
    variables: {
      categoryType: 'visit', //@ts-ignore
      gps: `${location?.coords?.latitude},${location?.coords?.longitude}`,
      distance: distance * 1000,
    },
  });
  console.log(data);

  const debounedSetState = debounce((value) => {
    setDistance(value);
  }, 500);

  const renderItem = React.useCallback(
    //  @ts-ignore
    ({ item }) => <LocationCard {...item} />,
    []
  );

  useEffect(() => {
    (async () => {
      const currentLocation = await getCurrentLocation(); //@ts-ignore
      setLocation(currentLocation);
    })();
  }, []);

  useEffect(() => {
    refetch();
  }, [location, refetch, distance]);

  return (
    <>
      <ScrollView className="p-4 dark:bg-themeblue-600">
        <Text className="mb-4">Adjust the radius for nearby activities</Text>
        <Slider
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ width: '100%', height: 40 }}
          minimumValue={0}
          maximumValue={50}
          step={1}
          value={distance}
          minimumTrackTintColor={colors.themeblue[400]}
          maximumTrackTintColor={colors.themeblue[300]}
          thumbTintColor={colors.themeblue[400]}
          onValueChange={(value: number) => {
            debounedSetState(value);
          }}
        />
        <View className="flex-row justify-between">
          <Text className="m-2">1km</Text>
          <Text className="m-2">50km</Text>
        </View>
        <Text className="pb-4">Activities within {distance} kms</Text>
        {isLoading && (
          <View className="flex-1  justify-center">
            <ActivityIndicator />
          </View>
        )}
        {isError && (
          <View>
            <Text> Error Loading data </Text>
          </View>
        )}
        {data && (
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(_, index) => `item-${index}`}
            ListEmptyComponent={<EmptyList isLoading={false} />}
            // estimatedItemSize={199}
          />
        )}
      </ScrollView>
    </>
  );
};
