import type { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheetFlatList } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import { useColorScheme } from 'nativewind';
import * as React from 'react';
import {
  Modal as NativeModal,
  Platform,
  type PressableProps,
} from 'react-native';

import { colors } from '@/ui/theme';

import { Modal } from '../modal';
import { Pressable } from '../pressable';
import { Text } from '../text';
import { Check } from './icons';

export type Option = {
  label: string;
  value: string | number;
};

type OptionsProps = {
  options: Option[];
  onSelect: (option: Option) => void;
  value?: string | number;
  visibility: boolean;
};

function keyExtractor(item: Option) {
  return `select-item-${item.value}`;
}

export const Options = React.forwardRef<BottomSheetModal, OptionsProps>(
  ({ options, onSelect, value, visibility }, ref) => {
    const height = options.length * 70 + 100;
    const snapPoints = React.useMemo(() => [height], [height]);
    const { colorScheme } = useColorScheme();
    const isDark = colorScheme === 'dark';
    const renderSelectItem = React.useCallback(
      ({ item }: { item: Option }) => (
        <Option
          key={`select-item-${item.value}`}
          label={item.label}
          selected={value === item.value}
          onPress={() => onSelect(item)}
        />
      ),
      [onSelect, value]
    );

    return Platform.OS !== 'web' ? (
      <Modal
        ref={ref}
        index={0}
        snapPoints={snapPoints}
        backgroundStyle={{
          backgroundColor: isDark ? colors.themeblue[500] : colors.white,
        }}
      >
        <BottomSheetFlatList
          data={options}
          keyExtractor={keyExtractor}
          renderItem={renderSelectItem}
        />
      </Modal>
    ) : (
      <NativeModal
        animationType="slide"
        transparent={true}
        visible={visibility}
        onRequestClose={() => {
          // Alert.alert('Modal has been closed.');
          // setModalVisible(!modalVisible);
        }}
      >
        <FlashList
          data={options}
          keyExtractor={keyExtractor}
          renderItem={renderSelectItem}
        />
      </NativeModal>
    );
  }
);

const Option = React.memo(
  ({
    label,
    selected = false,
    ...props
  }: PressableProps & {
    selected?: boolean;
    label: string;
  }) => {
    return (
      <Pressable
        className="flex-row items-center border-b-[1px] border-neutral-300 bg-white py-2 px-3 dark:border-charcoal-700 dark:bg-themeblue-500"
        {...props}
      >
        <Text variant="md" className="flex-1 dark:text-charcoal-100 ">
          {label}
        </Text>
        {selected && <Check fill="fill-black dark:fill-white" />}
      </Pressable>
    );
  }
);
