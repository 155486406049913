import { Env } from '@env';
import { jwtDecode } from 'jwt-decode';
import { useColorScheme } from 'nativewind';
import * as React from 'react';
import { Share as ShareFeature } from 'react-native';

import { openLinkInBrowser, useAuth } from '@/core';
import { getToken } from '@/core/auth/utils';
import { FocusAwareStatusBar, ScrollView, View } from '@/ui';
import { Rate, Share, Support, Website } from '@/ui/icons';
import colors from '@/ui/theme/colors';

import { EmailItem } from './email-item';
import { Item } from './item';
import { ItemsContainer } from './items-container';
import { LanguageItem } from './language-item';
import { ThemeItem } from './theme-item';

export const Settings = () => {
  const signOut = useAuth.use.signOut();
  const { colorScheme } = useColorScheme();
  const iconColor =
    colorScheme === 'dark' ? colors.neutral[400] : colors.neutral[500];

  const [decodedToken, setDecodedToken] = React.useState({
    name: '',
    email: '',
    verificationStatus: {
      email: false,
      phone: false,
      instagram: false,
    },
  });

  const handleShare = async () => {
    try {
      const result = await ShareFeature.share({
        message: `Check out this trip planner: https://clonemytrips.com`,
        //
      });

      if (result.action === ShareFeature.sharedAction) {
        console.log('Post shared successfully');
      } else if (result.action === ShareFeature.dismissedAction) {
        console.log('Share cancelled');
      }
    } catch (error) {
      //@ts-ignore
      console.error('Error sharing post:', error.message);
    }
  };

  React.useEffect(() => {
    const jwtToken = getToken();

    try {
      // @ts-ignore
      const decoded = jwtDecode<JwtPayload>(jwtToken.access);
      setDecodedToken(decoded);
    } catch (error) {
      console.error('Error decoding JWT:', error);
    }
  }, []);

  return (
    <>
      <FocusAwareStatusBar />

      <ScrollView>
        <View className="flex-1 px-4 ">
          {/* <Text variant="lg" className="font-bold">
            {translate('settings.title')}
          </Text> */}
          <ItemsContainer title="settings.profile">
            <Item text="settings.profileName" value={decodedToken?.name} />
            <EmailItem
              decodedToken={decodedToken}
              setDecodedToken={setDecodedToken}
            />
          </ItemsContainer>

          <ItemsContainer title="settings.generale">
            <LanguageItem />
            <ThemeItem />
          </ItemsContainer>

          <ItemsContainer title="settings.about">
            <Item text="settings.app_name" value={Env.NAME} />
            <Item text="settings.version" value={Env.VERSION} />
          </ItemsContainer>

          <ItemsContainer title="settings.support_us">
            <Item
              text="settings.share"
              icon={<Share color={iconColor} />}
              onPress={() => handleShare()}
            />
            <Item
              text="settings.rate"
              icon={<Rate color={iconColor} />}
              onPress={() => {
                openLinkInBrowser(
                  'https://play.google.com/store/apps/details?id=com.onetrip.clonemytrips'
                );
              }}
            />
            <Item
              text="settings.feedback"
              icon={<Support color={iconColor} />}
              onPress={() => {
                openLinkInBrowser('https://tally.so/r/mBXaO5');
              }}
            />
          </ItemsContainer>

          <ItemsContainer title="settings.links">
            <Item
              text="settings.privacy"
              onPress={() => {
                openLinkInBrowser('https://clonemytrips.com/privacypolicy');
              }}
            />
            <Item
              text="settings.terms"
              onPress={() => {
                openLinkInBrowser(
                  'https://clonemytrips.com/termsandconditions'
                );
              }}
            />
            {/* <Item
              text="settings.github"
              icon={<Github color={iconColor} />}
              onPress={() => {}}
            /> */}
            <Item
              text="settings.website"
              icon={<Website color={iconColor} />}
              onPress={() => {
                openLinkInBrowser('https://clonemytrips.com');
              }}
            />
          </ItemsContainer>

          <View className="my-8">
            <ItemsContainer>
              <Item text="settings.logout" onPress={signOut} />
            </ItemsContainer>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
