// import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useColorScheme } from 'nativewind';
import * as React from 'react';
import { Modal, Platform } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { useTripClone, useTripCommonDetail } from '@/api';
import { usePaymentLink } from '@/api/payments';
import { openLinkInBrowser } from '@/core';
import { getItem, removeItem } from '@/core/storage';
import {
  TripDetailChecklist,
  TripDetailCost,
  // TripDetailFood,
  TripDetailInfo,
  TripDetailItinerary,
  TripDetailStays,
} from '@/screens';
import {
  ActivityIndicator,
  Button,
  colors,
  DynamicModal,
  EmptyList,
  FocusAwareStatusBar,
  showErrorMessage,
  Text,
  useModal,
  View,
} from '@/ui';
import { ModalHeader } from '@/ui/core/modal/modal-header';

import { CloneForm } from './clone-form';

export type TripDetailStackParamList = {
  Itinerary: { id: number };
  Food: { id: number };
  Stays: { id: number };
  Checklist: { id: number };
  About: { id: number };
  Cost: { id: number };
};

// const Stack = createNativeStackNavigator<NearbyStackParamList>();

// const GoToAddPost = () => {
//   return (
//     <Pressable onPress={() => {}} className="p-2">
//       <Text className="text-primary-300">Create</Text>
//     </Pressable>
//   );
// };
const Tab = createMaterialTopTabNavigator<TripDetailStackParamList>();

type Props = {
  navigation: any;
  route: any;
};

export const TripDetailNavigator = ({ navigation, route }: Props) => {
  // const { navigate } = useNavigation();
  const modal = useModal();
  const [date, setDate] = React.useState();
  const [visibility, setVisibility] = React.useState(false);
  const { colorScheme } = useColorScheme();

  const { mutate: cloneTrip, isLoading: cloneLoader } = useTripClone();

  React.useEffect(() => {
    const redirectedLink = getItem('TRIP_URL');
    if (redirectedLink) {
      removeItem('TRIP_URL');
    }
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({ title: route.params?.name });
  }, [navigation, route]);

  const { data, isLoading, isError } = useTripCommonDetail({
    variables: { id: route.params.id },
  });

  const { refetch, data: phonepe } = usePaymentLink(
    //@ts-ignore
    { variables: { tripId: route.params.id } } // Example amount
    // { enabled: false } // Disable automatic fetching
  ); // Disable automatic fetching);

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }

  if (phonepe) {
    window.open(
      'https://clonemytrips.com/pay?url=' +
        phonepe?.data?.instrumentResponse?.redirectInfo?.url
    );
  }

  //@ts-ignore
  const generateGoogleMapsRouteLink = (coordinates) => {
    if (coordinates.length < 2) {
      // Handle case with less than two coordinates
      return null;
    }

    const origin = `${coordinates[0].latitude},${coordinates[0].longitude}`;
    const destination = `${coordinates[coordinates.length - 1].latitude},${
      coordinates[coordinates.length - 1].longitude
    }`;
    const waypoints = coordinates
      .slice(1, -1) //@ts-ignore
      .map((coord) => `${coord.latitude},${coord.longitude}`)
      .join('|');

    // Construct the Google Maps URL
    const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints}`;

    return url;
  };
  //@ts-ignore
  const handleSubmitClone = (dataClone, type) => {
    if (type === 'manual') {
      cloneTrip(
        {
          bookingType: type, //@ts-ignore
          date: {
            start: date,
            // end: new Date( //@ts-ignore
            //   new Date(date).setDate(new Date(date).getDate() + data?.tripLength)
            // ).toISOString(),
          },
          id: route?.params?.id,
          phoneNumber: dataClone?.phoneNumber?.startsWith('+91')
            ? dataClone?.phoneNumber
            : '+91' + dataClone?.phoneNumber, //@ts-ignore
          member: { min: parseInt(dataClone?.member, 10) },
        },
        {
          // eslint-disable-next-line unused-imports/no-unused-vars
          onSuccess: (res) => {
            showMessage({
              message: 'Trip cloned added successfully',
              type: 'success',
            });

            // here you can navigate to the post list and refresh the list data
            //queryClient.invalidateQueries(usePosts.getKey());
            if (Platform.OS === 'web') {
              setVisibility(false);
            } else {
              modal.dismiss();
            }
            // navigate('MyTrips'); //{ id: res.clonedTrip._id }); //res.clonedTrip.id
            // navigate('MyTripDetails', { id: res.clonedTrip._id });
          },
          onError: () => {
            showErrorMessage('Error adding post');
          },
        }
      );
    } else {
      refetch();
    }
  };

  return (
    // <NavigationContainer independent>
    <>
      <Tab.Navigator
        initialRouteName="Itinerary"
        screenOptions={{
          // scrollEnabled: true, // Enable scrolling for tabs
          // tabStyle: { width: 'auto' },
          tabBarScrollEnabled: true,
          tabBarLabelStyle: {
            fontSize: 12,
            fontWeight: 'bold',
            // color:
            //   colorScheme === 'dark' ? colors.white : colors.themeblue[500],
          },
          tabBarIndicatorStyle: {
            backgroundColor:
              colorScheme === 'dark' ? colors.white : colors.themeblue[500],
            height: '100%',
          },
          tabBarActiveTintColor:
            colorScheme === 'dark' ? colors.themeblue[500] : colors.white,
          tabBarInactiveTintColor:
            colorScheme === 'dark' ? colors.white : colors.themeblue[500],
          tabBarStyle: {
            // width: 'auto',
            // marginTop: Platform.OS === 'ios' ? 100 : 60,
            backgroundColor:
              colorScheme === 'dark' ? colors.themeblue[500] : colors.white,
            // position: 'absolute',
            // elevation: 0,
            // bottom: 5,
          },
          lazy: true,
          lazyPlaceholder: () => <EmptyList isLoading={true} />,
          lazyPreloadDistance: 0,
        }}
      >
        <Tab.Screen
          name="About"
          component={TripDetailInfo}
          initialParams={{ id: route?.params?.id }}
        />
        <Tab.Screen
          name="Itinerary"
          component={TripDetailItinerary}
          initialParams={{ id: route?.params?.id }}
        />
        {/* <Tab.Screen
          name="Food"
          component={TripDetailFood}
          initialParams={{ id: route?.params?.id }}
        /> */}
        <Tab.Screen
          name="Stays"
          component={TripDetailStays}
          initialParams={{ id: route?.params?.id }}
        />
        <Tab.Screen
          name="Checklist"
          component={TripDetailChecklist}
          initialParams={{ id: route?.params?.id }}
        />
        <Tab.Screen
          name="Cost"
          component={TripDetailCost}
          initialParams={{ id: route?.params?.id }}
        />
      </Tab.Navigator>
      <View className="absolute bottom-2 flex w-full flex-row justify-evenly">
        <Button
          label="Route"
          variant="secondary"
          onPress={() =>
            openLinkInBrowser(
              //@ts-ignore
              generateGoogleMapsRouteLink(data?.sortedLocations)
            )
          }
        />
        <Button
          label="Clone"
          variant="secondary"
          onPress={() =>
            Platform.OS === 'web' ? setVisibility(true) : modal.present()
          }
        />
      </View>
      {Platform.OS !== 'web' && (
        <DynamicModal ref={modal.ref} title="Clone Trip">
          <CloneForm
            onSubmitAuto={(res) => handleSubmitClone(res, 'auto')}
            onSubmitManual={(res) => handleSubmitClone(res, 'manual')}
            disable={cloneLoader}
            //@ts-ignore
            onDateChange={(r) => setDate(r)}
          />
        </DynamicModal>
      )}
      {Platform.OS === 'web' && (
        <Modal
          animationType="slide"
          transparent={false}
          visible={visibility}
          onRequestClose={() => {
            // Alert.alert('Modal has been closed.');
            // setModalVisible(!modalVisible);
          }}
        >
          <ModalHeader
            title="Clone Trip"
            dismiss={() => setVisibility(false)}
          />
          <CloneForm
            onSubmitAuto={(res) => handleSubmitClone(res, 'auto')}
            onSubmitManual={(res) => handleSubmitClone(res, 'manual')}
            disable={cloneLoader}
            //@ts-ignore
            onDateChange={(r) => setDate(r)}
          />
        </Modal>
      )}
    </>
    // </NavigationContainer>
    // <Stack.Navigator
    //   screenOptions={{
    //     headerShown: false,
    //   }}
    // >
    //   <Stack.Group
    //     screenOptions={{
    //       // eslint-disable-next-line react/no-unstable-nested-components
    //       headerRight: () => <GoToAddPost />,
    //     }}
    //   >
    //     {/* <Stack.Screen name="HomeScreen" component={Home} /> */}
    //   </Stack.Group>

    //   <Stack.Screen name="Nearby" component={Nearby} />
    // </Stack.Navigator>
  );
};
