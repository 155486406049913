import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

import { useTripChecklist } from '@/api';
import { FocusAwareStatusBar, ScrollView, Text, View } from '@/ui';

//@ts-ignore
const BulletPoint = ({ text, subItems, className }) => (
  <View style={styles.bulletPointContainer} className={className}>
    <View className="flex-row">
      <Text style={styles.bulletPoint}>•</Text>
      <Text style={styles.bulletText}>{text}</Text>
    </View>
    {subItems && (
      <View style={styles.subBulletList}>
        {/* @ts-ignore */}
        {subItems.map((subItem, index) => (
          <BulletPoint
            key={index}
            text={subItem.name}
            subItems={subItem.subItems}
            className={''}
          />
        ))}
      </View>
    )}
  </View>
);
//@ts-ignore
export const TripDetailChecklist = ({ route }) => {
  const { data, isLoading, isError } = useTripChecklist({
    variables: { id: route.params.id },
  });

  if (isLoading) {
    return (
      <View className="flex-1  justify-center">
        <ActivityIndicator />
      </View>
    );
  }
  if (isError) {
    return (
      <View className="flex-1  justify-center">
        <FocusAwareStatusBar />
        <Text variant="md" className="text-center">
          Error loading post
        </Text>
      </View>
    );
  }
  return (
    <ScrollView
      style={styles.container}
      className="bg-white p-2 dark:bg-themeblue-600"
    >
      {data.map((item, index) => (
        <BulletPoint
          key={index}
          text={item.name}
          subItems={item.subchecklist}
          className={
            'rounded-md border-[1px] border-solid border-[cornflowerblue] bg-themeblue-200 p-2 dark:bg-themeblue-500'
          }
        />
      ))}

      <Text className="mr-8 mt-8 text-right">Clone to customize</Text>
      <View className="pb-16" />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  bulletPointContainer: {
    flexDirection: 'column',
    // alignItems: '',
    marginBottom: 8,
  },
  bulletPoint: {
    fontSize: 20,
    marginRight: 8,
  },
  bulletText: {
    fontSize: 16,
  },
  subBulletList: {
    marginTop: 10,
    marginLeft: 24, // Adjust indentation for sub-bullets
  },
});
